import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import { url } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import { Accordion, Card } from "react-bootstrap";
import fetchHandler from "../../api/fetchHandler";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import heart from "../../images/heart.gif";
import FixedHeader from "../../layout/FixedHeader";

const FAQ = () => {
  const { language, isAuthenticated } = useContext(MainContent);
  const [text, setText] = useState(null);
  const [faqlist, setFaqlist] = useState([]);
  const [prvulr, setPrvulr] = useState(null);
  const [nxturl, setNxturl] = useState(null);
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  let myHeaders = new Headers();
  myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Language", language || "en");

  const getFAQ = (url) => {
    fetchHandler(url, "GET", undefined, myHeaders).then((result) => {
      setFaqlist(result.data);
      setPrvulr(result.previouspage);
      setNxturl(result.nextpage);
    });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getnotificationPage = () => {
      fetchHandler(
        `${url}sitepage/pagetype?PageTypeId=5&LangCode=${language}`,
        "GET",
        undefined,
        myHeaders
      ).then((result) => {
        setText(result.data ? result.data.text : t("NoDataAvailable"));
      });
    };

    getnotificationPage();

    getFAQ(`${url}faq/lang/public`);
  }, [language]);

  return (
    <div
      className={`pt-1 container min-h-screen bg-blue-50  ${
        !isAuthenticated ? "mt-20" : "lg:mt-24"
      }`}
    >
      {isAuthenticated && <ProfileNavbar />}
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("FAQ")}
        language={language}
      />
      <div className=" w-full flex items-center mb-0 justify-center ">
        <h1 className="text-center mt-9  text-sr font-medium sm:text-xl  md:text-2xl  lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {t("FAQ")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      {faqlist !== null ? (
        <>
          <div className="py-4">
            {text !== null && (
              <div
                className="bg-white py-4 px-5 w-full shadow-md rounded-md font-normal "
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            )}

            <div className="py-4">
              <Accordion>
                {faqlist.length !== 0 && (
                  <>
                    {faqlist.map((item, ind) => (
                      <Accordion.Item eventKey={ind} key={ind}>
                        <Accordion.Header>
                          <h1 className="text-xl text-sr font-medium  pb-2 flex items-center w-full">
                            {item.title}
                          </h1>
                        </Accordion.Header>
                        <Accordion.Body>{item.text}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </>
                )}
              </Accordion>
              {prvulr !== null || nxturl !== null ? (
                <div className="flex w-full items-center mt-2 mb-4 justify-end gap-2">
                  {prvulr !== null ? (
                    <button
                      onClick={() => getFAQ(prvulr)}
                      className="text-white bg-pr hover:bg-sr rounded-md px-2 py-2 text-sm"
                    >
                      {t("Previous")}
                    </button>
                  ) : (
                    <button
                      disabled
                      className="text-white bg-pr cursor-not-allowed rounded-md px-2 py-2 text-sm"
                    >
                      {t("Previous")}
                    </button>
                  )}
                  {faqlist.length >= 12 ? (
                    <button
                      onClick={() => getFAQ(nxturl)}
                      className="text-white bg-pr hover:bg-sr rounded-md px-2 py-2 text-sm"
                    >
                      {t("Next")}
                    </button>
                  ) : (
                    <button
                      disabled
                      className="text-white bg-pr cursor-not-allowed rounded-md px-2 py-2 text-sm"
                    >
                      {t("Next")}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
    </div>
  );
};

export default FAQ;
