import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { url, errorHandler } from "../../../api/Api";
import { MainContent } from "../../../App";
import EditItem from "../../profileSettings/pSettingsPages/EditItem";
import Location from "../../profileSettings/pSettingsPages/Location";
import Redio from "../../profileSettings/pSettingsPages/Radio";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import heart from "../../../images/heart.gif";
import { BsBlockquoteLeft, BsBlockquoteRight } from "react-icons/bs";
import Swal from "sweetalert2";
import FixedHeader from "../../../layout/FixedHeader";
import DynamicButton from "./../../../components/DynamicButton";

const MatchSetting = () => {
  const { language, changnow, myown_data } = useContext(MainContent);
  const [formdata, setFormdata] = useState(null);
  const [gender, setGender] = useState(
    myown_data?.data?.profileattributes?.gender
  );
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectlanguage, setSelectlanguage] = useState([]);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [cityName, setCityName] = useState("");
  const [haircolor, setHaircolor] = useState(0);
  const [hairtype, setHairtype] = useState(0);
  const [eyewear, setEyewear] = useState(0);
  const [eyecolor, setEyecolor] = useState(0);
  const [skintype, setSkintype] = useState(0);
  const [bodytype, setBodytype] = useState(0);
  const [bodylook, setBodylook] = useState(0);
  const [facialhair, setFacialhair] = useState(0);
  const [bodyweight, setBodyweight] = useState(0);
  const [ethencity, setEthencity] = useState(0);
  const [disabilitie, setDisabilitie] = useState(0);
  const [drink, setDrink] = useState(0);
  const [smoke, setSmoke] = useState(0);
  const [animal, setAnimal] = useState(0);
  const [eatinghabit, setEatinghabit] = useState(0);
  const [marital, setMarital] = useState(0);
  const [havechildren, setHavechildren] = useState(0);
  const [wantchildren, setWantchildren] = useState(0);
  const [howmanaychildren, setHowmanaychildren] = useState(0);
  const [oldestchildren, setOldestchildren] = useState(0);
  const [youngestchilren, setYoungestchilren] = useState(0);
  const [ocupation, setOcupation] = useState(0);
  const [anualincome, setAnualincome] = useState(0);
  const [hometype, setHometype] = useState(0);
  const [residence, setResidence] = useState(0);
  const [relocate, setRelocate] = useState(0);
  const [residenceStatus, setResidenceStatus] = useState(0);
  const [livingsituations, setLivingsituations] = useState(0);
  const [clothing, setClothing] = useState(0);
  const [romance, setRomance] = useState(0);
  const [characteristic, setcharacteristic] = useState(0);
  const [sports, setSports] = useState([]);
  const [traveling, setTraveling] = useState("");
  const [faith, setFaith] = useState(0);
  const [religiousvalues, setReligiousvalues] = useState(0);
  const [born, setBorn] = useState(0);
  const [attendReligious, setAttendReligious] = useState(0);
  const [readQuran, setReadQuran] = useState(0);
  const [polygamy, setPolygamy] = useState(0);
  const [familyValue, setFamilyValue] = useState(0);
  const [profileCreator, setProfileCreator] = useState(0);
  const [gambling, setGambling] = useState(0);
  const [car, setCar] = useState(false);
  const [motorcycle, setMotorcycle] = useState(false);
  const [height, setHeight] = useState(0);
  const [education, setEducation] = useState(0);
  const [minage, setMinage] = useState(18);
  const [maxage, setMaxage] = useState(99);
  const [minhei, setMinhei] = useState(0);
  const [maxhei, setMaxhei] = useState(0);
  const [hasphoto, setHasphoto] = useState(false);
  const [hobbies, setHobbies] = useState([]);
  const [hobbiesdata, setHobbiesdata] = useState([]);
  const [lastactivity, setLastactivity] = useState(0);
  const [lastActivityData, setLastActivityData] = useState([]);
  const [shortlist, setShortlist] = useState(0);
  const [shortListData, setShortListData] = useState([]);
  const [mydata, setMydata] = useState();
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const [laod, setLaod] = useState(false);
  const [age, setAge] = useState([]);
  const [usersHobbies, setUsersHobbies] = useState([]);
  const [userLanguage, setUserLanguage] = useState([]);
  const [pData, setpData] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [countryorgin, setCountryorgin] = useState(null);
  const [nationality, setNationality] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isProfileContentComplete, setIsProfileContentComplete] = useState(
    myown_data?.data?.profilecontent !== null ||
      myown_data?.data?.profilecontentdraft !== null
  );
  const [profileComplet, setProfileComplet] = useState(
    myown_data?.data?.profileattributes.profilepercentcomplete || 0
  );

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  function getCustomHeaders() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Accept-Language", language || "en");

    return myHeaders;
  }

  const showtoast = (result) => {
    if (result.success) {
      setrefresh(true);
      swalController(t("Toast_Successfully_Saved"), "", "success");
    } else {
      swalController(t("No_Changes_Have_Been_Made"), "", "warning");
    }
  };
  let filter1 = [];

  useEffect(() => {
    if (
      havechildren === "1" ||
      havechildren === "" ||
      havechildren === undefined ||
      havechildren === null
    ) {
      setOldestchildren("");
      setYoungestchilren("");
      setHowmanaychildren("");
    }
  }, [havechildren]);

  const doesntHaveChildren = React.useMemo(() => {
    return havechildren === "1" || !havechildren;
  }, [havechildren]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLaod(false);
    setrefresh(false);

    const getDataFromServer = async () => {
      // get profile data
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };
      fetch(
        `${process.env.REACT_APP_URI}metadata/lookups?LookupOptions=Profile`,
        requestOptions
      )
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((res) => setFormdata(res));
    };
    const getAge = async () => {
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };

      fetch(
        `${url}metadata/singletype?LookupSingleTypeOptions=Age`,
        requestOptions
      )
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((res) => setAge(res.age));
    };
    const getLastActivity = async () => {
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };

      fetch(
        `${url}metadata/singletype?LookupSingleTypeOptions=LastActivity`,
        requestOptions
      )
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((res) => setLastActivityData(res.lastactivity));
    };
    const getSortResults = async () => {
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };
      fetch(
        `${url}metadata/singletype?LookupSingleTypeOptions=SortResults`,
        requestOptions
      )
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((res) => setShortListData(res.sortresults));
    };
    const GetALlhobbies = async () => {
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };
      fetch(`${url}metadata/lookups?LookupOptions=Hobbies`, requestOptions)
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((response) => setHobbiesdata(response.hobbies));
    };
    const getMatchedData = async () => {
      const requestOptions = {
        method: "GET",
        headers: getCustomHeaders(),
        redirect: "follow",
      };
      fetch(`${url}profilematch`, requestOptions)
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((response) => {
          setMydata(response);

          if (response.data !== null) {
            const data = response.data.profilematchciterias;
            setpData(data);
            setMaxage(data.agemax);
            setMinage(data.agemin);
            //

            setBorn(data.backgroundandculturalvalues.convertid);
            setReligiousvalues(
              data.backgroundandculturalvalues.faithattitudeid
            );
            setEducation(data.backgroundandculturalvalues.educationid);
            setFaith(data.backgroundandculturalvalues.faithid); //fixed
            setFamilyValue(data.backgroundandculturalvalues.familyvaluesid);
            setNationality(data.backgroundandculturalvalues.nationalityid);
            setPolygamy(data.backgroundandculturalvalues.polygamyid);
            setProfileCreator(
              data.backgroundandculturalvalues.profilecreatorid
            );
            setReadQuran(data.backgroundandculturalvalues.readquranid);
            setAttendReligious(
              data.backgroundandculturalvalues.religionservicesid
            );
            //
            setMaxhei(data.bodyheightmax);
            setMinhei(data.bodyheightmin);
            setHasphoto(data.hasphoto);
            setLastactivity(data.lastactivityid);
            setShortlist(data.sortbyid);
            setUserLanguage(data.spokenlanguages); 
            setHeight(data.weightmax);
            setBodylook(data.bodyshapedescription.bodylookid);
            setBodytype(data.bodyshapedescription.bodytypeid);
            setDisabilitie(data.bodyshapedescription.disabilityid);
            setEthencity(data.bodyshapedescription.ethnicityid);
            setEyecolor(data.bodyshapedescription.eyescolortypeid);
            setEyewear(data.bodyshapedescription.eyewearid);
            setFacialhair(data.bodyshapedescription.facialhairid);
            setHaircolor(data.bodyshapedescription.haircolorid);
            setHairtype(data.bodyshapedescription.hairtypeid);
            setSkintype(data.bodyshapedescription.skintypeid);
            setBodylook(data.bodyshapedescription.bodylookid);
            setBodyweight(data.weightmin);

            setSelectedCountry(data.countries.length > 0 ? data.countries : []);
            setCountry(data.countries.length > 0 ? data.countries : []);

            setDrink(data.lifestylesandattitudes.alcoholattitudeid);
            setAnimal(data.lifestylesandattitudes.animalsattitudeid);
            setAnualincome(data.lifestylesandattitudes.annualincomeid);
            setHavechildren(data.lifestylesandattitudes.childrenhaveattitudeid);
            setWantchildren(data.lifestylesandattitudes.childrenattitudeid);
            setHowmanaychildren(
              data.lifestylesandattitudes.childrennumberattitudeid
            );
            setResidenceStatus(data.lifestylesandattitudes.residencystatusid);
            setOldestchildren(
              data.lifestylesandattitudes.childrenoldestattitudeid
            );
            setYoungestchilren(
              data.lifestylesandattitudes.childrenyoungesttattitudeidid
            );
            setClothing(data.lifestylesandattitudes.clothingattitudeid);
            setEatinghabit(data.lifestylesandattitudes.foodattitudeid);
            setGambling(data.lifestylesandattitudes.gamblingattitudeid);
            setCar(data.lifestylesandattitudes.haveacar);
            setMotorcycle(data.lifestylesandattitudes.haveamotorcycle);
            setHometype(data.lifestylesandattitudes.hometypeid);
            setLivingsituations(data.lifestylesandattitudes.livingsituationid);
            setMarital(data.lifestylesandattitudes.maritalstatusattitudeid);
            setRelocate(data.lifestylesandattitudes.relocateid);
            setResidence(data.lifestylesandattitudes.residenceid);
            setSmoke(data.lifestylesandattitudes.smokeattitudeid);
            setSports(data.profilesports);
            setTraveling(
              data.profiletravlingattitude !== null &&
                data.profiletravlingattitude.length > 0
                ? data.profiletravlingattitude[0].travlingattitudeid
                : []
            );
            setOcupation(data.lifestylesandattitudes.employmentattitudeid);
            setCityName(data.location.alternatecityname);
            setCountryorgin(data.location.countryoforiginid);
            setLatitude(data.location.gpslatitude);
            setLongitude(data.location.gpslongitude);
            setRomance(data.personalitydescription.romancetypeid);
            setcharacteristic(data.personalitydescription.characteristicsid);
            setUsersHobbies(data.profilehobbies);
          }
        });
    };
    GetALlhobbies();
    getSortResults();
    getLastActivity();
    getMatchedData();
    getAge();
    getDataFromServer();
  }, [language, changnow, laod, refresh]);

  const selectallcountry = (e) => {
    const makearray = (item) => {
      setCountry([...country, item]);
    };
    e.forEach((item) => {
      makearray(item.translistid);
    });
  };

  const filterallcountry = (e) => {
    let newc = [];

    const makearray = (item) => {
      newc = [...newc, item];
    };

    e.forEach((item) => {
      makearray(item.translistid);
    });
    setCountry(newc);
  };

  const saveMatchesSetting = () => {
    let spokenLanguages = [];
    if (selectlanguage.length > 0) {
      function newlanguage(langid) {
        let obj = {
          languageId: langid,
          isMotherLanguage: false,
        };
        return obj;
      }
      const ln = selectlanguage.map((item) => newlanguage(item.translistid));
      spokenLanguages = ln;
    } else {
      spokenLanguages = [];
    }
    let hobb = [];
    if (hobbies.length > 0) {
      function newHobby(hobbyId) {
        let obj = {
          hobbiesid: hobbyId,
        };
        return obj;
      }
      const hb = hobbies.map((item) => newHobby(item.translistid));
      hobb = hb;
    }
    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: "",
        gender: gender,
        agemin: minage,
        agemax: maxage,
        bodyheightmin: minhei,
        bodyheightmax: maxhei,
        weightmin: bodyweight,
        weightmax: height,
        lastactivityid: lastactivity,
        sortbyid: shortlist,
        hasphoto: hasphoto,
        countries: country,
        paginationquery: {
          pagenumber: 0,
          pagesize: 0,
        },
        bodyshapedescription: {
          hairtypeid: hairtype,
          bodylookid: bodylook,
          haircolorid: haircolor,
          eyescolortypeid: eyecolor,
          bodytypeid: bodytype,
          skintypeid: skintype,
          eyewearid: eyewear,
          disabilityid: disabilitie,
          facialhairid: facialhair,
          ethnicityid: ethencity,
          minbodyheightid: minhei,
          maxbodyheightid: maxhei,
        },
        lifestylesandattitudes: {
          smokeattitudeid: smoke,
          foodattitudeid: eatinghabit,
          alcoholattitudeid: drink,
          gamblingattitudeid: gambling,
          clothingattitudeid: clothing,
          childrenattitudeid: wantchildren,
          employmentattitudeid: ocupation,
          animalsattitudeid: animal,
          annualincomeid: anualincome,
          residenceid: residence,
          childrenhaveattitudeid: havechildren,
          childrennumberattitudeid: howmanaychildren,
          childrenoldestattitudeid: oldestchildren,
          childrenyoungesttattitudeidid: youngestchilren,
          residencystatusid: residenceStatus,
          relocateid: relocate,
          hometypeid: hometype,
          maritalstatusattitudeid: marital,
          livingsituationid: livingsituations,
          haveacar: car,
          haveamotorcycle: motorcycle,
        },
        personalitydescription: {
          romancetypeid: romance,
          characteristicsid: characteristic,
        },
        profilesports: sports,
        profiletravlingattitude:
          traveling !== null && traveling !== "" && traveling.length > 0
            ? [
                {
                  travlingattitudeid: traveling,
                },
              ]
            : [],
        backgroundandculturalvalues: {
          nationalityid: nationality,
          educationid: education,
          faithattitudeid: religiousvalues,
          faithid: faith,
          convertid: born,
          religionservicesid: attendReligious,
          readquranid: readQuran,
          profilecreatorid: profileCreator,
          familyvaluesid: familyValue,
          polygamyid: polygamy,
        },
        location: {
          gpslongitude: longitude,
          gpslatitude: latitude,
          cityid: 0,
          alternatecityname: cityName,
          countryoforiginid: countryorgin,
          countryofresidenceid: 0,
        },
        spokenlanguages: spokenLanguages.length > 0 ? spokenLanguages : [], //userLanguage,
        profilehobbies: hobb.length > 0 ? hobb : [], //usersHobbies,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: getCustomHeaders(),
      body: raw,
      redirect: "follow",
    };
    fetch(`${url}profilematch/addorupdate`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        showtoast(result);
      });
  };
  const resetMatch = () => {
    const requestOptions = {
      method: "DELETE",
      headers: getCustomHeaders(),
      redirect: "follow",
    };

    fetch(`${url}profilematch/delete`, requestOptions)
      .then((response) => {
        errorHandler(response);
        if (response.ok) {
          setMaxage(99);
          setMinage(18);
          setpData([]);
          setBorn(0);
          setReligiousvalues(0);
          setEducation(0);
          setFaith(0);
          setFamilyValue(0);
          setPolygamy(0);
          setProfileCreator(0);
          setReadQuran(0);
          setAttendReligious(0);
          setMaxhei(0);
          setMinhei(0);
          setLastactivity(0);
          setShortlist(0);
          setUserLanguage([]);
          setHeight(0);
          setBodylook(0);
          setBodytype(0);
          setDisabilitie(0);
          setEthencity(0);
          setEyecolor(0);
          setEyewear(0);
          setFacialhair(0);
          setHaircolor(0);
          setHairtype(0);
          setSkintype(0);
          setBodylook(0);
          setBodyweight(0);
          setCountryorgin(null);
          setSelectedCountry([]);
          setGender(myown_data?.data?.profileattributes?.gender);
          setHasphoto(false);
          setDrink(0);
          setAnimal(0);
          setAnualincome(0);
          setHavechildren(0);
          setHowmanaychildren(0);
          setOldestchildren(0);
          setYoungestchilren(0);
          setClothing(0);
          setEatinghabit(0);
          setGambling(0);
          setCar(false);
          setMotorcycle(false);
          setHometype(0);
          setLivingsituations(0);
          setMarital(0);
          setRelocate(0);
          setResidence(0);
          setSmoke(0);
          setSports([]);
          setTraveling("");
          setOcupation(0);
          setCityName("");
          setLatitude("");
          setLongitude("");
          setRomance(0);
          setcharacteristic(0);
          setUsersHobbies([]);
          setNationality("");
        }
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setLaod(!laod);
          swalController(t("Toast_Reset_Succesfully"), "", "success");
        } else {
          swalController(t("No_Changes_Have_Been_Made"), "", "warning");
        }
      });
  };

  if (usersHobbies.length > 0 && hobbiesdata.length > 0) {
    usersHobbies.forEach((item) => {
      let newarr = hobbiesdata.filter(
        (ind) => ind.translistid === item.hobbiesid
      );
      filter1 = [...filter1, newarr[0]];
    });
  }
  const savesports = (arr) => {
    let newarr = [];
    arr.map((item) => newarr.push({ sportattitudeid: item.translistid }));
    setSports(newarr);
  };
  let filter2 = [];
  let filter3 = [];

  if (selectedCountry.length > 0 && formdata !== null) {
    selectedCountry.forEach((item) => {
      let newarr = formdata.country.filter((ind) => ind.translistid === item);
      filter2 = [...filter2, newarr[0]];
    });
  }
  if (userLanguage.length > 0 && formdata !== null) {
    userLanguage.forEach((item) => {
      let newarr = formdata.language.filter(
        (ind) => ind.translistid === item.languageid
      );

      filter3 = [...filter3, newarr[0]];
    });
  }

  const filter4 = sports
    .map(({ sportattitudeid: sportId }) =>
      formdata?.sportattitude.find(({ translistid }) => translistid === sportId)
    )
    .filter((i) => i);

  const selectedValues = filter4.length > 0 ? filter4 : null;

  const confirmShowResetHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      text: t("Reset_Match_Setting_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Reset_Form"),
      cancelButtonText: t("Cancel"),
      onOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        resetMatch();
      }
    });
  };

  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 4000,
    });
  };

  const handleCheckboxChange = (e) => {
    setHasphoto(e.target.checked);
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Matches_Settings")}
        language={language}
      />
      {mydata && formdata !== null ? (
        <>
          <ToastContainer />
          <div className="container min-h-screen ">
            <div className="w-full flex flex-col lg:flex-row items-center gap-2 mb-10">
              <div className=" flex items-center justify-center flex-grow ">
                <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
                  <BsBlockquoteLeft className="text-sr" />
                  {t("Matches_Settings")}
                  <BsBlockquoteRight className="text-sr" />
                </h1>
              </div>
              {(isProfileContentComplete === false || profileComplet < 60) && (
                  <div className="flex justify-center w-full lg:w-auto lg:ml-auto mt-2 lg:mt-0">
                    <DynamicButton />
                  </div>
                )}
            </div>
            <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  text-gray-600 h-auto ">
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="gender" className="form-label">
                  {t("I_Am_A")}
                </label>
                <select
                  className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                  aria-label=".form-select-lg example"
                  id="gender"
                  //      onChange={(e) => setGender(e.target.value)}
                  value={gender}
                  disabled
                >
                  <option value={true}>{t("Male")}</option>
                  <option value={false}>{t("Female")}</option>
                </select>
              </div>
              <div className="flex flex-col  bg-white px-4 py-1 shadow-md rounded-md">
                <label
                  htmlFor="genderProfile_Looking_For"
                  className="form-label"
                >
                  {t("Profile_Looking_For")}
                </label>

                <select
                  className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                  aria-label=".form-select-lg example"
                  id="genderProfile_Looking_For"
                  value={!gender}
                  //  onChange={(e) => setGender(e.target.value)}
                  disabled
                >
                  <option value={false}>{t("Female")}</option>
                  <option value={true}>{t("Male")}</option>
                </select>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <div className="grid grid-cols-2 gap-4 items-center">
                  <div>
                    <p className="pb-2">{t("Minimum_Age")}</p>
                    <select
                      className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                      aria-label=".form-select-lg example"
                      id="minage"
                      onChange={(e) => setMinage(e.target.value)}
                      value={minage || ""}
                    >
                      {age.length > 0 &&
                        age.map((item, ind) => (
                          <option key={ind} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <p className="pb-2">{t("Maximum_Age")}</p>
                    <select
                      className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                      aria-label=".form-select-lg example"
                      id="maxage"
                      onChange={(e) => setMaxage(e.target.value)}
                      value={maxage || ""}
                    >
                      {age.length > 0 &&
                        age.map((item, ind) => (
                          <option key={ind} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="im" className="form-label">
                  {t("Last_Active")}
                </label>
                <select
                  className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                  aria-label=".form-select-lg example"
                  id="lastactivity"
                  value={lastactivity || ""}
                  onChange={(e) => setLastactivity(e.target.value)}
                >
                  {lastActivityData.map((item, ind) => (
                    <option key={ind} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col pt-3 bg-white px-4 py-1 shadow-md rounded-md">
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t("Has_Photo")}
                </label>
                <div>
                  <Checkbox
                    //onChange={(e) => setHasphoto(e.target.checked)}
                    onChange={handleCheckboxChange}
                    checked={hasphoto}
                  />
                </div>
              </div>
              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="shortlist" className="form-label">
                  {t("Sort_List_By")}
                </label>
                <select
                  className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                  aria-label=".form-select-lg example"
                  id="shortlist"
                  value={shortlist || ""}
                  onChange={(e) => setShortlist(e.target.value)}
                >
                  {shortListData.map((item, ind) => (
                    <option key={ind} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                <label htmlFor="countryorgin" className="form-label">
                  {t("Country_Of_Origin")}
                </label>
                <select
                  //className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                  className={`form-select form-select-md mb-3 font-medium text-sr text-sm ${
                    countryorgin === "" ||
                    countryorgin === undefined ||
                    countryorgin === null
                      ? "bg-red-100"
                      : "bg-green-100 "
                  }`}
                  aria-label=".form-select-lg example"
                  id="countryorgin"
                  value={countryorgin || ""}
                  onChange={(e) => setCountryorgin(e.target.value)}
                >
                  <option value={""}>{t("Select_Country")}</option>
                  {formdata.country.map((item, ind) => (
                    <option key={ind} value={item.translistid}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="bg-white px-4 py-1 shadow-md rounded-md">
                <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                  {t("CountryOfResidence")}
                </h2>
                <div className="mt-2 ">
                  {filter2.length > 0 ? (
                    <Multiselect
                      className={`form-select form-select-md mb-3 mt-2 py-2  ${
                        language === "ar" ? " text-right " : ""
                      } ${
                        filter2.length === 0 ? "bg-red-100" : "bg-green-100 "
                      }`}
                      options={formdata.country}
                      selectionLimit="5"
                      displayValue="name"
                      placeholder={t("Select")}
                      selectedValues={filter2}
                      onSelect={(e) => selectallcountry(e)}
                      onRemove={(e) => filterallcountry(e)}
                    />
                  ) : (
                    <Multiselect
                      className={`form-select form-select-md mb-3 mt-2 py-2  ${
                        language === "ar" ? " text-right " : ""
                      } ${
                        formdata?.country.length === 0
                          ? "bg-green-100"
                          : "bg-red-100 "
                      }`}
                      options={formdata.country}
                      selectionLimit="5"
                      displayValue="name"
                      placeholder={t("Select")}
                      onSelect={(e) => selectallcountry(e)}
                      onRemove={(e) => filterallcountry(e)}
                    />
                  )}
                </div>
              </div>
              <div className="bg-white px-4 py-1 shadow-md rounded-md">
                <div className="flex items-center justify-between">
                  <h2 className="text-sr text-md font-bold pb-1 border-b">
                    {t("Location_City")}
                  </h2>
                  <p className="text-gray-600">{cityName}</p>
                </div>
                <div className="mb-3 mt-2 py-2">
                  <Location
                    setLongitude={(value) => setLongitude(value)}
                    setLatitude={(value) => setLatitude(value)}
                    setCityName={(value) => setCityName(value)}
                    savedAddress={cityName}
                    useColors={true}
                  />
                </div>
              </div>
              <div className="pt-4 flex items-center gap-3 justify-end lg:col-span-3 ">
                <button
                  onClick={confirmShowResetHandler}
                  type="button"
                  className={`${
                    pData.length === 0
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-sr hover:bg-pr"
                  } py-2 rounded text-white w-54 text-md font-bold px-4 `}
                  disabled={pData.length === 0}
                >
                  {t("Reset_Form")}
                </button>
                <button
                  type="button"
                  onClick={saveMatchesSetting}
                  className="text-white px-3  rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer text-md font-bold "
                >
                  {t("Save")}
                </button>
              </div>
            </form>

            <Accordion className="mt-5" defaultActiveKey="1">
              <Accordion.Item className="my-2 shadow-md" eventKey="3">
                <Accordion.Header>
                  <div className="flex items-center gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium  pb-2 flex items-center">
                      {t("Appearance")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <p>
                          {pData.bodyshapedescription.haircolorid !== 0 && (
                            <>
                              {" "}
                              <span className="text-sr">
                                {t("Hair_Color")} :{" "}
                              </span>
                              {
                                formdata.haircolor.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.haircolorid
                                )[0].name
                              }{" "}
                              ,
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.hairtypeid !== 0 && (
                            <>
                              {" "}
                              <span className="text-sr">
                                {t("Hair_Type")} :{" "}
                              </span>{" "}
                              {
                                formdata.hairtype.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.hairtypeid
                                )[0].name
                              }{" "}
                              ,
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.eyewearid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Eye_Wear")} :{" "}
                              </span>{" "}
                              {
                                formdata.eyewear.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.eyewearid
                                )[0].name
                              }{" "}
                              ,{" "}
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.eyescolortypeid !== 0 && (
                            <>
                              <>
                                <span className="text-sr">
                                  {t("Eye_Color")} :{" "}
                                </span>{" "}
                              </>
                              <>
                                {formdata.eyescolortype.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.eyescolortypeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.skintypeid !== 0 && (
                            <>
                              {
                                <>
                                  <span className="text-sr">
                                    {t("Skin_Type")} :
                                  </span>
                                  <>
                                    {formdata.skintype.filter(
                                      (item) =>
                                        item.translistid ===
                                        pData.bodyshapedescription.skintypeid
                                    )[0].name + ","}
                                  </>
                                </>
                              }
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.bodytypeid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Body_Type")} :
                              </span>
                              <>
                                {formdata.bodytype.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.bodytypeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.bodylookid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Body_Look")} :
                              </span>
                              <>
                                {formdata.bodylook.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.bodylookid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.facialhairid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Facial_Hair")} :
                              </span>
                              <>
                                {formdata.facialhair.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.facialhairid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.weightmin !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Minimum_Weight")} :{" "}
                              </span>
                              <>
                                {formdata.bodyweight.filter(
                                  (item) => item.translistid === pData.weightmin
                                )[0].name + ","}{" "}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.weightmax !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Maximum_Weight")} ({t("kg")}):
                              </span>
                              <>
                                {formdata.bodyweight.filter(
                                  (item) => item.translistid === pData.weightmax
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.minbodyheightid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Minimum_Height")} ({t("kg")}):
                              </span>
                              <>
                                {formdata.bodyheight.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.minbodyheightid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.maxbodyheightid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Maximum_Height")} ({t("kg")}):
                              </span>
                              <>
                                {formdata.bodyheight.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.maxbodyheightid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.ethnicityid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Profile_Ethnicity_Is_Mostly")} :
                              </span>
                              <>
                                {formdata.ethnicitie.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.ethnicityid
                                )[0]?.name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.bodyshapedescription.disabilityid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Disabilities")} :
                              </span>
                              <>
                                {formdata.disabilitie.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.bodyshapedescription.disabilityid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="pt-2 ">
                    <EditItem
                      title={t("Hair_Color")}
                      handleSelect={(value) => setHaircolor(value)}
                      value={haircolor}
                      data={
                        gender === ("true" || true)
                          ? formdata.haircolor.filter(
                              (item) => item.gender !== "F"
                            )
                          : formdata.haircolor
                      }
                    />
                    <EditItem
                      title={t("Hair_Type")}
                      value={hairtype}
                      handleSelect={(value) => setHairtype(value)}
                      data={formdata.hairtype}
                    />
                    <EditItem
                      value={eyewear}
                      title={t("Eye_Wear")}
                      handleSelect={(value) => setEyewear(value)}
                      data={formdata.eyewear}
                    />
                    <EditItem
                      title={t("Eye_Color")}
                      handleSelect={(value) => setEyecolor(value)}
                      data={formdata.eyescolortype}
                      value={eyecolor}
                    />
                    <EditItem
                      value={skintype}
                      title={t("Skin_Type")}
                      handleSelect={(value) => setSkintype(value)}
                      data={formdata.skintype}
                    />
                    <EditItem
                      value={bodytype}
                      title={t("Body_Type")}
                      handleSelect={(value) => setBodytype(value)}
                      data={formdata.bodytype}
                    />
                    <EditItem
                      value={bodylook}
                      title={t("Body_Look")}
                      handleSelect={(value) => setBodylook(value)}
                      data={formdata.bodylook}
                    />
                    <EditItem
                      value={facialhair}
                      title={t("Facial_Hair")}
                      handleSelect={(value) => setFacialhair(value)}
                      data={formdata.facialhair}
                    />

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Minimum_Weight")}
                        </h2>
                        <select
                          className="form-select form-select-md mb-3 mt-2 py-2 w-52 bg-green-100"
                          aria-label=".form-select-lg example"
                          id="bodyweight"
                          value={bodyweight || ""}
                          onChange={(e) => setBodyweight(e.target.value)}
                        >
                          <option>{t("Any")}</option>
                          {formdata?.bodyweight.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name} ({t("kg")})
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Maximum_Weight")}
                        </h2>
                        <select
                          className="form-select form-select-md mb-3 mt-2 py-2 w-52  bg-green-100"
                          aria-label=".form-select-lg example"
                          id="height"
                          value={height || ""}
                          onChange={(e) => setHeight(e.target.value)}
                        >
                          <option>{t("Any")}</option>
                          {formdata.bodyweight.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name} ({t("kg")})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Minimum_Height")}
                        </h2>
                        <select
                          className="form-select form-select-md mb-3 mt-2 py-2 w-52  bg-green-100"
                          aria-label=".form-select-lg example"
                          id="minhei"
                          value={minhei || ""}
                          onChange={(e) => {
                            setMinhei(e.target.value);
                          }}
                        >
                          <option>{t("Any")}</option>
                          {formdata.bodyheight.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name} ({t("cm")})
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Maximum_Height")}
                        </h2>
                        <select
                          className="form-select form-select-md mb-3 mt-2 py-2 w-52  bg-green-100"
                          aria-label=".form-select-lg example"
                          id="maxhei"
                          value={maxhei || ""}
                          onChange={(e) => setMaxhei(e.target.value)}
                        >
                          <option>{t("Any")}</option>
                          {formdata.bodyheight.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name} ({t("cm")})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <EditItem
                      title={t("Profile_Ethnicity_Is_Mostly")}
                      data={formdata.ethnicitie}
                      handleSelect={(value) => setEthencity(value)}
                      value={ethencity}
                    />
                    <EditItem
                      handleSelect={(value) => setDisabilitie(value)}
                      title={t("Disabilities")}
                      data={formdata.disabilitie}
                      value={disabilitie}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="4">
                <Accordion.Header>
                  <div className="flex items-center gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center">
                      {t("Personality")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <p>
                          {pData.personalitydescription.romancetypeid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Romance_Type")} :
                              </span>
                              <>
                                {formdata.romancetype.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.personalitydescription.romancetypeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <EditItem
                    title={t("Romance_Type")}
                    data={formdata.romancetype}
                    handleSelect={(value) => setRomance(value)}
                    value={romance}
                  />
                  <EditItem
                    title={t("Characteristics")}
                    data={formdata?.characteristics}
                    handleSelect={(value) => setcharacteristic(value)}
                    value={characteristic}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="5">
                <Accordion.Header>
                  <div className="flex items-center gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center">
                      {t("Language")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <>
                          {pData.spokenlanguages.length > 0 && (
                            <>
                              {pData.spokenlanguages.map((item, ind) => (
                                <p key={ind}>
                                  {formdata.language.filter(
                                    (i) => i.translistid === item.languageid
                                  )[0].name + ","}
                                </p>
                              ))}
                            </>
                          )}
                        </>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="grid grid-cols-1 lg:grid-cols-3">
                    <div className="pt-4">
                      <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                        {t("Language")}
                      </h2>

                      {filter3.length > 0 ? (
                        <Multiselect
                          className={`form-select form-select-md mb-3 mt-2 py-2  ${
                            language === "ar" ? " text-right " : ""
                          } ${
                            filter3.length === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          required
                          options={formdata.language}
                          selectionLimit="5"
                          selectedValues={filter3}
                          displayValue="name"
                          placeholder={t("Select")}
                          onSelect={(e) => setSelectlanguage(e)}
                          onRemove={(e) => setSelectlanguage(e)}
                        />
                      ) : (
                        <Multiselect
                          className={`form-select form-select-md mb-3 mt-2 py-2  ${
                            language === "ar" ? " text-right " : ""
                          } ${
                            selectlanguage.length === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          required
                          options={formdata.language}
                          selectionLimit="5"
                          displayValue="name"
                          placeholder={t("Select")}
                          onSelect={(e) => setSelectlanguage(e)}
                          onRemove={(e) => setSelectlanguage(e)}
                        />
                      )}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="6">
                <Accordion.Header>
                  {" "}
                  <div className="flex gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium pb-2 ">
                      {t("Life_Style")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <p>
                          {pData.lifestylesandattitudes.alcoholattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Do_You_Drink")} :
                              </span>
                              <>
                                {formdata.alcoholattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .alcoholattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.smokeattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Do_You_Smoke")} :
                              </span>
                              <>
                                {formdata.smokeattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.smokeattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.animalsattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Loving_Animal")} :
                              </span>
                              <>
                                {formdata.animalsattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .animalsattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.foodattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Eating_Habits")} :
                              </span>
                              <>
                                {formdata.foodattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.foodattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.gamblingattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Gambling_Attitude")} :
                              </span>
                              <>
                                {formdata.gamblingattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .gamblingattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes
                            .maritalstatusattitudeid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Marital_Status")} :
                              </span>
                              <>
                                {formdata.maritalstatusattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .maritalstatusattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes
                            .childrenhaveattitudeid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Do_You_Have_Children")} :
                              </span>
                              <>
                                {formdata.childrenhaveattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .childrenhaveattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {(pData.lifestylesandattitudes.childrenattitudeid ??
                            0) !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Do_You_Want_Children")} :
                              </span>
                              <>
                                {formdata.childrenattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .childrenattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {(pData.lifestylesandattitudes
                            .childrennumberattitudeid ?? 0) !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("How_Many_Children_Do_You_Have")} :
                              </span>
                              <>
                                {formdata.childrennumberattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .childrennumberattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {(pData.lifestylesandattitudes
                            .childrenoldestattitudeid ?? 0) !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Oldest_Child")} :
                              </span>
                              <>
                                {formdata.childrenoldestattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .childrenoldestattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {(pData.lifestylesandattitudes
                            .childrenyoungesttattitudeidid ?? 0) !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Youngest_Child")} :
                              </span>
                              <>
                                {formdata.childrenyoungesttattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .childrenyoungesttattitudeidid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.employmentattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Ocupation")} :{" "}
                              </span>
                              <>
                                {formdata.employmentattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .employmentattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.annualincomeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Annual_Income")} :{" "}
                              </span>
                              <>
                                {formdata.annualincome.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.annualincomeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.hometypeid !== 0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Home_Type")} :
                              </span>
                              <>
                                {formdata.hometype.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.hometypeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.residencystatusid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Residency_Status")} :{" "}
                              </span>
                              <>
                                {formdata.residencystatus.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .residencystatusid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.residenceid !== 0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Residence")} :{" "}
                              </span>
                              <>
                                {formdata.residence.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.residenceid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.livingsituationid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Living_Situation")} :{" "}
                              </span>
                              <>
                                {formdata.livingsituation.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .livingsituationid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.relocateid !== 0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Willing_To_Relocate")} :{" "}
                              </span>
                              <>
                                {formdata.relocate.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes.relocateid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.clothingattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Clothing_Attitude")} :{" "}
                              </span>
                              <>
                                {formdata.clothingattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.lifestylesandattitudes
                                      .clothingattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>

                        <p>
                          {pData.lifestylesandattitudes.haveacar ? (
                            <>
                              <span className="text-sr">
                                {t("Have_Any_Car")} :
                              </span>{" "}
                              {t("Yes")},
                            </>
                          ) : (
                            <>
                              <span className="text-sr">
                                {t("Have_Any_Car")} :
                              </span>{" "}
                              {t("No")},
                            </>
                          )}
                        </p>
                        <p>
                          {pData.lifestylesandattitudes.haveamotorcycle ? (
                            <>
                              {" "}
                              <span className="text-sr">
                                {t("Have_Motorcycle")} :
                              </span>{" "}
                              {t("Yes")},
                            </>
                          ) : (
                            <>
                              {" "}
                              <span className="text-sr">
                                {t("Have_Motorcycle")} :
                              </span>{" "}
                              {t("No")},
                            </>
                          )}
                        </p>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="pt-2 ">
                    <EditItem
                      title={t("Do_You_Drink")}
                      data={formdata.alcoholattitude}
                      handleSelect={(value) => setDrink(value)}
                      value={drink}
                    />
                    <EditItem
                      title={t("Do_You_Smoke")}
                      data={formdata.smokeattitude}
                      handleSelect={(value) => setSmoke(value)}
                      value={smoke}
                    />
                    <EditItem
                      title={t("Loving_Animal")}
                      data={formdata.animalsattitude}
                      handleSelect={(value) => setAnimal(value)}
                      value={animal}
                    />

                    <EditItem
                      title={t("Eating_Habits")}
                      data={formdata.foodattitude}
                      handleSelect={(value) => setEatinghabit(value)}
                      value={eatinghabit}
                    />
                    <EditItem
                      title={t("Gambling_Attitude")}
                      data={formdata.gamblingattitude}
                      handleSelect={(value) => setGambling(value)}
                      value={gambling}
                    />
                    <EditItem
                      title={t("Marital_Status")}
                      data={
                        gender === ("true" || true)
                          ? formdata.maritalstatusattitude.filter(
                              (item) => item.gender !== "F"
                            )
                          : formdata.maritalstatusattitude.filter(
                              (item) => item.gender !== "M"
                            )
                      }
                      handleSelect={(value) => setMarital(value)}
                      value={marital}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Do_You_Have_Children")}
                        </h2>
                        <select
                          //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                          className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                            havechildren === "" ||
                            havechildren === undefined ||
                            havechildren === null ||
                            havechildren === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          aria-label=".form-select-lg example"
                          id="havechildren"
                          value={havechildren || ""}
                          onChange={(e) => setHavechildren(e.target.value)}
                        >
                          {/* <option>{t("Any")}</option> */}
                          <option value="">{t("Select_Options")}</option>
                          {formdata.childrenhaveattitude.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Do_You_Want_Children")}
                        </h2>
                        <select
                          // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                          className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                            wantchildren === "" ||
                            wantchildren === undefined ||
                            wantchildren === null ||
                            wantchildren === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          aria-label=".form-select-lg example"
                          id="wantchildren"
                          value={wantchildren || ""}
                          onChange={(e) => setWantchildren(e.target.value)}
                        >
                          {/* <option>{t("Any")}</option> */}
                          <option value="">{t("Select_Options")}</option>
                          {formdata.childrenattitude.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("How_Many_Children_Do_You_Have")}
                        </h2>
                        <select
                          disabled={doesntHaveChildren}
                          // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                          className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                            howmanaychildren === "" ||
                            howmanaychildren === undefined ||
                            howmanaychildren === null ||
                            howmanaychildren === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          aria-label=".form-select-lg example"
                          id="im"
                          value={howmanaychildren || ""}
                          onChange={(e) => setHowmanaychildren(e.target.value)}
                        >
                          {/* <option>{t("Any")}</option> */}
                          <option value="">{t("Select_Options")}</option>
                          {formdata.childrennumberattitude.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Oldest_Child")}
                        </h2>
                        <select
                          disabled={doesntHaveChildren}
                          // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                          className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                            oldestchildren === "" ||
                            oldestchildren === undefined ||
                            oldestchildren === null ||
                            oldestchildren === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          aria-label=".form-select-lg example"
                          id="im"
                          value={oldestchildren || ""}
                          onChange={(e) => setOldestchildren(e.target.value)}
                        >
                          {/* <option>{t("Any")}</option> */}
                          <option value="">{t("Select_Options")}</option>
                          {formdata.childrenoldestattitude.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Youngest_Child")}
                        </h2>
                        <select
                          disabled={doesntHaveChildren}
                          // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                          className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                            youngestchilren === "" ||
                            youngestchilren === undefined ||
                            youngestchilren === null ||
                            youngestchilren === 0
                              ? "bg-red-100"
                              : "bg-green-100 "
                          }`}
                          aria-label=".form-select-lg example"
                          id="im"
                          value={youngestchilren || ""}
                          onChange={(e) => setYoungestchilren(e.target.value)}
                        >
                          {/* <option>{t("Any")}</option> */}
                          <option value="">{t("Select_Options")}</option>
                          {formdata.childrenyoungesttattitude.map((item) => (
                            <option
                              key={item.translistid}
                              value={item.translistid}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          &nbsp;
                        </h2>
                      </div>
                    </div>
                    <EditItem
                      title={t("Ocupation")}
                      data={formdata.employmentattitude}
                      handleSelect={(value) => setOcupation(value)}
                      value={ocupation}
                    />
                    <EditItem
                      title={t("Annual_Income")}
                      data={formdata.annualincome}
                      handleSelect={(value) => setAnualincome(value)}
                      value={anualincome}
                    />
                    <EditItem
                      title={t("Home_Type")}
                      handleSelect={(value) => setHometype(value)}
                      data={formdata.hometype}
                      value={hometype}
                    />
                    <EditItem
                      title={t("Residency_Status")}
                      data={formdata.residencystatus}
                      handleSelect={(value) => setResidenceStatus(value)}
                      value={residenceStatus}
                    />
                    <EditItem
                      title={t("Residence")}
                      handleSelect={(value) => setResidence(value)}
                      data={formdata.residence}
                      value={residence}
                    />
                    <EditItem
                      title={t("Living_Situation")}
                      handleSelect={(value) => setLivingsituations(value)}
                      data={formdata.livingsituation}
                      value={livingsituations}
                    />
                    <EditItem
                      title={t("Willing_To_Relocate")}
                      handleSelect={(value) => setRelocate(value)}
                      data={formdata.relocate}
                      value={relocate}
                    />
                    <EditItem
                      title={t("Clothing_Attitude")}
                      data={formdata.clothingattitude}
                      handleSelect={(value) => setClothing(value)}
                      value={clothing}
                    />

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b">
                          {t("Have_Any_Car")}
                        </h2>
                        <FormControl component="fieldset">
                          <RadioGroup
                            className={`rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                              car === "" || car === undefined || car === null
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            row
                            aria-label="car"
                            name="row-radio-buttons-group"
                            value={car}
                            onChange={(e) => setCar(e.target.value)}
                          >
                            <FormControlLabel
                              control={<Redio val={true} />}
                              label={t("Yes")}
                            />
                            <FormControlLabel
                              control={<Redio val={false} />}
                              label={t("No")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="pt-2">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b">
                          {t("Have_Motorcycle")}
                        </h2>

                        <FormControl component="fieldset">
                          <RadioGroup
                            className={`rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                              motorcycle === "" ||
                              motorcycle === undefined ||
                              motorcycle === null
                                ? "bg-red-100"
                                : "bg-green-100 "
                            } `}
                            row
                            aria-label="motorcycle"
                            name="row-radio-buttons-group"
                            value={motorcycle}
                            onChange={(e) => {
                              setMotorcycle(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              control={<Redio val={true} />}
                              label={t("Yes")}
                            />
                            <FormControlLabel
                              control={<Redio val={false} />}
                              label={t("No")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="7">
                <Accordion.Header>
                  <div className="flex items-center gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center ">
                      {t("Profile_Background_And_Cultural_Values")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <p>
                          {pData.backgroundandculturalvalues.nationalityid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Nationality")} :
                              </span>
                              <>
                                {formdata.nationality.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .nationalityid
                                )[0]?.name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.faithid !== 0 && (
                            <>
                              <span className="text-sr">{t("Faith")} :</span>
                              <>
                                {formdata.faith.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues.faithid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.faithattitudeid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Religious_Values")} :{" "}
                              </span>
                              <>
                                {formdata.faithattitude.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .faithattitudeid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues
                            .religionservicesid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Attend_Religious_Services")} :{" "}
                              </span>
                              <>
                                {formdata.religionservice.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .religionservicesid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.convertid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {t("Born_Reverted")} :{" "}
                              </span>
                              <>
                                {formdata.convert.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues.convertid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.readquranid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Read_Quran")} :{" "}
                              </span>
                              <>
                                {formdata.readquran.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .readquranid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.polygamyid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Polygamy")} :{" "}
                              </span>
                              <>
                                {formdata.polygamy.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues.polygamyid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.educationid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Education")} :{" "}
                              </span>
                              <>
                                {formdata.education.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .educationid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues.familyvaluesid !==
                            0 && (
                            <>
                              <span className="text-sr">
                                {" "}
                                {t("Family_Values")} :{" "}
                              </span>
                              <>
                                {formdata.familyvalue.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .familyvaluesid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                        <p>
                          {pData.backgroundandculturalvalues
                            .profilecreatorid !== 0 && (
                            <>
                              <span className="text-sr">
                                {t("Profile_Creator")} :{" "}
                              </span>
                              <>
                                {formdata.profilecreator.filter(
                                  (item) =>
                                    item.translistid ===
                                    pData.backgroundandculturalvalues
                                      .profilecreatorid
                                )[0].name + ","}
                              </>
                            </>
                          )}
                        </p>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="pt-2 ">
                    <h6 className=" text-sr font-medium text-lg w-full pb-2 mb-2 border-b">
                      {t("Nationality")}
                    </h6>
                    <select
                      className={`px-2 py-2 border w-full text-sr font-medium rounded-md ${
                        nationality === "" ||
                        nationality === undefined ||
                        nationality === null
                          ? "bg-red-100"
                          : "bg-green-100 "
                      }`}
                      value={nationality || ""}
                      onChange={(e) => setNationality(e.target.value)}
                      // className="px-2 py-2 border w-full text-sr font-medium"
                    >
                      <option value="">{t("Select_Nationality")}</option>
                      {formdata.nationality.map((item, ind) => (
                        <option key={ind} value={item.translistid}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <EditItem
                      title={t("Faith")}
                      data={
                        gender === ("true" || true)
                          ? formdata.faith.filter((item) =>
                              [1, 2].includes(item.translistid)
                            )
                          : formdata.faith
                      }
                      handleSelect={(value) => setFaith(value)}
                      value={faith}
                    />
                    <div onClick={() => setShow(true)}>
                      {
                        <EditItem
                          title={t("Religious_Values")}
                          data={formdata.faithattitude}
                          handleSelect={(value) => setReligiousvalues(value)}
                          value={religiousvalues}
                        />
                      }
                    </div>
                    {show && (
                      <EditItem
                        title={t("Attend_Religious_Services")}
                        data={formdata.religionservice}
                        handleSelect={(value) => setAttendReligious(value)}
                        value={attendReligious}
                      />
                    )}
                    <EditItem
                      title={t("Born_Reverted")}
                      data={
                        gender === ("false" || false)
                          ? formdata.convert
                          : formdata.convert.filter(
                              (item) => item.gender !== "F"
                            )
                      }
                      handleSelect={(value) => setBorn(value)}
                      value={born}
                    />

                    <EditItem
                      title={t("Read_Quran")}
                      handleSelect={(value) => setReadQuran(value)}
                      data={formdata.readquran}
                      value={readQuran}
                    />
                    <EditItem
                      title={t("Polygamy")}
                      value={polygamy}
                      handleSelect={(value) => setPolygamy(value)}
                      data={formdata.polygamy}
                    />
                    <EditItem
                      title={t("Education")}
                      handleSelect={(value) => setEducation(value)}
                      data={formdata.education}
                      value={education}
                    />
                    <EditItem
                      value={familyValue}
                      title={t("Family_Values")}
                      handleSelect={(value) => setFamilyValue(value)}
                      data={formdata.familyvalue}
                    />
                    <EditItem
                      value={profileCreator}
                      title={t("Profile_Creator")}
                      data={formdata.profilecreator}
                      icon={true}
                      handleSelect={(value) => setProfileCreator(value)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="10">
                <Accordion.Header>
                  <h1 className="text-lg text-sr font-medium pb-2 flex items-center capitalize w-full">
                    {t("Sport_Attitude")}
                  </h1>
                  {/* {pData.length > 0 && (
                    <div className="flex items-center pl-3 flex-wrap gap-1 text-gray-500 text-xs">
                      <>
                        {pData.profilesports.length > 0 && (
                          <>
                            {pData.profilesports.map((item, ind) => (
                              <p key={ind}>
                                {formdata.sportattitude.filter(
                                  (i) => i.translistid === item.sportattitudeid
                                )[0]?.name + ","}
                              </p>
                            ))}
                          </>
                        )}
                      </>
                    </div>
                  )} */}
                </Accordion.Header>
                <Accordion.Body>
                  <Multiselect
                    className={`form-select form-select-md mb-3 mt-2 py-2  ${
                      language === "ar" ? " text-right " : ""
                    } ${sports.length === 0 ? "bg-red-100" : "bg-green-100 "}`}
                    required
                    options={formdata.sportattitude}
                    selectionLimit="5"
                    selectedValues={selectedValues}
                    displayValue="name"
                    placeholder={t("Select")}
                    onSelect={(e) => savesports(e)}
                    onRemove={(e) => savesports(e)}
                  />
                  <div className="w-full items-end flex justify-end"></div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="11">
                <Accordion.Header>
                  <h1 className="text-lg text-sr font-medium pb-2 flex items-center capitalize w-full">
                    {t("Travling_Attitude")}
                  </h1>
                  {/* <div className="pl-3">
                    <p className="text-xs text-gray-500">
                      {pData.length > 0 && (
                        <>
                          {pData.profiletravlingattitude !== null &&
                            pData.profiletravlingattitude.length > 0 &&
                            formdata.travlingattitude.filter(
                              (item) =>
                                item.translistid ===
                                pData.profiletravlingattitude[0]
                                  .travlingattitudeid
                            )[0]?.name}
                        </>
                      )}
                    </p>
                  </div> */}
                </Accordion.Header>
                <Accordion.Body>
                  <select
                    value={traveling || ""}
                    onChange={(e) => setTraveling(e.target.value)}
                    // className="px-2 py-2 border w-full"
                    className={`px-2 py-2 border w-full ${
                      traveling === "" ||
                      traveling === undefined ||
                      traveling === null
                        ? "bg-red-100"
                        : "bg-green-100 "
                    }`}
                  >
                    <option value="">{t("Select_Options")}</option>
                    {formdata.travlingattitude.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item className="my-2 shadow-md" eventKey="9">
                <Accordion.Header>
                  <div className="flex items-center gap-3 w-full">
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center">
                      {t("Hobbies")}
                    </h1>
                    {/* {pData.length > 0 && (
                      <div className="flex items-center flex-wrap gap-1 text-gray-500 text-xs">
                        <>
                          {pData.profilehobbies.length > 0 && (
                            <>
                              {pData.profilehobbies.map((item, ind) => (
                                <p key={ind}>
                                  {hobbiesdata.filter(
                                    (i) => i.translistid === item.hobbiesid
                                  )[0].name + ","}
                                </p>
                              ))}
                            </>
                          )}
                        </>
                      </div>
                    )} */}
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {filter1.length > 0 ? (
                    <Multiselect
                      className={`form-select form-select-md mb-3 mt-2 py-2  ${
                        language === "ar" ? " text-right " : ""
                      }${
                        filter1.length === 0 ? "bg-red-100" : "bg-green-100 "
                      }`}
                      options={hobbiesdata}
                      selectionLimit="10"
                      displayValue="name"
                      placeholder={t("Select")}
                      selectedValues={filter1}
                      onSelect={(e) => setHobbies(e)}
                      onRemove={(e) => setHobbies(e)}
                    />
                  ) : (
                    <Multiselect
                      className={`form-select form-select-md mb-3 mt-2 py-2  ${
                        language === "ar" ? " text-right " : ""
                      } ${
                        hobbies.length === 0 ? "bg-red-100" : "bg-green-100 "
                      }`}
                      options={hobbiesdata}
                      selectedValues={hobbies}
                      selectionLimit="10"
                      displayValue="name"
                      placeholder={t("Select")}
                      onRemove={(e) => setHobbies(e)}
                      onSelect={(e) => setHobbies(e)}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="w-full mt-4  flex justify-end gap-3 items-center pb-4">
              <button
                onClick={confirmShowResetHandler}
                type="button"
                className={`${
                  pData.length === 0
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-sr hover:bg-pr"
                } py-2 rounded text-white w-36 text-md font-bold`}
                disabled={pData.length === 0}
              >
                {t("Reset_Form")}
              </button>
              <button
                type="button"
                onClick={saveMatchesSetting}
                className="text-white px-3  rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer text-md font-bold"
              >
                {t("Save")}
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
    </>
  );
};
export default MatchSetting;
