import Cookies from "js-cookie";
import React, { useState, useEffect, useContext } from "react";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import { url } from "../../../api/Api";
import { MainContent } from "../../../App";
import { Form, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import fetchHandler from "../../../api/fetchHandler";
import heart from "../../../images/heart.gif";
import { toast, ToastContainer } from "react-toastify";
import { customToasterStyle } from "../../../utils/constants";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FixedHeader from "../../../layout/FixedHeader";

const ProfileDelete = () => {
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  const [markdelete, setMarkdelete] = useState(false);
  const { changnow, language, myown_data } = useContext(MainContent);
  const [password, setPassword] = useState("");
  const [reason, setReason] = useState([]);
  const [getselectedtype, setGetselectedtype] = useState(1);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [sid, setSid] = useState();
  const [dhtml, setDhtml] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  let myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Accept-Language", language || "en");

  const toastHandler = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const deleteProfile = (e) => {
    e.preventDefault();
    const raw = JSON.stringify({
      reasontypeid: getselectedtype,
      password: password,
    });
    fetchHandler(`${url}profile/delete`, "DELETE", raw, myHeaders).then(
      (result) => {
        if (result.success) {
          setReload(true);
          setMarkdelete(false);
          window.location.reload();
        }
        toastHandler(result);
      }
    );
  };
  const UndoDelete = () => {
    fetchHandler(
      `${url}profile/undoprofiledelete`,
      "POST",
      undefined,
      myHeaders
    ).then((result) => {
      toastHandler(result);
      if (result.success) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const getown = () => {
      setMarkdelete(myown_data.data.profileattributes.markedfordeletion);
    };

    const getDeleteReason = async () => {
      myHeaders.append("Accept-Language", language || "en");
      fetchHandler(
        `${process.env.REACT_APP_URI}metadata/singletype?LookupSingleTypeOptions=ReasonType`,
        "GET",
        myHeaders.delete("Content-Type")
      ).then((result) => {
        setReason(result.reasontype);
      });
    };
    const deletepage = () => {
      let mynHeaders = new Headers();
      mynHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
      fetchHandler(
        `${url}sitepage/pagetype?PageTypeId=23&LangCode=${language}`,
        "GET",
        undefined,
        mynHeaders
      ).then((result) => {
        if (result.data) {
          setDhtml(result.data.text);
        }
      });
    };
    deletepage();
    getDeleteReason();
    getown();
  }, [reload, changnow, language]);

  const createSuccessStory = (e) => {
    e.preventDefault();
    const raw = JSON.stringify({
      title: title,
      text: text,
      langcode: language,
    });

    fetchHandler(`${url}SuccessStory/add`, "POST", raw, myHeaders).then(
      (result) => {
        if (result.success) {
          setShow(true);
          setSid(result.identifier);
        }
        toastHandler(result);
      }
    );
  };
  const updateSuccessStory = (e) => {
    e.preventDefault();

    const raw = JSON.stringify({
      title: title,
      text: text,
      langcode: language,
      successstoriesid: sid,
    });

    fetchHandler(`${url}SuccessStory/update`, "PUT", raw, myHeaders).then(
      (result) => {
        toastHandler(result);
      }
    );
  };

  const handleMouseDown = (setShow) => (event) => {
    event.preventDefault();
    setShow(true);
  };

  const handleMouseUp = (setShow) => () => {
    setShow(false);
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Profile_Settings_Delete_Profile")}
        language={language}
      />
      <div className="container mt-24 lg:mt-16 lg:pt-10 min-h-screen bg-blue-50 mb-14">
        <div className=" w-full">
          <ProfileNavbar />
        </div>
        <ToastContainer />
        {reason.length !== 0 && dhtml !== null ? (
          <div className=" w-full pt-4 ">
            <div className=" w-full flex items-center justify-center mb-3">
              <h1 className="text-center mt-9  text-sr font-medium sm:text-xl  md:text-2xl  lg:text-4xl flex items-center gap-2 capitalize">
                <BsBlockquoteLeft className="text-sr " />
                {t("Profile_Settings_Delete_Profile")}
                <BsBlockquoteRight className="text-sr" />
              </h1>
            </div>
            <div className="w-full flex flex-col lg:mt-5">
              {!markdelete && (
                <div
                  className="w-full bg-white shadow-md p-3 rounded-md"
                  dangerouslySetInnerHTML={{ __html: dhtml }}
                ></div>
              )}
              <div className="w-full flex  justify-center mt-2 bg-sr rounded-md pt-4 pb-9 lg:mt-5 mb-5 lg:mb-0 border-2">
                <div className="w-3/4 focus-within:  mt-2">
                  {!markdelete ? (
                    <Form onSubmit={(e) => deleteProfile(e)}>
                      <Form.Select
                        className="text-sm text-sr font-medium shadow-md bg-white"
                        required
                        onChange={(e) => setGetselectedtype(e.target.value)}
                      >
                        <option>{t("Please_Select_A_Reason")}</option>
                        {reason.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Group
                        className="mt-3"
                        controlId="formBasicPassword"
                      >
                        <p className="text-white font-medium text-sm pb-1">
                          {t("Please_Insert_Your_Current_Password")}
                        </p>
                        <div className="relative flex">
                          <Form.Control
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            // type="password"
                            type={showPassword ? "text" : "password"}
                            className=" font-medium shadow-md bg-white text-sr text-sm"
                            placeholder={t("Password")}
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                t("Please_Insert_Your_Current_Password")
                              )
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                            style={{ direction: password ? "ltr" : "" }}
                          />
                          {password && (
                            <InputAdornment
                              position="end"
                              className="absolute right-2 top-1/2 transform -translate-y-1/2"
                            >
                              <IconButton
                                onMouseDown={handleMouseDown(setShowPassword)}
                                onMouseUp={handleMouseUp(setShowPassword)}
                                onTouchStart={handleMouseDown(setShowPassword)}
                                onTouchEnd={handleMouseUp(setShowPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )}
                        </div>
                      </Form.Group>
                      <button
                        type="submit"
                        className="px-2 py-2 mt-4 rounded bg-gray-100 text-md hover:bg-pr text-sr font-bold shadow-md"
                      >
                        {t("Profile_Settings_Delete_Profile")}
                      </button>
                    </Form>
                  ) : (
                    <div className="mt-7 lg:mt-7 flex items-center gap-2 flex-col  ">
                      <p className="text-xl text-white mb-3">
                        {t("Your_Profile_Will_Be_Deleted_Message")}
                      </p>{" "}
                      <button
                        onClick={UndoDelete}
                        className="px-2 py-2 rounded bg-green-500 hover:bg-pr text-white font-medium text-xl hover shadow-md"
                      >
                        {t("Cancel_Delete")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-full">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item className=" shadow-md" eventKey="0">
                      <Accordion.Header className="text-sr font-medium text-sm">
                        {t("Have_A_Success_Story_To_Share")}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form
                          onSubmit={
                            !show
                              ? (e) => createSuccessStory(e)
                              : (e) => updateSuccessStory(e)
                          }
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="text-sr font-medium text-sm">
                              {t("Title")}
                            </Form.Label>
                            <Form.Control
                              autoFocus
                              maxLength="100"
                              required
                              onChange={(e) => setTitle(e.target.value)}
                              type="text"
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  t("The_Subject_Field_Is_Mandatory")
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <div className=" w-full flex items-center justify-between">
                              <Form.Label className="text-sr font-medium text-sm">
                                {t("Content")}
                              </Form.Label>
                            </div>
                            <Form.Control
                              maxLength="4000"
                              required
                              onChange={(e) => setText(e.target.value)}
                              as="textarea"
                              rows={3}
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  t("The_content_Field_Is_Mandatory")
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </Form.Group>
                          <div className="flex items-center w-full justify-between">
                            <p className="text-green-500 text-xs font-bold">
                              {text.length} / 4000
                            </p>
                            {!show ? (
                              <button
                                type="submit"
                                className="text-md rounded-md bg-sr text-white hover:bg-pr px-2 py-2 w-16 font-bold"
                              >
                                {t("Save")}
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="text-md bg-sr rounded-md text-white hover:bg-pr px-2 py-2 w-16 font-bold"
                              >
                                {t("Update")}
                              </button>
                            )}
                          </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex items-start justify-center h-screen">
            <img src={heart} className="h-96  rounded-full" alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileDelete;
