import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import React, { useEffect, useState, useContext } from "react";
import { errorHandler, url } from "../../../api/Api";
import { MainContent } from "../../../App";
import { VscThreeBars } from "react-icons/vsc";
import Cart from "../card/Cart";
import { useTranslation } from "react-i18next";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import {
  AiOutlineFileSearch,
  AiOutlineDoubleRight,
  AiOutlineDoubleLeft,
} from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import heart from "../../../images/heart.gif";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const MatchFilter = () => {
  const { t } = useTranslation();
  const { grid, setGrid, language, changnow, setFreshReload } =
    useContext(MainContent);
  const [agentdata, setAgentdata] = useState([]);
  const [pagenumber, setPagenumber] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [agentid, setAgentid] = useState("");
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const [isAgentDeleted, setIsAgentDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });




  useEffect(() => {
    window.scrollTo(0, 0);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}agent/all`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setAgentdata(result.data);
        } else {
          setAgentdata([]);
          setSearchResult([]);
          saveProfileIds(null);
        }
      });

    if (isAgentDeleted) {
      setIsAgentDeleted(false);
      setAgentid("");
      setSearchResult([]);
      saveProfileIds(null);
    }
  }, [changnow, language, isAgentDeleted]);

  const agentSearch = (crl, check) => {
    setIsLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      profilematchciterias: {
        paginationquery: {
          pagenumber: check ? pagenumber + 1 : pagenumber - 1,
          pagesize: 10,
        },
      },
      agent: {
        agentid: agentid,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
          setPagenumber(result.pagenumber);
        } else {
          setSearchResult(null);
          saveProfileIds(null);
        }
        setIsLoading(false);
      });
  };

  const deleteAgent = (crl) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      agent: {
        agentid: agentid,
      },
    });

    const requestOptions = {
      method: "Delete",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setIsAgentDeleted(true);
      });
    setIsAgentDeleted(true);
    setFreshReload(true);
  };

  useEffect(() => {
    if (agentid !== "") {
      setIsLoading(true);
      agentSearch(`${url}profile/search?pageNumber=1&pageSize=10`, true);
    }
  }, [agentid]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Matches_Filter")}
        language={language}
      />

      <div className="pt-2 w-full flex items-center justify-center">
        <div className="container">
          <div className=" w-full flex items-center justify-center ">
            <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Matches_Filter")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <>
            {!isLoading ? (
              <div className="flex flex-col items-start my-2 gap-3">
                <div className=" flex flex-col items-start bg-white w-full shadow-md p-4 rounded-md">
                  {agentdata.length !== 0 && (
                    <h2 className="text-sr font-medium text-lg">
                      {t("Please_Select_From_The_Saved_Queries")}
                    </h2>
                  )}
                  <div>
                    <ToastContainer />
                    {agentdata.length !== 0 ? (
                      <div className="flex items-center gap-3 ">
                        {" "}
                        <select
                          className="form-select form-select-md  font-medium text-sr text-md mt-3"
                          aria-label=".form-select-lg example"
                          value={agentid}
                          id="im"
                          onChange={(e) => setAgentid(e.target.value)}
                        >
                          <option value="">{t("Select_Query")}</option>
                          {agentdata.map((item, ind) => (
                            <option key={ind} value={item.agentid}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        {agentid !== "" && (
                          <button
                            onClick={() =>
                              deleteAgent(
                                `${url}agent/delete?AgentId=${agentid}`
                              )
                            }
                            className="bg-sr text-white rounded-md hover:bg-pr px-2 py-2 text-md font-bold mt-3"
                          >
                            {t("DeleteAgent")}
                          </button>
                        )}
                      </div>
                    ) : (
                      <p className=" font-medium text-sr">
                        {t("No_Saved_Queries")}
                      </p>
                    )}
                    {agentdata.length < 5 ? (
                      <Link
                        to="/search/advance"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded mt-4"
                      >
                        <AiOutlineFileSearch
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Advanced_Search")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon font-bold text-2xl" />
                        ) : (
                          <AiOutlineDoubleRight className="icon bold  text-2xl" />
                        )}
                      </Link>
                    ) : (
                      <div className="mt-2 text-gray-300 font-bold">
                        {t(
                          "You_have_reached_the_maximum_allowed_limit_SearchQuery"
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  {searchResult !== null ? (
                    <>
                      {searchResult.length !== 0 && (
                        <>
                          {/* <h1 className="text-3xl mt-3 font-medium text-gray-700 w-full">
                      {t("Search_Result")}
                    </h1> */}
                          <SectionSeparator
                            Section_Title={t("Search_Result")}
                          ></SectionSeparator>

                          <div className="flex items-center w-full justify-end gap-3 mt-0">
                            <div>
                              <BsFillGrid3X3GapFill
                                onClick={() => setGrid(true)}
                                // className="cursor-pointer text-3xl text-sr"
                                className={`cursor-pointer text-3xl  ${
                                  grid ? "text-gray-400" : "text-sr"
                                }`}
                              />
                            </div>
                            <div>
                              <VscThreeBars
                                onClick={() => setGrid(false)}
                                // className="cursor-pointer text-4xl text-sr"
                                className={`cursor-pointer text-3xl  ${
                                  !grid ? "text-gray-400" : "text-sr"
                                }`}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              grid
                                ? "my-8 grid grid-cols-1 justify-center md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                                : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                            }
                          >
                            {searchResult.map((item, ind) => (
                              <Cart key={ind} data={item} />
                            ))}
                          </div>
                          <div className="w-full flex items-center justify-end gap-3 mb-5">
                            {prvurl !== null || nxturl !== null ? (
                              <>
                                {prvurl !== null ? (
                                  <button
                                    onClick={() => agentSearch(prvurl, false)}
                                    className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                                  >
                                    {t("Previous")}
                                  </button>
                                ) : (
                                  <button
                                    disabled
                                    className="px-3 py-2 bg-sr cursor-not-allowed text-white rounded-md hover:bg-pr"
                                  >
                                    {t("Previous")}
                                  </button>
                                )}
                                {searchResult.length !== 10 ? (
                                  <button
                                    disabled
                                    className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                                  >
                                    {t("Next")}
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => agentSearch(nxturl, true)}
                                    className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                                  >
                                    {t("Next")}
                                  </button>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                      {t("No_Search_Result_Found")}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="flex items-start justify-center h-screen">
                <img src={heart} className="h-96  rounded-full" alt="" />
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default MatchFilter;
