import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Cookies from "js-cookie";
import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { url, errorHandler } from "../../../api/Api";
import { MainContent } from "../../../App";
import EditItem from "../../profileSettings/pSettingsPages/EditItem";
import Location from "../../profileSettings/pSettingsPages/Location";
import Redio from "../../profileSettings/pSettingsPages/Radio";
import Checkbox from "@mui/material/Checkbox";
import { indigo } from "@mui/material/colors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ar";
import "dayjs/locale/da";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/se";
import "dayjs/locale/nl";
import "dayjs/locale/tr";
import "dayjs/locale/sv";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
//import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { AiOutlineDoubleRight } from "react-icons/ai";
import {
  BsBlockquoteLeft,
  BsBlockquoteRight,
  BsFillGrid3X3GapFill,
} from "react-icons/bs";
import { VscThreeBars, VscSearch } from "react-icons/vsc";
import Cart from "../card/Cart";
import heart from "../../../images/heart.gif";
import fetchHandler from "../../../api/fetchHandler";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import AutocompleteSelect from "../../common/AutocompleteSelect";
import TravelingSelect from "../../common/TravelingSelect";
import dayjs from "dayjs";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Advance = ({ advancesho, setAdvancesho }) => {
  const { language, changnow, grid, setGrid, setFreshReload, myown_data } =
    useContext(MainContent);
  const [formdata, setFormdata] = useState(null);
  const [country, setCountry] = useState([]);
  const [selectlanguage, setSelectlanguage] = useState([]);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [cityName, setCityName] = useState("");
  const [haircolor, setHaircolor] = useState(0);
  const [hairtype, setHairtype] = useState(0);
  const [eyewear, setEyewear] = useState(0);
  const [eyecolor, setEyecolor] = useState(0);
  const [skintype, setSkintype] = useState(0);
  const [bodytype, setBodytype] = useState(0);
  const [bodylook, setBodylook] = useState(0);
  const [facialhair, setFacialhair] = useState(0);
  const [minbodyweight, setMinBodyweight] = useState(0);
  const [ethencity, setEthencity] = useState(0);
  const [disabilitie, setDisabilitie] = useState(0);
  const [drink, setDrink] = useState(0);
  const [smoke, setSmoke] = useState(0);
  const [animal, setAnimal] = useState(0);
  const [eatinghabit, setEatinghabit] = useState(0);
  const [marital, setMarital] = useState(0);
  const [havechildren, setHavechildren] = useState(0);
  const [wantchildren, setWantchildren] = useState(0);
  const [howmanaychildren, setHowmanaychildren] = useState(0);
  const [oldestchildren, setOldestchildren] = useState(0);
  const [youngestchilren, setYoungestchilren] = useState(0);
  const [ocupation, setOcupation] = useState(0);
  const [anualincome, setAnualincome] = useState(0);
  const [hometype, setHometype] = useState(0);
  const [residence, setResidence] = useState(0);
  const [relocate, setRelocate] = useState(0);
  const [residenceStatus, setResidenceStatus] = useState(0);
  const [livingsituations, setLivingsituations] = useState(0);
  const [clothing, setClothing] = useState(0);
  const [romance, setRomance] = useState(0);
  const [characteristic, setcharacteristic] = useState(0);
  const [sports, setSports] = useState([]);
  const [traveling, setTraveling] = useState([]);
  const [faith, setFaith] = useState(0);
  const [religiousvalues, setReligiousvalues] = useState(0);
  const [born, setBorn] = useState(0);
  const [attendReligious, setAttendReligious] = useState(0);
  const [readQuran, setReadQuran] = useState(0);
  const [polygamy, setPolygamy] = useState(0);
  const [familyValue, setFamilyValue] = useState(0);
  const [profileCreator, setProfileCreator] = useState(0);
  const [gambling, setGambling] = useState(0);
  const [car, setCar] = useState(true);
  const [motorcycle, setMotorcycle] = useState(true);
  const [maxbodyweight, setMaxbodyweight] = useState(0);
  const [education, setEducation] = useState(0);
  const [minage, setMinage] = useState(18);
  const [maxage, setMaxage] = useState(99);
  const [minhei, setMinhei] = useState(0);
  const [maxhei, setMaxhei] = useState(0);
  const [hasphoto, setHasphoto] = useState(false);
  const [hobbies, setHobbies] = useState([]);
  const [hobbiesdata, setHobbiesdata] = useState([]);
  const [lastactivity, setLastactivity] = useState(0);
  const [lastActivityData, setLastActivityData] = useState([]);
  const [shortlist, setShortlist] = useState(0);
  const [shortListData, setShortListData] = useState([]);
  const [agent, setAgent] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [searchQuerytitle, setSearchQuerytitle] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const { t } = useTranslation();
  const [checkgender, setCheckgender] = useState(false);
  const [age, setAge] = useState([]);
  const [show, setShow] = useState(false);
  const [pagenumber, setPagenumber] = useState(0);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const [countryorigin, setCountryorigin] = useState(0);
  const [nationality, setNationality] = useState(0);
  const [error, setError] = useState("");
  const queryTitleinputRef = useRef("");

  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const currentDate = function getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    setStartDate(currentDate);

    return () => {
      setStartDate(new Date());
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getDataFromServer = async () => {
      // get profile data
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
      myHeaders.append("Accept-Language", language || "en");

      fetchHandler(
        `${process.env.REACT_APP_URI}metadata/lookups?LookupOptions=Profile`,
        "GET",
        undefined,
        myHeaders
      ).then((result) => {
        setFormdata(result);
      });
    };

    let getheders = new Headers();
    getheders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    getheders.append("Accept-Language", language || "en");

    const getAge = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=Age`,
        "GET",
        undefined,
        getheders
      ).then((result) => {
        setAge(result.age);
      });
    };

    const getLastActivity = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=LastActivity`,
        "GET",
        undefined,
        getheders
      ).then((result) => {
        setLastActivityData(result.lastactivity);
      });
    };

    const getSortResults = async () => {
      fetchHandler(
        `${url}metadata/singletype?LookupSingleTypeOptions=SortResults`,
        "GET",
        undefined,
        getheders
      ).then((result) => {
        setShortListData(result.sortresults);
      });
    };

    const GetALlhobbies = async () => {
      fetchHandler(
        `${url}metadata/lookups?LookupOptions=Hobbies`,
        "GET",
        undefined,
        getheders
      ).then((result) => {
        setHobbiesdata(result.hobbies);
      });
    };

    const checklocal = () => {
      if (localStorage.getItem("advance")) {
        setAdvancesho(false);
        let newHeaders = new Headers();
        newHeaders.append(
          "Authorization",
          `Bearer ${Cookies.get("accessToken")}`
        );

        newHeaders.append("Content-Type", "application/json");

        fetchHandler(
          `${url}profile/search?pageNumber=1&pageSize=10`,
          "POST",
          localStorage.getItem("advance"),
          newHeaders
        ).then((result) => {
          if (result.data.length > 0) {
            setSearchResult(result.data);
            saveProfileIds(result.data);
            setNxturl(result.nextpage);
            setPrvurl(result.previouspage);
            setPagenumber(result.pagenumber);
          } else {
            setSearchResult([]);
            saveProfileIds(null);
          }
        });
      }
    };
    checklocal();
    GetALlhobbies();
    getSortResults();
    getLastActivity();
    getAge();
    getDataFromServer();
  }, [language, changnow]);

  const showFrontPageProfiles = () => {
    // let myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    // const requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // fetch(
    //   `${url}profile/getfrontpageprofiles?gender=${
    //     checkgender === "true" || checkgender === true ? "male" : "female"
    //   }&SeekingMinAge=${minage}&SeekingMaxAge=${maxage}`,
    //   requestOptions
    // )
    //   .then((response) => {
    //     errorHandler(response);
    //     return response.json();
    //   })
    //   .then((result) => {
    //     setFontpageProfiles(result.data);
    //   });

    let selectedGender =
      checkgender === true || checkgender === "true" ? "male" : "female";
    const apiUrl = `${url}profile/getfrontpageprofiles?gender=${selectedGender}&SeekingMinAge=${minage}&SeekingMaxAge=${maxage}`;
    fetchHandler(apiUrl, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  };

  // select country
  const selectallcountry = (e) => {
    setCountry((prevCountry) => [...e.map((item) => item.translistid)]);
  };
  const SearchHere = (crlurl, check) => {
    window.scrollTo(0, 0);
    showFrontPageProfiles();
    let newln;
    if (selectlanguage.length !== 0) {
      function newlanguage(langid) {
        let obj = {
          languageId: langid,
          isMotherLanguage: false,
        };
        return obj;
      }
      let ln = selectlanguage.map((item) => newlanguage(item.translistid));
      newln = ln;
    }
    let ln;
    if (hobbies.length !== 0) {
      function newlanguage(langid) {
        let obj = {
          hobbiesid: langid,
        };
        return obj;
      }
      ln = hobbies.map((item) => newlanguage(item.translistid));
    }

    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      profilematchciterias: {
        freesearchtext: "",
        gender: checkgender,
        agemin: minage,
        agemax: maxage,
        bodyheightmin: minhei,
        bodyheightmax: maxhei,
        weightmin: 0,
        weightmax: 0,
        lastactivityid: lastactivity,
        sortbyid: shortlist,
        hasphoto: hasphoto,
        countries: country,
        paginationquery: {
          pagenumber: check ? pagenumber + 1 : pagenumber - 1,
          pagesize: 10,
        },
        bodyshapedescription: {
          hairtypeid: hairtype,
          bodylookid: bodylook,
          haircolorid: haircolor,
          eyescolortypeid: eyecolor,
          bodytypeid: bodytype,
          minbodyweightid: minbodyweight,
          maxbodyweightid: maxbodyweight,
          skintypeid: skintype,
          eyewearid: eyewear,
          disabilityid: disabilitie,
          facialhairid: facialhair,
          ethnicityid: ethencity,
          minbodyheightid: minhei,
          maxbodyheightid: maxhei,
        },
        lifestylesandattitudes: {
          smokeattitudeid: smoke,
          foodattitudeid: eatinghabit,
          alcoholattitudeid: drink,
          gamblingattitudeid: gambling,
          clothingattitudeid: clothing,
          employmentattitudeid: ocupation,
          animalsattitudeid: animal,
          annualincomeid: anualincome,
          residenceid: residence,
          childrenattitudeid: wantchildren,
          childrenhaveattitudeid: havechildren,
          childrennumberattitudeid: howmanaychildren,
          childrenoldestattitudeid: oldestchildren,
          childrenyoungesttattitudeidid: youngestchilren,
          residencystatusid: residenceStatus,
          relocateid: relocate,
          hometypeid: hometype,
          maritalstatusattitudeid: marital,
          livingsituationid: livingsituations,
        },
        personalitydescription: {
          romancetypeid: romance,
          characteristicsid: characteristic,
        },
        spokenlanguages: newln,
        backgroundandculturalvalues: {
          nationalityid: nationality,
          educationid: education,
          faithattitudeid: religiousvalues,
          faithid: faith,
          convertid: born,
          religionservicesid: attendReligious,
          readquranid: readQuran,
          profilecreatorid: profileCreator,
          familyvaluesid: familyValue,
          polygamyid: polygamy,
        },
        location: {
          gpslongitude: longitude,
          gpslatitude: latitude,
          cityid: 0,
          alternatecityname: cityName,
          countryoforiginid: countryorigin,
          countryofresidenceid: 0,
        },
        profilehobbies: ln,
        profilesports: sports,
        profiletravlingattitude: traveling,
      },
      agent: {
        title: searchQuerytitle,
        active: true,
        startdate: startDate || new Date(),
        enddate: endDate,
      },
    });

    localStorage.setItem("advance", raw);

    fetchHandler(crlurl, "POST", raw, myHeaders).then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
        setPagenumber(result.pagenumber);
      } else {
        setSearchResult([]);
        saveProfileIds(null);
      }
    });
  };
  useEffect(() => {
    setCheckgender(!myown_data.data.profileattributes.gender);
  }, [myown_data.data.profileattributes.gender]);

  const handleSearch = () => {
    setAdvancesho(true);
    setSearchResult(null);
    saveProfileIds(null);
    setNxturl(null);
    setPrvurl(null);
    setPagenumber(null);
    setTraveling([]);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (
      havechildren === "1" ||
      havechildren === "" ||
      havechildren === undefined ||
      havechildren === null
    ) {
      setOldestchildren("");
      setYoungestchilren("");
      setHowmanaychildren("");
    }
  }, [havechildren]);

  const doesntHaveChildren = React.useMemo(() => {
    return havechildren === "1" || !havechildren;
  }, [havechildren]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const validateSearchQuery = () => {
    if (
      agent &&
      !myown_data?.data?.profileattributes.ismaxagentslimitreached &&
      (!searchQuerytitle.trim() || searchQuerytitle.trim().length === 0)
    ) {
      setError("Search_Query_Name_IsRequired");

      if (
        error &&
        queryTitleinputRef !== null &&
        queryTitleinputRef?.current !== null
      ) {
        queryTitleinputRef?.current.focus();
      }

      return false;
    }

    return true;
  };

  const validateEndDate = () => {
    if (
      agent &&
      !myown_data?.data?.profileattributes.ismaxagentslimitreached &&
      endDate &&
      dayjs(startDate) >= dayjs(endDate)
    ) {
      setError("End_date_must_be_greater_than_the_start_date");

      return false;
    }

    return true;
  };

  const handleSearchClick = () => {
    if (!validateSearchQuery() || !validateEndDate()) {
      return;
    }
    setError("");
    SearchHere(`${url}profile/search`, true);
    setAdvancesho(false);

    if (agent && searchQuerytitle.trim().length > 0) {
      setFreshReload(true);
    }

    setAgent(false);
  };

  const inputListStyles = `form-select form-select-md mb-3 mt-2 py-2 ${
    country.length !== 0 ? "bg-green-100" : "bg-red-100"
  } ${language === "ar" ? "text-right" : ""}`;

  const handleCheckboxChange = (e) => {
    setHasphoto(e.target.checked);
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Advanced_Search")}
        language={language}
      />
      <div className=" w-full flex items-center justify-center ">
        <h1 className="text-center my-9 text-sr font-medium sm:text-xl  md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
          <BsBlockquoteLeft className="text-sr" />
          {t("Advanced_Search")}
          <BsBlockquoteRight className="text-sr" />
        </h1>
      </div>
      <ToastContainer />
      {formdata ? (
        <>
          {advancesho ? (
            <div className="min-h-screen pb-5">
              <form className="grid grid-cols-1 h-auto lg:grid-cols-3 gap-4  text-sr font-medium">
                <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                  <label htmlFor="mygender" className="form-label">
                    {t("I_Am_A")}
                  </label>
                  <select
                    className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                    aria-label=".form-select-lg example"
                    id="mygender"
                    value={checkgender}
                    disabled={true}
                    onChange={(e) => setCheckgender(e.target.value)}
                  >
                    <option value={false}>{t("Male")}</option>
                    <option value={true}>{t("Female")}</option>
                  </select>
                </div>
                <div className="flex flex-col  bg-white px-4 py-1 shadow-md rounded-md">
                  <label htmlFor="partnergender" className="form-label">
                    {t("I_Am_Looking_For")}
                  </label>
                  <select
                    className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                    aria-label=".form-select-lg example"
                    id="partnergender"
                    value={checkgender}
                    disabled={true}
                    onChange={(e) => setCheckgender(e.target.value)}
                  >
                    <option value={false}>{t("Female")}</option>
                    <option value={true}>{t("Male")}</option>
                  </select>
                </div>
                <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                  <div className="grid grid-cols-2 gap-4 items-center">
                    <div>
                      <p className="pb-2">{t("Minimum_Age")}</p>
                      <select
                        className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                        aria-label=".form-select-lg example"
                        id="minage"
                        onChange={(e) => setMinage(e.target.value)}
                        value={minage}
                      >
                        {age.length !== 0 &&
                          age.map((item, ind) => (
                            <option key={ind} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <p className="pb-2">{t("Maximum_Age")}</p>
                      <select
                        className="form-select form-select-md mb-3 font-medium text-sr text-sm"
                        aria-label=".form-select-lg example"
                        id="maxage"
                        onChange={(e) => setMaxage(e.target.value)}
                        value={maxage}
                      >
                        {age.length !== 0 &&
                          age.map((item, ind) => (
                            <option key={ind} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                  <label htmlFor="lastactivity" className="form-label">
                    {t("Last_Active")}
                  </label>
                  <select
                    className="form-select form-select-md mb-3 font-medium text-sm text-sr"
                    aria-label=".form-select-lg example"
                    id="lastactivity"
                    value={lastactivity}
                    onChange={(e) => setLastactivity(e.target.value)}
                  >
                    {lastActivityData.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col pt-3 bg-white px-4 py-1 shadow-md rounded-md">
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    {t("Has_Photo")}
                  </label>
                  <div>
                    <Checkbox
                      // onChange={(e) => setHasphoto(e.target.checked)}
                      onChange={handleCheckboxChange}
                      checked={hasphoto}
                      sx={{
                        color: indigo[900],
                        "&.Mui-checked": {
                          color: indigo[900],
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                  <label htmlFor="shortlist" className="form-label">
                    {t("Sort_List_By")}
                  </label>
                  <select
                    className="form-select form-select-md mb-3 text-sm font-medium text-sr"
                    aria-label=".form-select-lg example"
                    id="shortlist"
                    value={shortlist}
                    onChange={(e) => setShortlist(e.target.value)}
                  >
                    {shortListData.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col bg-white px-4 py-1 shadow-md rounded-md">
                  <label htmlFor="im" className="form-label">
                    {t("Country_Of_Origin")}
                  </label>
                  {/* <select
                    className="form-select form-select-md mb-3 text-sm font-medium text-sr"
                    aria-label=".form-select-lg example"
                    id="im"
                    value={countryorigin}
                    onChange={(e) => setCountryorigin(e.target.value)}
                  >
                    {formdata.country.map((item, ind) => (
                      <option key={ind} value={item.translistid}>
                        {item.name}
                      </option>
                    ))}
                  </select> */}
                  <AutocompleteSelect
                    countryorigin={countryorigin}
                    setCountryorigin={setCountryorigin}
                    formdata={formdata}
                  />
                </div>
                <div className="bg-white px-4 py-1 shadow-md rounded-md">
                  <h2 className="text-sr text-sm font-medium pb-1 border-b mb-1">
                    {t("CountryOfResidence")}
                  </h2>

                  <div className="mt-2 ">
                    <Multiselect
                      className={inputListStyles}
                      options={formdata?.country}
                      selectionLimit="5"
                      displayValue="name"
                      placeholder={t("Select")}
                      onSelect={(e) => selectallcountry(e)}
                      onRemove={(e) => selectallcountry(e)}
                    />
                  </div>
                </div>

                <div className="bg-white px-4 py-1 shadow-md rounded-md">
                  <h2 className="text-sr text-sm font-medium pb-1 border-b">
                    {t("Location_City")}
                  </h2>
                  <div className="mb-3 pt-1">
                    <Location
                      setLongitude={(value) => setLongitude(value)}
                      setLatitude={(value) => setLatitude(value)}
                      setCityName={(value) => setCityName(value)}
                      savedAddress={""}
                      useColors={true}
                    />
                  </div>
                </div>
              </form>
              <div className="w-full items-end flex justify-end">
                <button
                  type="button"
                  onClick={handleSearchClick}
                  className="text-white px-3 mt-4 text-md font-bold rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer"
                >
                  {t("Search")}
                </button>
              </div>
              <Accordion className="mt-5" defaultActiveKey="1">
                <Accordion.Item className="my-2 shadow-md" eventKey="3">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium  pb-2 flex items-center w-full">
                      {t("Appearance")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="pt-2 ">
                      <EditItem
                        title={t("Hair_Color")}
                        handleSelect={(value) => setHaircolor(value)}
                        value={haircolor}
                        // data={
                        //   checkgender === ("true" || true)
                        //     ? formdata?.haircolor.filter(
                        //         (item) => item.gender !== "F"
                        //       )
                        //     : formdata.haircolor
                        // }
                        data={
                          checkgender === "true" || checkgender === true
                            ? (formdata?.haircolor || []).filter(
                                (item) => item.gender !== "F"
                              )
                            : formdata?.haircolor || []
                        }
                      />
                      <EditItem
                        title={t("Hair_Type")}
                        value={hairtype}
                        handleSelect={(value) => setHairtype(value)}
                        data={formdata?.hairtype}
                      />
                      <EditItem
                        value={eyewear}
                        title={t("Eye_Wear")}
                        handleSelect={(value) => setEyewear(value)}
                        data={formdata?.eyewear}
                      />
                      <EditItem
                        title={t("Eye_Color")}
                        handleSelect={(value) => setEyecolor(value)}
                        data={formdata?.eyescolortype}
                        value={eyecolor}
                      />
                      <EditItem
                        value={skintype}
                        title={t("Skin_Type")}
                        handleSelect={(value) => setSkintype(value)}
                        data={formdata?.skintype}
                      />
                      <EditItem
                        value={bodytype}
                        title={t("Body_Type")}
                        handleSelect={(value) => setBodytype(value)}
                        data={formdata?.bodytype}
                      />
                      <EditItem
                        value={bodylook}
                        title={t("Body_Look")}
                        handleSelect={(value) => setBodylook(value)}
                        data={formdata?.bodylook}
                      />
                      <EditItem
                        value={facialhair}
                        title={t("Facial_Hair")}
                        handleSelect={(value) => setFacialhair(value)}
                        data={formdata?.facialhair}
                      />

                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Minimum_Weight")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              minbodyweight.length !== 0
                                ? "bg-green-100"
                                : "bg-red-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="minimumweight"
                            value={minbodyweight}
                            onChange={(e) => setMinBodyweight(e.target.value)}
                          >
                            <option>{t("Any")}</option>
                            {formdata?.bodyweight.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name} ({t("kg")})
                              </option>
                            ))}
                          </select>{" "}
                        </div>
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Maximum_Weight")}
                          </h2>
                          <select
                            //className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              maxbodyweight.length !== 0
                                ? "bg-green-100"
                                : "bg-red-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="maximumweight"
                            value={maxbodyweight}
                            onChange={(e) => setMaxbodyweight(e.target.value)}
                          >
                            <option>{t("Any")}</option>
                            {formdata?.bodyweight.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name} ({t("kg")})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Minimum_Height")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              minhei.length !== 0
                                ? "bg-green-100"
                                : "bg-red-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="minimumheight"
                            value={minhei}
                            onChange={(e) => setMinhei(e.target.value)}
                          >
                            <option>{t("Any")}</option>
                            {formdata?.bodyheight.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name} ({t("cm")})
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Maximum_Height")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              maxhei.length !== 0
                                ? "bg-green-100"
                                : "bg-red-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="maximumheight"
                            value={maxhei}
                            onChange={(e) => setMaxhei(e.target.value)}
                          >
                            <option>{t("Any")}</option>
                            {formdata?.bodyheight.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name} ({t("cm")})
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <EditItem
                        title={t("Profile_Ethnicity_Is_Mostly")}
                        data={formdata?.ethnicitie}
                        handleSelect={(value) => setEthencity(value)}
                        value={ethencity}
                      />
                      <EditItem
                        handleSelect={(value) => setDisabilitie(value)}
                        title={t("Disabilities")}
                        data={formdata?.disabilitie}
                        value={disabilitie}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                      {t("Personality")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <EditItem
                      title={t("Romance_Type")}
                      data={formdata?.romancetype}
                      handleSelect={(value) => setRomance(value)}
                      value={romance}
                    />
                    <EditItem
                      title={t("Characteristics")}
                      data={formdata?.characteristics}
                      handleSelect={(value) => setcharacteristic(value)}
                      value={characteristic}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                      {t("Language")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="grid grid-cols-1 lg:grid-cols-3">
                      <div className="pt-4">
                        <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                          {t("Language")}
                        </h2>

                        <Multiselect
                          // className={`form-select form-select-md mb-3 mt-2 py-2  ${
                          //   language === "ar" ? " text-right " : ""
                          // } ${
                          //   selectlanguage.length === 0
                          //     ? "bg-red-100"
                          //     : "bg-green-100 "
                          // }`}
                          //   required
                          className={inputListStyles}
                          options={formdata?.language}
                          selectionLimit="5"
                          selectedValues={selectlanguage}
                          displayValue="name"
                          placeholder={t("Select")}
                          onSelect={(e) => setSelectlanguage(e)}
                          onRemove={(e) => setSelectlanguage(e)}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                      {t("Life_Style")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="pt-2 ">
                      <EditItem
                        title={t("Do_You_Drink")}
                        data={formdata?.alcoholattitude}
                        handleSelect={(value) => setDrink(value)}
                        value={drink}
                      />
                      <EditItem
                        title={t("Do_You_Smoke")}
                        data={formdata?.smokeattitude}
                        handleSelect={(value) => setSmoke(value)}
                        value={smoke}
                      />
                      <EditItem
                        title={t("Loving_Animal")}
                        data={formdata?.animalsattitude}
                        handleSelect={(value) => setAnimal(value)}
                        value={animal}
                      />

                      <EditItem
                        title={t("Eating_Habits")}
                        data={formdata?.foodattitude}
                        handleSelect={(value) => setEatinghabit(value)}
                        value={eatinghabit}
                      />
                      <EditItem
                        title={t("Gambling_Attitude")}
                        data={formdata?.gamblingattitude}
                        handleSelect={(value) => setGambling(value)}
                        value={gambling}
                      />
                      <EditItem
                        title={t("Marital_Status")}
                        data={
                          checkgender === ("true" || true)
                            ? formdata?.maritalstatusattitude.filter(
                                (item) => item.gender !== "F"
                              )
                            : formdata?.maritalstatusattitude.filter(
                                (item) => item.gender !== "M"
                              )
                        }
                        handleSelect={(value) => setMarital(value)}
                        value={marital}
                      />
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Do_You_Have_Children")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            // aria-label=".form-select-lg example"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              havechildren === "" ||
                              havechildren === undefined ||
                              havechildren === null ||
                              havechildren === 0
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            id="havechildren"
                            value={havechildren}
                            onChange={(e) => setHavechildren(e.target.value)}
                          >
                            {/* <option>{t("Any")}</option> */}
                            <option value="">{t("Select_Options")}</option>
                            {formdata?.childrenhaveattitude.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Do_You_Want_Children")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              wantchildren === "" ||
                              wantchildren === undefined ||
                              wantchildren === null ||
                              wantchildren === 0
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="wantchildren"
                            value={wantchildren}
                            onChange={(e) => setWantchildren(e.target.value)}
                          >
                            {/* <option>{t("Any")}</option> */}
                            <option value="">{t("Select_Options")}</option>
                            {formdata?.childrenattitude.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("How_Many_Children_Do_You_Have")}
                          </h2>
                          <select
                            disabled={doesntHaveChildren}
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              howmanaychildren === "" ||
                              howmanaychildren === undefined ||
                              howmanaychildren === null ||
                              howmanaychildren === 0
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="howmanaychildren"
                            value={howmanaychildren}
                            onChange={(e) =>
                              setHowmanaychildren(e.target.value)
                            }
                          >
                            {/* <option>{t("Any")}</option> */}
                            <option value="">{t("Select_Options")}</option>
                            {formdata?.childrennumberattitude.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Oldest_Child")}
                          </h2>
                          <select
                            // className="form-select form-select-md mb-3 mt-2 py-2 w-52"
                            disabled={doesntHaveChildren}
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              oldestchildren === "" ||
                              oldestchildren === undefined ||
                              oldestchildren === null ||
                              oldestchildren === 0
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="doesntHaveChildren"
                            value={oldestchildren}
                            onChange={(e) => setOldestchildren(e.target.value)}
                          >
                            {/* <option>{t("Any")}</option> */}
                            <option value="">{t("Select_Options")}</option>
                            {formdata?.childrenoldestattitude.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            {t("Youngest_Child")}
                          </h2>
                          <select
                            disabled={doesntHaveChildren}
                            className={`form-select form-select-md mb-3 mt-2 py-2 w-52 ${
                              youngestchilren === "" ||
                              youngestchilren === undefined ||
                              youngestchilren === null ||
                              youngestchilren === 0
                                ? "bg-red-100"
                                : "bg-green-100 "
                            }`}
                            aria-label=".form-select-lg example"
                            id="youngestchilren"
                            value={youngestchilren}
                            onChange={(e) => setYoungestchilren(e.target.value)}
                          >
                            {/* <option>{t("Any")}</option> */}
                            <option value="">{t("Select_Options")}</option>
                            {formdata?.childrenyoungesttattitude.map((item) => (
                              <option
                                key={item.translistid}
                                value={item.translistid}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="pt-4">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b mb-1">
                            &nbsp;
                          </h2>
                        </div>
                      </div>
                      <EditItem
                        title={t("Ocupation")}
                        data={formdata?.employmentattitude}
                        handleSelect={(value) => setOcupation(value)}
                        value={ocupation}
                      />
                      <EditItem
                        title={t("Annual_Income")}
                        data={formdata?.annualincome}
                        handleSelect={(value) => setAnualincome(value)}
                        value={anualincome}
                      />
                      <EditItem
                        title={t("Home_Type")}
                        handleSelect={(value) => setHometype(value)}
                        data={formdata?.hometype}
                        value={hometype}
                      />
                      <EditItem
                        title={t("Residency_Status")}
                        data={formdata?.residencystatus}
                        handleSelect={(value) => setResidenceStatus(value)}
                        value={residenceStatus}
                      />
                      <EditItem
                        title={t("Residence")}
                        handleSelect={(value) => setResidence(value)}
                        data={formdata?.residence}
                        value={residence}
                      />
                      <EditItem
                        title={t("Living_Situation")}
                        handleSelect={(value) => setLivingsituations(value)}
                        data={formdata?.livingsituation}
                        value={livingsituations}
                      />
                      <EditItem
                        title={t("Willing_To_Relocate")}
                        handleSelect={(value) => setRelocate(value)}
                        data={formdata?.relocate}
                        value={relocate}
                      />
                      <EditItem
                        title={t("Clothing_Attitude")}
                        data={formdata?.clothingattitude}
                        handleSelect={(value) => setClothing(value)}
                        value={clothing}
                      />

                      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b">
                            {t("Have_Any_Car")}
                          </h2>

                          <FormControl component="fieldset">
                            <RadioGroup
                              className={`rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                                car === "" || car === undefined || car === null
                                  ? "bg-red-100"
                                  : "bg-green-100 "
                              }`}
                              row
                              aria-label="car"
                              name="row-radio-buttons-group"
                              // defaultValue={car}
                              value={car}
                              onChange={(e) => setCar(e.target.value)}
                            >
                              <FormControlLabel
                                control={<Redio val={true} />}
                                label={t("Yes")}
                              />
                              <FormControlLabel
                                control={<Redio val={false} />}
                                label={t("No")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="pt-2">
                          <h2 className="text-sr text-lg font-medium pb-1 border-b">
                            {t("Have_Motorcycle")}
                          </h2>

                          <FormControl component="fieldset">
                            <RadioGroup
                              className={`rounded-md border-2 mb-3 mt-2 py-2 w-52 ${
                                motorcycle === "" ||
                                motorcycle === undefined ||
                                motorcycle === null
                                  ? "bg-red-100"
                                  : "bg-green-100 "
                              } `}
                              row
                              aria-label="motorcycle"
                              name="row-radio-buttons-group"
                              value={motorcycle}
                              onChange={(e) => setMotorcycle(e.target.value)}
                            >
                              <FormControlLabel
                                control={<Redio val={true} />}
                                label={t("Yes")}
                              />
                              <FormControlLabel
                                control={<Redio val={false} />}
                                label={t("No")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                      {t("Profile_Background_And_Cultural_Values")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="pt-2 ">
                      <h6 className=" text-sr font-medium text-lg w-full pb-2 mb-2 border-b">
                        {t("Nationality")}
                      </h6>
                      <select
                        onChange={(e) => setNationality(e.target.value)}
                        // className="px-2 py-2 border w-full text-sr font-medium"
                        className={`px-2 py-2 border w-full text-sr font-medium rounded-md ${
                          nationality === "" ||
                          nationality === undefined ||
                          nationality === null ||
                          nationality === 0
                            ? "bg-red-100"
                            : "bg-green-100 "
                        }`}
                      >
                        <option value="">{t("Select_Nationality")}</option>
                        {formdata?.nationality.map((item, ind) => (
                          <option key={ind} value={item.translistid}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <EditItem
                        title={t("Faith")}
                        // data={
                        //   checkgender === ("true" || true)
                        //     ? formdata?.faith.filter((item) =>
                        //         [1, 2].includes(item.translistid)
                        //       )
                        //     : formdata.faith
                        // }
                        data={
                          checkgender === "true" || checkgender === true
                            ? (formdata?.faith || []).filter((item) =>
                                [1, 2].includes(item.translistid)
                              )
                            : formdata?.faith || []
                        }
                        handleSelect={(value) => setFaith(value)}
                        value={faith}
                      />
                      <div onClick={() => setShow(true)}>
                        <EditItem
                          title={t("Religious_Values")}
                          data={formdata?.faithattitude}
                          handleSelect={(value) => setReligiousvalues(value)}
                          value={religiousvalues}
                        />
                      </div>
                      {show && (
                        <EditItem
                          title={t("Attend_Religious_Services")}
                          data={formdata?.religionservice}
                          handleSelect={(value) => setAttendReligious(value)}
                          value={attendReligious}
                        />
                      )}
                      <EditItem
                        title={t("Born_Reverted")}
                        // data={
                        //   checkgender === ("false" || false)
                        //     ? formdata.convert
                        //     : formdata.convert.filter((item) => item.gender !== "F")
                        // }
                        data={
                          checkgender === "false" || checkgender === false
                            ? formdata?.convert
                            : (formdata?.convert || []).filter(
                                (item) => item.gender !== "F"
                              )
                        }
                        handleSelect={(value) => setBorn(value)}
                        value={born}
                      />

                      <EditItem
                        title={t("Read_Quran")}
                        handleSelect={(value) => setReadQuran(value)}
                        data={formdata?.readquran}
                        value={readQuran}
                      />
                      <EditItem
                        title={t("Polygamy")}
                        value={polygamy}
                        handleSelect={(value) => setPolygamy(value)}
                        data={formdata?.polygamy}
                      />
                      <EditItem
                        title={t("Education")}
                        handleSelect={(value) => setEducation(value)}
                        data={formdata?.education}
                        value={education}
                      />
                      <EditItem
                        value={familyValue}
                        title={t("Family_Values")}
                        handleSelect={(value) => setFamilyValue(value)}
                        data={formdata?.familyvalue}
                      />
                      <EditItem
                        value={profileCreator}
                        title={t("Profile_Creator")}
                        data={formdata?.profilecreator}
                        icon={true}
                        handleSelect={(value) => setProfileCreator(value)}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10" className="my-2 shadow-md">
                  <Accordion.Header>
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center capitalize w-full">
                      {t("Sport_Attitude")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Multiselect
                      // className={`form-select form-select-md mb-3 mt-2 py-2  ${
                      //   language === "ar" ? " text-right " : ""
                      // } ${
                      //   sports.length === 0 ? "bg-red-100" : "bg-green-100 "
                      // }`}
                      // required
                      className={inputListStyles}
                      options={formdata?.sportattitude}
                      selectionLimit="5"
                      displayValue="name"
                      placeholder={t("Select")}
                      onSelect={(e) => {
                        setSports([
                          ...sports,
                          {
                            sportattitudeid: e[sports.length]?.translistid,
                          },
                        ]);
                      }}
                      onRemove={(e) =>
                        setSports(
                          e.map((item) => ({
                            sportattitudeid: item.translistid,
                          }))
                        )
                      }
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center capitalize w-full">
                      {t("Travling_Attitude")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    {/* <select
                      onChange={(e) =>
                        setTraveling([
                          {
                            travlingattitudeid: e.target.value,
                          },
                        ])
                      }
                      className="px-2 py-2 border w-full"
                    >
                      {formdata.travlingattitude.map((item, ind) => (
                        <option key={ind} value={item.translistid}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                    <TravelingSelect
                      formdata={formdata}
                      setTraveling={setTraveling}
                      traveling={traveling}
                    />
                    {/* <select
                      value={traveling || ""}
                      onChange={(e) => setTraveling(e.target.value)}
                      // className="px-2 py-2 border w-full"
                      className={`px-2 py-2 border w-full ${
                        traveling === "" ||
                        traveling === undefined ||
                        traveling === null
                          ? "bg-red-100"
                          : "bg-green-100 "
                      }`}
                    >
                      <option value="">{t("Select_Options")}</option>
                      {formdata.travlingattitude.map((item, ind) => (
                        <option key={ind} value={item.translistid}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9" className="my-2 shadow-md">
                  <Accordion.Header>
                    {" "}
                    <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                      {t("Hobbies")}
                    </h1>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Multiselect
                      // className={`form-select form-select-md mb-3 mt-2 py-2 ${
                      //   language === "ar" ? " text-right " : ""
                      // } ${
                      //   hobbies.length === 0 ? "bg-red-100" : "bg-green-100 "
                      // }`}
                      //   required
                      className={inputListStyles}
                      options={hobbiesdata}
                      selectedValues={hobbies}
                      selectionLimit="10"
                      displayValue="name"
                      placeholder={t("Select")}
                      onSelect={(e) => setHobbies(e)}
                      onRemove={(e) => setHobbies(e)}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={agent}
                    onChange={(e) => {
                      setAgent(e.target.checked);
                      setError("");
                    }}
                    sx={{
                      color: indigo[800],
                      "&.Mui-checked": {
                        color: indigo[600],
                      },
                    }}
                  />{" "}
                  <p>
                    {t("Save_Query")}{" "}
                    <span className="text-gray-300 ml-10 font-bold">
                      {t("TotalSavedAgents")}&nbsp;
                      <span className="font-bold">
                        {myown_data?.data?.profileattributes.totalsavedagents} /
                        5
                      </span>
                    </span>
                  </p>
                </div>
                {agent && (
                  <Accordion defaultActiveKey="1" flush>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {" "}
                        <h1 className="text-lg text-sr font-medium pb-2 flex items-center w-full">
                          {t("Save_Search_Query")}{" "}
                        </h1>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion flush>
                          {!myown_data?.data?.profileattributes
                            .ismaxagentslimitreached ? (
                            <>
                              <label
                                htmlFor="SearchQueryTitle"
                                className="form-label text-xl text-gray-700"
                              >
                                {t("Title")}{" "}
                                <span className="text-red-500">{t(error)}</span>
                              </label>
                              <input
                                autoComplete="off"
                                placeholder={t("Search_Query_Title")}
                                maxLength={50}
                                type="text"
                                className="form-control w-full"
                                id="SearchQueryTitle"
                                //  value={searchQuerytitle}
                                ref={queryTitleinputRef}
                                autoFocus
                                onBlur={(e) =>
                                  setSearchQuerytitle(e.target.value)
                                }
                              />
                              <div className="mt-3 flex items-center gap-10">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale={language}
                                  // localeText={language}
                                >
                                  <DatePicker
                                    defaultValue={dayjs(startDate)}
                                    id="startdate"
                                    locale={language}
                                    label={t("Start_Date")}
                                    selected={startDate}
                                    // minDate={dayjs()}
                                    onChange={handleStartDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    slotProps={{
                                      textField: {
                                        placeholder: t("Start_Date"),
                                        inputProps: {
                                          dir:
                                            language === "ar" ? "rtl" : "ltr",
                                          style: {
                                            textAlign:
                                              language === "ar"
                                                ? "right"
                                                : "left",
                                          },
                                        },
                                        InputLabelProps: {
                                          shrink: true,
                                          style: {
                                            textAlign:
                                              language === "ar"
                                                ? "right"
                                                : "left",
                                          },
                                        },
                                      },
                                    }}
                                  />

                                  <DatePicker
                                    label={t("End_Date")}
                                    onChange={handleEndDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    //   minDate={dayjs()}
                                    slotProps={{
                                      textField: {
                                        placeholder: t("End_Date"),
                                        inputProps: {
                                          dir:
                                            language === "ar" ? "rtl" : "ltr",
                                          style: {
                                            textAlign:
                                              language === "ar"
                                                ? "right"
                                                : "left",
                                          },
                                        },
                                        InputLabelProps: {
                                          shrink: true,
                                          style: {
                                            textAlign:
                                              language === "ar"
                                                ? "right"
                                                : "left",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </div>
                            </>
                          ) : (
                            <div className="text-center text-gray-500 font-bold">
                              {t("You_have_reached_the_maximum_allowed_limit")}
                              <Link
                                to="/matches/filter"
                                className="mt-2 text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                              >
                                <FaFilter className=" mr-1" />
                                {t("Manage_Queries")}{" "}
                                <AiOutlineDoubleRight className="icon" />
                              </Link>
                            </div>
                          )}
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </Accordion>

              <div className="w-full items-end flex justify-end">
                <button
                  type="button"
                  onClick={handleSearchClick}
                  className="text-white px-3 mt-4 text-md font-bold rounded py-2 bg-sr hover:bg-pr transition ease-linear duration-300 cursor-pointer"
                >
                  {t("Search")}
                </button>
              </div>
            </div>
          ) : (
            <>
              {searchResult !== null ? (
                <>
                  {searchResult.length !== 0 ? (
                    <>
                      {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                        {t("Search_Result")}
                      </h1> */}
                      <SectionSeparator
                        Section_Title={t("Search_Result")}
                      ></SectionSeparator>
                      <div className="flex items-center justify-end gap-3 mt-0">
                        <div>
                          <VscSearch
                            onClick={handleSearch}
                            className="cursor-pointer text-4xl text-sr"
                          />
                        </div>
                        <div>
                          <BsFillGrid3X3GapFill
                            onClick={() => setGrid(true)}
                            // className="cursor-pointer text-3xl text-sr"
                            className={`cursor-pointer text-3xl  ${
                              grid ? "text-gray-400" : "text-sr"
                            }`}
                          />
                        </div>
                        <div>
                          <VscThreeBars
                            onClick={() => setGrid(false)}
                            // className="cursor-pointer text-4xl text-sr"
                            className={`cursor-pointer text-3xl  ${
                              !grid ? "text-gray-400" : "text-sr"
                            }`}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          grid
                            ? "my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto"
                            : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                        }
                      >
                        {searchResult.map((item, ind) => (
                          <Cart key={ind} data={item} />
                        ))}
                      </div>
                      <div className="w-full mt-14 flex items-center justify-end gap-5 mb-5">
                        {prvurl !== null || nxturl !== null ? (
                          <>
                            {prvurl !== null ? (
                              <button
                                onClick={() => SearchHere(prvurl, false)}
                                className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                              >
                                {t("Previous")}
                              </button>
                            ) : (
                              <button
                                disabled
                                className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                              >
                                {t("Previous")}
                              </button>
                            )}
                            {searchResult.length !== 10 ? (
                              <button
                                disabled
                                className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                              >
                                {t("Next")}
                              </button>
                            ) : (
                              <button
                                onClick={() => SearchHere(nxturl, true)}
                                className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                              >
                                {t("Next")}
                              </button>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                      <p> {t("No_Search_Result_Found")}</p>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-start justify-center h-screen">
                  <img src={heart} className="h-96  rounded-full" alt="" />
                </div>
              )}
              {fontpageProfiles.length > 0 && (
                <>
                  <SectionSeparator
                    Section_Title={t("Recommended_Profiles")}
                  ></SectionSeparator>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">

                    {t("Recommended_Profiles")}
                  </h1> */}
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <VscSearch
                        onClick={handleSearch}
                        className="cursor-pointer text-4xl text-sr"
                      />
                    </div>
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {fontpageProfiles.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
    </>
  );
};
export default Advance;
