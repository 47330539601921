import Cookies from "js-cookie";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Table,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Carousel,
  Card,
} from "react-bootstrap";
import {
  MdLocationOn,
  MdSportsKabaddi,
  MdOutlineRecommend,
} from "react-icons/md";
import { FiActivity, FiLock } from "react-icons/fi";
import { RiGenderlessLine } from "react-icons/ri";
import { useParams, Link, useNavigate } from "react-router-dom";
import { url } from "../../api/Api";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import "./psetting.css";
import { AiFillCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import { SiInformatica, SiLooker } from "react-icons/si";
import { GoInfo } from "react-icons/go";
import { IoImageOutline } from "react-icons/io5";
import { CgUnblock } from "react-icons/cg";
import {
  BsHeart,
  BsFillHeartFill,
  BsStar,
  BsStarFill,
  BsFlag,
  BsFlagFill,
  BsPersonBoundingBox,
  BsPersonLinesFill,
  BsTrophyFill,
  BsMessenger,
} from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import heart from "../../images/heart.gif";
import cover from "../../images/Image2.jpg";
import gold from "../../images/gold.png";
import basic from "../../images/basic.png";
import platinum from "../../images/platinum.png";
import { GiBackup, GiHobbitDwelling } from "react-icons/gi";
import {
  FaAmericanSignLanguageInterpreting,
  FaGlassCheers,
  FaStreetView,
  FaIdCard,
} from "react-icons/fa";
import fetchHandler from "../../api/fetchHandler";
import MakeRow from "./MakeRow";
import ProfilePhoto from "./ProfilePhoto";
import PeoplePhoto from "./PeoplePhoto";
import { MakeEmpRow, MakeEmpRow2, MakeSportRow } from "./Components";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../utils/constants";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

import {
  getPreviousProfileId,
  getNextProfileId,
} from "../../utils/profileNavigationUtils";
import Flag from "react-world-flags";

const PublicProfile = () => {
  const { t } = useTranslation();
  const {
    language,
    changnow,
    myown_data,
    isCurrentProfileApproved,
    isCurrentProfileAvailable,
    isMarkedForDeletion,
  } = useContext(MainContent);
  const { profileid } = useParams();
  const history = useNavigate();
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState();
  const [userid, setUserid] = useState("");
  const [location, setLocation] = useState("");
  const [title, setTitle] = useState("");
  const [aboutme, setAboutme] = useState(t(""));
  const [looking, setlooking] = useState(t(""));
  const [flag, setFlag] = useState(false);
  const [body, setBody] = useState("");
  const [formdata, setFormdata] = useState();
  const [getowndata, setGetowndata] = useState();
  const [profileattributes, setProfileattributes] = useState();
  const [albumcontainer, setAlbumcontainer] = useState();
  const [backgroundandculturalvalues, setBackgroundandculturalvalues] =
    useState();
  const [block, setBlock] = useState();
  const [like, setLike] = useState(false);
  const [faveourite, setFaveourite] = useState(false);
  const [bodyshapedescription, setBodyshapedescription] = useState();
  const [lifestylesandattitudes, setLifestylesandattitudes] = useState();
  const [profilehobbies, setProfilehobbies] = useState();
  const [personalitydescription, setPersonalitydescription] = useState();
  const [profilepicture, setProfilepicture] = useState();
  const [thumbnileimages, setThumbnileimages] = useState([]);
  const [mainImages, setmainImages] = useState([]);
  const [spokenlanguages, setSpokenlanguages] = useState();
  const [myid, setMyid] = useState();
  const [hobbies, setHobbies] = useState();
  const [abusement, setAbusement] = useState([]);
  const [ispaid, setIspaid] = useState(false);
  const [sendmessage, setSendmessage] = useState(false);
  const [reload, setReload] = useState(true);
  const [ppublic, setPpublic] = useState(true);
  const [viewpic, setViewpic] = useState([]);
  const [sports, setSports] = useState([]);
  const [traveling, setTraveling] = useState([]);
  const [inboxid, setInboxid] = useState(null);
  const [isinboxempty, setIsinboxempty] = useState(true);
  const [inboxarchived, setInboxarchived] = useState(false);
  const [countryorigin, setCountryorigin] = useState(null);
  const [countryoriginCode, setCountryoriginCode] = useState(null);
  const [country, setCountry] = useState(null);
  const lang = localStorage.getItem("i18nextLng");
  const [viewPhofilePhoto, setViewPhofilePhoto] = useState(false);
  const [mappedUsers, setMappedUsers] = useState([]);
  const [previousOrNextProfileId, setPreviousOrNextProfileId] = useState(null);
  const [enablePreviousProfileBtn, setEnablePreviousProfileBtn] =
    useState(true);
  const [enableNextProfileBtn, setEnableNextProfileBtn] = useState(true);
  // const enablePreviousProfileBtn = useRef(true);
  //const enableNextProfileBtn = useRef(true);
  const imageOnError = (event) => {
    event.currentTarget.src = gender ? noimage : muslimf;
  };

  const imageSrc = gender ? noimage : muslimf;

  function photomix(pic) {
    if (pic.isthumbnailblurred && mainImages.length !== 0) {
      setViewpic(mainImages);
    } else {
      const selectedPicture = mainImages.filter(
        (item) => item.pictureid === pic.parentpictureid
      );
      const oldv = mainImages.filter((item) => item !== selectedPicture[0]);
      const newv = [selectedPicture[0], ...oldv];
      setViewpic(newv);
    }
  }

  useEffect(() => {
    return () => {
      setAboutme(t(""));
      setlooking(t(""));
    };
  }, []);

  const getOwn = () => {
    setMyid(myown_data.data.profileid);
    setIspaid(myown_data.data.profileattributes.isapaidmember);
    setMyid(myown_data.data.profileid);

    if (myown_data.data.profileid === profileid) {
      history("/profile-settings/view");
    }
  };

  const getPublicData = () => {
    fetchHandler(`${url}profile?ProfileId=${profileid}`, "GET", undefined).then(
      (result) => {
        if (result.data.profilecontent !== null) {
          setTitle(result.data.profilecontent.title);
          setAboutme(result.data.profilecontent.aboutmetext);
          setlooking(result.data.profilecontent.lookingfortext);
        }
        setUsername(result.data.profileattributes.displayusername);
        setUserid(result.data.profileid);
        if (result.data.location !== null) {
          setLocation(result.data.location.alternatecityname);
          if (result.data.location.countryofresidenceid !== null) {
            setCountry(result.data.location.countryoforigincode);
          }

          setCountryorigin(result.data.location.countryoforiginid);
          setCountryoriginCode(result.data.location.countryoforigincode);
        }
        setInboxid(result.data.profileattributes.inboxid);
        setIsinboxempty(result.data.profileattributes.isinboxempty);
        setInboxarchived(result.data.profileattributes.isinboxarchived);
        setGender(result.data.profileattributes.gender);
        setBodyshapedescription(result.data.bodyshapedescription);
        setProfileattributes(result.data.profileattributes);
        setBlock(result.data.profileattributes.isblocked);
        setFaveourite(result.data.profileattributes.isfavorited);
        setLike(result.data.profileattributes.isliked);
        setFlag(result.data.profileattributes.isreportedasabuser);

        setProfilehobbies(result.data.profilehobbies);
        setPersonalitydescription(result.data.personalitydescription);
        setBackgroundandculturalvalues(result.data.backgroundandculturalvalues);
        setAlbumcontainer(result.data.albumcontainer);
        if (result.data.albumcontainer !== null) {
          setPpublic(result.data.albumcontainer.ispublic);
          if (result.data.albumcontainer.pictures.length !== 0) {
            const profilepic = result.data.albumcontainer.pictures.filter(
              (item) =>
                item.ismicroimage === false &&
                item.isprimary === true &&
                item.isthumbnail === false
            );
            if (result.data.profileattributes.hasaccesstopictures === true) {
              const thumbniles = result.data.albumcontainer.pictures.filter(
                (item) =>
                  item.isprimary === false &&
                  item.isthumbnail === true &&
                  item.ismicroimage === false &&
                  item.approved === true
              );
              const profileMainImages =
                result.data.albumcontainer.pictures.filter(
                  (item) =>
                    item.isprimary === false &&
                    item.isthumbnail === false &&
                    item.ismicroimage === false &&
                    item.approved === true
                );
              setmainImages(profileMainImages);
              setThumbnileimages(thumbniles);
            }
            if (result.data.profileattributes.hasaccesstopictures === false) {
              const thumbniles = result.data.albumcontainer.pictures.filter(
                (item) =>
                  item.isprimary === false &&
                  item.isthumbnailblurred === true &&
                  item.ismicroimage === false &&
                  item.approved === true
              );

              const profileMainImages =
                result.data.albumcontainer.pictures.filter(
                  (item) =>
                    item.isprimary === false &&
                    item.ismainblurred === true &&
                    item.ismicroimage === false &&
                    item.isthumbnail === false &&
                    item.approved === true
                );
              setmainImages(profileMainImages);
              setThumbnileimages(thumbniles);
            }

            setProfilepicture(profilepic[0]);
          } else {
            setmainImages([]);
            setThumbnileimages([]);
          }
        } else {
          setmainImages([]);
          setThumbnileimages([]);
        }
        setLifestylesandattitudes(result?.data?.lifestylesandattitudes);
        setSpokenlanguages(result?.data?.spokenlanguages);
        setGetowndata(result);
        setSports(result?.data?.profilesports);
        setTraveling(result?.data?.profiletravlingattitudes);
      }
    );
  };

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  myHeaders.append("Accept-Language", language || "en");

  const getDataFromServer = useCallback(async () => {
    fetchHandler(
      `${process.env.REACT_APP_URI}metadata/lookups?LookupOptions=Profile`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      setFormdata(result);
    });
  }, [myHeaders, formdata]);

  const getProfileHobbies = useCallback(async () => {
    fetchHandler(
      `${process.env.REACT_APP_URI}metadata/lookups?LookupOptions=Hobbies`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      setHobbies(result);
    });
  }, [hobbies, myHeaders]);

  const getAbousementData = useCallback(async () => {
    fetchHandler(
      `${url}metadata/lookups?LookupOptions=Abusement`,
      "GET",
      undefined,
      myHeaders
    ).then((response) => {
      setAbusement(response.abusementtype);
    });
  }, [abusement, myHeaders]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getOwn();
    getAbousementData();
    getProfileHobbies();
    getDataFromServer();
    if (reload === true) {
      getPublicData();
    }

    setEnablePreviousProfileBtn(isPreviousButtonEnabled());
    setEnableNextProfileBtn(isNextButtonEnabled());
    setReload(false);
  }, [language, changnow, reload]);

  const pheaders = new Headers();
  pheaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  pheaders.append("Content-Type", "application/json");
  pheaders.append("Accept-Language", language || "en");

  const toastcontroller = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result?.message ? result.message : result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  //TODO: Refactore
  const Swalcontroller = (result) => {
    if (result.success) {
      Swal.fire({
        title: result.message,
        //text: result.message,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#032E42",
        icon: "success",
        timer: 4000,
      });
    } else {
      toast.error(result?.message ? result.message : result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const blockProfile = useCallback(() => {
    const raw = JSON.stringify({
      profileId: myid,
      blockedProfileId: profileid,
      profilename: username,
    });
    fetchHandler(`${url}profile/block`, "POST", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setBlock(true);
        }
        Swalcontroller(result);
        // toastcontroller(result);
      }
    );
  }, [myid, profileid, myHeaders, block]);

  const likeProfile = useCallback(() => {
    const raw = JSON.stringify({
      profileId: myid,
      likeprofileid: profileid,
    });
    fetchHandler(`${url}profile/like/add`, "POST", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setLike(true);
        }
        //  toastcontroller(result);
        Swalcontroller(result);
      }
    );
  }, [myid, profileid, pheaders, like]);

  const faverouteProfile = useCallback(() => {
    const raw = JSON.stringify({
      profileId: myid,
      favoriteProfileId: profileid,
    });
    fetchHandler(`${url}profile/favorite/add`, "POST", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setFaveourite(true);
        }
        toastcontroller(result);
      }
    );
  }, [faveourite, myid, profileid, pheaders]);

  const removefaverouteProfile = useCallback(() => {
    let raw = JSON.stringify({
      profileId: myid,
      favoriteProfileId: profileid,
    });
    fetchHandler(`${url}profile/favorite/remove`, "POST", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setFaveourite(false);
        }
        toastcontroller(result);
      }
    );
  }, [faveourite, myid, profileid, pheaders]);

  const photoRequest = useCallback(
    (userId = profileid) => {
      let raw = JSON.stringify({
        profileId: userId,
      });

      fetchHandler(`${url}image/requestaccess`, "POST", raw, pheaders).then(
        (result) => {
          if (result.success) {
            setReload(true);
            const updatedMappedItems = mappedUsers.map((item) => {
              if (item.id === userId) {
                return {
                  ...item,
                  success: true,
                };
              }
              return item;
            });

            // Set the state with the updated array
            setMappedUsers(updatedMappedItems);
          }
          toastcontroller(result);
        }
      );
    },
    [profileid, pheaders, reload]
  );

  const [comment, setComment] = useState("");
  const [getabid, setGetabid] = useState(1);

  const reportAbouse = useCallback(() => {
    let raw = JSON.stringify({
      abusementTypeId: getabid,
      abuserProfileId: profileid,
      reportedByProfileId: myid,
      text: comment,
    });
    fetchHandler(`${url}abusement/add`, "POST", raw, pheaders).then(
      (result) => {
        setFlag(true);
        setModalShow(false);
        toastcontroller(result);
      }
    );
  }, [getabid, profileid, myid, comment, pheaders]);

  const [modalShow, setModalShow] = useState(false);

  const sendMessage = useCallback(
    (e) => {
      e.preventDefault();
      setSendmessage(false);

      let raw = JSON.stringify({
        body: body,
        fromProfileId: myid,
        toProfileId: userid,
        messagetypeid: 1,
      });

      fetchHandler(`${url}inbox/newmessage`, "POST", raw, pheaders).then(
        (result) => {
          toastcontroller(result);
        }
      );
    },
    [body, myid, userid, pheaders]
  );

  const [openpic, setOpenpic] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const [searchResult, setSearchResult] = useState([]);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const showFontPageData = useCallback(() => {
    fetchHandler(`${url}profile/getfrontpageprofiles`, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    showFontPageData();
    fetchHandler(`${url}profile/gettopten`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        const data = result.data.map((ele) => ({
          id: ele.profileid,
          success: ele.hasaccessalreadybeenrequested,
        }));

        setTimeout(() => setMappedUsers(data), 0);
      } else {
        setSearchResult(null);
      }
    });
  }, [changnow, language]);

  const renderOnlineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOnlineNow")}
    </Tooltip>
  );

  const renderOfflineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOffline")}
    </Tooltip>
  );

  const profileNotActiveOrNotApprovedHandler = () => {
    if (!isCurrentProfileApproved) {
      showSwal(
        t("AwaitingApproval_Header"),
        t("AwaitingApproval_Message"),
        "info"
      );
      return;
    }

    if (!isCurrentProfileAvailable) {
      showSwal(
        t("ProfileNotActive_Header"),
        t("ProfileNotActive_Message"),
        "info"
      );
      return;
    }

    if (isMarkedForDeletion) {
      showSwal(
        t("ProfileMarkedForDeletion_Header"),
        t("ProfileMarkedForDeletion_Message"),
        "info"
      );
    }
  };

  const showSwal = (title, message, type) => {
    Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
    });
  };

  const confirmShowInterestHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      html: t("Express_Interest_Confirm_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Express_Interest_Button"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        likeProfile();
      }
    });
  };

  const confirmBlocktHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      html: t("Block_Profile"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Block"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        blockProfile();
      }
    });
  };

  // Utility functions to calculate previous and next profile IDs
  const handlePreviousProfile = () => {
    const previousProfileId = getPreviousProfileId(profileid);
    if (previousOrNextProfileId === previousProfileId) {
      localStorage.setItem("enableShowPreviousProfile", "false");
      localStorage.setItem("enableShowNextProfile", "true");
      setEnablePreviousProfileBtn(false);
    } else {
      localStorage.setItem("enableShowPreviousProfile", "true");
      localStorage.setItem("enableShowNextProfile", "true");
    }
    setPreviousOrNextProfileId(previousProfileId);
    setCountryoriginCode(null);
  };

  const handleNextProfile = () => {
    const nextProfileId = getNextProfileId(profileid);
    if (previousOrNextProfileId === nextProfileId) {
      localStorage.setItem("enableShowNextProfile", "false");
      localStorage.setItem("enableShowPreviousProfile", "true");
      setEnableNextProfileBtn(false);
    } else {
      localStorage.setItem("enableShowNextProfile", "true");
      localStorage.setItem("enableShowPreviousProfile", "true");
    }
    setPreviousOrNextProfileId(nextProfileId);
    setCountryoriginCode(null);
  };

  useEffect(() => {
    if (
      previousOrNextProfileId !== null &&
      previousOrNextProfileId !== undefined
    ) {
      history(`/profile/${previousOrNextProfileId.toString()}`);
      setReload(true);
    }
  }, [previousOrNextProfileId]);

  const isNextButtonEnabled = () => {
    const storedNextValue = localStorage.getItem("enableShowNextProfile");
    return storedNextValue === null ||
      storedNextValue === "" ||
      storedNextValue === undefined
      ? true
      : JSON.parse(storedNextValue);
  };

  const isPreviousButtonEnabled = () => {
    const storedPreviousValue = localStorage.getItem(
      "enableShowPreviousProfile"
    );
    return storedPreviousValue === null ||
      storedPreviousValue === "" ||
      storedPreviousValue === undefined
      ? true
      : JSON.parse(storedPreviousValue);
  };

  return (
    <>
      {formdata && getowndata && username !== "" ? (
        <div className="container mt-20 lg:mt-16   lg:py-10 min-h-screen bg-blue-50 relative">
          <ToastContainer />
          <PeoplePhoto
            viewpic={viewpic}
            show={openpic}
            onHide={() => setOpenpic(false)}
          />
          <ProfilePhoto
            albumcontainer={albumcontainer}
            gender={gender}
            profilepicture={profilepicture}
            show={viewPhofilePhoto}
            onHide={() => setViewPhofilePhoto(false)}
            isCurrentProfileApproved={isCurrentProfileApproved ? true : false}
            isCurrentProfileAvailable={isCurrentProfileAvailable ? true : false}
            isMarkedForDeletion={isMarkedForDeletion ? true : false}
            handleButtonClick={photoRequest}
            profileNotActiveOrNotApprovedHandler={
              profileNotActiveOrNotApprovedHandler
            }
            hasaccessalreadybeenrequested={
              profileattributes?.hasaccessalreadybeenrequested ? true : false
            }
            hascurrentuseraccesstoviewcardimages={
              profileattributes?.hascurrentuseraccesstoviewcardimages
            }
            ispicturesprivate={
              profileattributes?.ispicturesprivate ? true : false
            }
            profileid={profileid}
            block={block}
          />
          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dir={lang === "ar" ? "rtl" : "ltr"}
          >
            <Modal.Header
              closeButton
              className={lang === "ar" ? "py-2 text-left w-full" : ""}
            >
              <h6 className={lang === "ar" ? "py-2 text-right w-full" : ""}>
                <span className="font-bold">{t("Report_Abusement")}</span>
              </h6>
            </Modal.Header>
            <Modal.Body>
              <h2 className={lang === "ar" ? "py-2 text-right" : "py-2"}>
                {t("Select_Abusement_Type")}
              </h2>
              <Form.Select
                className={lang === "ar" ? "text-right" : ""}
                required
                size="sm"
                onChange={(e) => setGetabid(e.target.value)}
              >
                {abusement.map((item, ind) => (
                  <option key={ind} value={item.translistid}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Group
                className="mt-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <h2 className={lang === "ar" ? "py-2 text-right" : "py-2"}>
                  {t("Your_Comment")}
                </h2>
                <Form.Control
                  as="textarea"
                  dir={lang === "ar" ? "rtl" : "ltr"}
                  placeholder={t("Enter_your_Abusment_Comment_Here")}
                  required
                  maxLength="4000"
                  onChange={(e) => setComment(e.target.value)}
                  rows={3}
                />
              </Form.Group>
            </Modal.Body>
            <p
              className={
                lang === "ar"
                  ? "text-green-500 font-medium pl-4 text-xs text-right pr-4 "
                  : "text-green-500 font-medium pl-4 text-xs"
              }
            >
              {lang === "ar"
                ? "4000 / " + comment.length
                : comment.length + " / 4000"}
            </p>
            {lang === "ar" ? (
              <div className="flex items-center justify-end gap-2 p-2">
                <button
                  className="text-white bg-pr hover:bg-sr px-2 py-2 rounded-md"
                  onClick={() => setModalShow(false)}
                >
                  {t("Cancel")}
                </button>
                <button
                  className={
                    comment !== ""
                      ? "text-white bg-sr hover:bg-pr px-2 py-2 rounded-md"
                      : "text-white bg-gray-400 px-2 py-2 rounded-md cursor-default"
                  }
                  onClick={comment !== "" ? reportAbouse : null}
                >
                  {t("Send")}
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-end gap-2 p-2">
                <button
                  className="text-white bg-pr hover:bg-sr px-2 py-2 rounded-md"
                  onClick={() => setModalShow(false)}
                >
                  {t("Cancel")}
                </button>
                <button
                  className={
                    comment !== ""
                      ? "text-white bg-sr hover:bg-pr px-2 py-2 rounded-md"
                      : "text-white bg-gray-400 px-2 py-2 rounded-md"
                  }
                  onClick={comment !== "" ? reportAbouse : null}
                >
                  {t("Send")}
                </button>
              </div>
            )}
          </Modal>
          {sendmessage &&
            (inboxid === null || inboxid === undefined || isinboxempty ? (
              <div
                className="fixed top-0 left-0 z-50"
                style={{
                  height: "100%",
                  width: "100%",
                  background: "rgba(0,0,0,0.3)",
                  zIndex: "99999",
                }}
              >
                <div
                  style={{ height: "500px" }}
                  className="lg:w-96 w-full fixed right-0 lg:right-10   bottom-2 px-2 border-2 border-pr overflow-hidden bg-white  shadow-md rounded-xl "
                >
                  <div className="flex items-center justify-end pt-2 mb-2">
                    <AiFillCloseCircle
                      onClick={() => setSendmessage(false)}
                      className="text-yellow-500 w-6 h-6 cursor-pointer"
                    />
                  </div>
                  <div className="flex items-center justify-center flex-col mb-3">
                    <div className="h-16 w-16 rounded-full overflow-hidden mb-2">
                      {albumcontainer !== null ? (
                        <>
                          {albumcontainer?.pictures?.length !== 0 ? (
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                              alt=""
                              onError={imageOnError}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          ) : (
                            <img
                              src={imageSrc}
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              alt=""
                              onError={imageOnError}
                              onContextMenu={(e) => e.preventDefault()}
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src={imageSrc}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt=""
                          onError={imageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )}
                    </div>
                    <h6
                      className={
                        lang === "ar"
                          ? "font-medium text-gray-700 flex-row-reverse"
                          : "font-medium text-gray-700 "
                      }
                    >
                      {username}
                    </h6>
                  </div>
                  <form onSubmit={(e) => sendMessage(e)}>
                    {ispaid &&
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion ? (
                      <div>
                        <textarea
                          onChange={(e) => setBody(e.target.value)}
                          required
                          autoFocus
                          maxLength="4000"
                          cols="25"
                          rows="10"
                          placeholder={t("Write_Your_Message_Here")}
                          className="outline-none border p-3 w-full rounded-md h-72"
                        ></textarea>
                      </div>
                    ) : !ispaid &&
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion ? (
                      <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                        <p className="text-red-500 flex  gap-2">
                          <AiOutlineWarning className="h-5 w-5" />
                          {t("Only_Paid_Member_Can_Send_Message")}
                        </p>
                        <Link
                          to="/payment"
                          className=" text-green-600 text-sm font-medium"
                        >
                          {t("Upgrade_Your_Subscription")}
                        </Link>
                      </div>
                    ) : (
                      <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                        <p className="text-red-500 flex  gap-2">
                          <AiOutlineWarning className="h-5 w-5" />
                          {t("Profile_Must_Be_Active_To_Send_Messages")}
                        </p>
                      </div>
                    )}

                    {ispaid &&
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion && (
                        <div className="w-full flex items-center justify-between">
                          <p className=" text-green-600 text-xs font-medium">
                            {body.length} / 4000
                          </p>
                          <button
                            type="submit"
                            className="bg-pr hover:bg-sr px-2 py-2 text-white rounded-sm"
                          >
                            {t("Send")}
                          </button>
                        </div>
                      )}
                  </form>
                </div>
              </div>
            ) : (
              <div>
                {inboxarchived
                  ? history("/chat/Archived/" + inboxid)
                  : history("/chat/Active/" + inboxid)}
              </div>
            ))}
          <div className="container">
            <ProfileNavbar />
          </div>
          {scrollPosition > 540 && (
            <div
              style={{ zIndex: "200" }}
              className="fixed top-20 left-0 h-28 w-full bg-pr shadow-md flex items-center justify-center lg:px-16"
            >
              <div className="w-20 h-20 z-50  relative rounded-full p-2 mt-3 bg-sr shadow-md">
                <div className="w-full h-full rounded-full overflow-hidden">
                  {albumcontainer !== null ? (
                    albumcontainer?.pictures?.length !== 0 ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded-full cursor-pointer"
                        onClick={() => setViewPhofilePhoto(true)}
                        src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                        alt=""
                        onError={imageOnError}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded-full cursor-pointer"
                        src={imageSrc}
                        onError={imageOnError}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=""
                      />
                    )
                  ) : (
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      className="rounded-full cursor-pointer"
                      src={imageSrc}
                      onError={imageOnError}
                      onContextMenu={(e) => e.preventDefault()}
                      alt=""
                    />
                  )}
                </div>
                <div className="text-white absolute top-0 right-3 h-6 w-6">
                  {profileattributes?.subscriptiontype === "Platinum" && (
                    <img src={platinum} alt={t("Platinum")} />
                  )}
                  {profileattributes.subscriptiontype === "Gold" && (
                    <img src={gold} alt={t("Gold")} />
                  )}
                  {profileattributes.subscriptiontype === "Basic" && (
                    <img src={basic} alt={t("Basic")} />
                  )}
                </div>
              </div>
              <div className="flex mt-3 items-center justify-center h-20">
                <h1 className="font-md text-2xl  flex items-center ">
                  {profileattributes?.online ? (
                    <div className="w-5 h-5 bg-green-500 ml-2  rounded-full "></div>
                  ) : (
                    <div className="w-5 h-5 bg-gray-400 ml-2  rounded-full "></div>
                  )}
                  <span
                    className=" text-lg lg:text-3xl font-medium text-sr ml-2 "
                    style={{ direction: "ltr" }}
                  >
                    {username}
                  </span>
                </h1>
                <div className="w-52 h-8 flex items-center gap-2 ml-4">
                  {" "}
                  <div className=" p-2 flex items-center justify-center bg-sr rounded-full border-2 border-pr">
                    {block ? (
                      like ? (
                        <BsFillHeartFill
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      ) : (
                        <BsHeart
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      )
                    ) : like ? (
                      <BsFillHeartFill
                        className="likeup text-yellow-500"
                        title={t("Already_Expressed_Interest")}
                      />
                    ) : (
                      <BsHeart
                        className=" cursor-pointer likeup text-yellow-500"
                        title={t("Express_Interest")}
                        onClick={
                          isCurrentProfileApproved &&
                          isCurrentProfileAvailable &&
                          !isMarkedForDeletion
                            ? confirmShowInterestHandler //() => setLikeOpen(true)
                            : profileNotActiveOrNotApprovedHandler
                        }
                      />
                    )}
                  </div>
                  <div className=" p-2 flex items-center bg-sr justify-center rounded-full border-2 border-pr">
                    {block ? (
                      faveourite ? (
                        <BsStarFill
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      ) : (
                        <BsStar
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      )
                    ) : faveourite ? (
                      <BsStarFill
                        className="likeup text-yellow-500"
                        title={t("Remove_From_Favorites")}
                        onClick={
                          isCurrentProfileApproved &&
                          isCurrentProfileAvailable &&
                          !isMarkedForDeletion
                            ? removefaverouteProfile
                            : profileNotActiveOrNotApprovedHandler
                        }
                      />
                    ) : (
                      <BsStar
                        title={t("Add_To_Favorites")}
                        className="cursor-pointer likeup text-yellow-500"
                        onClick={
                          isCurrentProfileApproved &&
                          isCurrentProfileAvailable &&
                          !isMarkedForDeletion
                            ? faverouteProfile
                            : profileNotActiveOrNotApprovedHandler
                        }
                      />
                    )}
                  </div>
                  <div className="p-2 flex items-center justify-center bg-sr rounded-full border-2 border-pr">
                    {block ? (
                      flag ? (
                        <BsFlagFill
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      ) : (
                        <BsFlag
                          className=" likeup text-gray-500 cursor-not-allowed"
                          title={t("Blocked")}
                        />
                      )
                    ) : flag ? (
                      <BsFlagFill
                        className=" likeup text-yellow-500"
                        title={t("Already_Reported_Abuse")}
                      />
                    ) : (
                      <BsFlag
                        title={t("Report_Abuse")}
                        onClick={
                          isCurrentProfileApproved &&
                          isCurrentProfileAvailable &&
                          !isMarkedForDeletion
                            ? () => setModalShow(true)
                            : profileNotActiveOrNotApprovedHandler
                        }
                        className=" cursor-pointer likeup text-yellow-500"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <div className="w-full">
              <div className="w-full relative h-96">
                <div className="w-full h-96 overflow-hidden">
                  {albumcontainer !== null ? (
                    <>
                      {albumcontainer?.pictures?.length !== 0 ? (
                        <>
                          {ppublic ? (
                            <>
                              {profilepicture?.approved ? (
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                                  alt=""
                                  onError={imageOnError}
                                  onContextMenu={(e) => e.preventDefault()}
                                />
                              ) : (
                                <>
                                  <img
                                    src={cover}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                    }}
                                    alt=""
                                    onError={imageOnError}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div>
                                {profilepicture?.approved ? (
                                  <img
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                    }}
                                    src={cover}
                                    onError={imageOnError}
                                    onContextMenu={(e) => e.preventDefault()}
                                  />
                                ) : (
                                  <>
                                    <img
                                      src={cover}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                      }}
                                      alt=""
                                      onError={imageOnError}
                                      onContextMenu={(e) => e.preventDefault()}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            src={cover}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            alt=""
                            onError={imageOnError}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <img
                      src={cover}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      alt=""
                      onError={imageOnError}
                    />
                  )}
                </div>
                <div
                  className=" absolute top-0 left-0 z-40 flex justify-center items-start pt-20 "
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "#032e42e5",
                  }}
                >
                  <p
                    style={{ width: "60%" }}
                    className=" text-white font-medium text-xs md:text-xl text-center"
                  >
                    {title !== "" && title}
                  </p>
                </div>

                <div
                  className=" mb-0 lg:mb-7 flex items-center flex-col md:flex-row gap-2 justify-center text-yellow-500 px-0 lg:px-3 py-2 z-40 p-0 lg:p-2 absolute  left-0 lg:left-6 bottom-5"
                  style={{ direction: "ltr" }}
                >
                  <div className="flex items-center gap-1 flex-col ">
                    <p className="bg-sr text-yellow-500 rounded-md border-2 text-xs  md:text-base border-pr font-medium px-2 lg:px-4 py-1 ">
                      {t("Age")}
                    </p>
                    <p className="text-yellow-500 md:text-2xl font-medium  text-sm  ">
                      {profileattributes.age}
                    </p>
                  </div>
                  <div className="flex items-center gap-1 flex-col ">
                    <p className="bg-sr text-yellow-500 rounded-md border-2 text-xs  md:text-base border-pr font-medium px-2  ml-1 lg:ml-0 lg:px-4 py-1  text-center">
                      {t("Seeking")}
                    </p>
                    <p className="md:text-2xl text-sm font-medium   flex gap-2 text-yellow-500">
                      {profileattributes.seekingminage}-
                      {profileattributes.seekingmaxage}
                    </p>
                  </div>
                  {countryoriginCode && (
                    <div className="flex items-center gap-0 flex-col">
                      <p className=" px-2 lg:px-4 py-1 ">
                        <Flag
                          code={countryoriginCode}
                          className="rounded h-6 md:h-8 lg:h-16"
                        />
                      </p>
                    </div>
                  )}
                </div>

                <div
                  className="mb-7 flex items-center flex-col md:flex-row justify-center gap-2 text-yellow-500 px-3 py-2 z-40  p-2 absolute  right-0 lg:right-6 bottom-5"
                  style={{ direction: "ltr" }}
                >
                  {block ? (
                    <div className=" bg-gray-50 py-1 text-sr rounded-sm px-2 flex items-center text-xs lg:text-base gap-1 cursor-not-allowed">
                      <CgUnblock className="w-6 h-6 cursor-pointer " />{" "}
                      {t("Blocked")}
                    </div>
                  ) : (
                    <div
                      className=" bg-gray-50 py-1 text-sr px-2 rounded-sm flex items-center text-xs lg:text-base gap-1 hover:bg-pr cursor-pointer"
                      onClick={
                        isCurrentProfileApproved &&
                        isCurrentProfileAvailable &&
                        !isMarkedForDeletion
                          ? () => confirmBlocktHandler()
                          : profileNotActiveOrNotApprovedHandler
                      }
                    >
                      <BiBlock className="w-5 h-5 cursor-pointer" />{" "}
                      {t("Block")}
                    </div>
                  )}
                  {!block && (
                    <>
                      <div
                        onClick={
                          isCurrentProfileApproved &&
                          isCurrentProfileAvailable &&
                          !isMarkedForDeletion
                            ? () => setSendmessage(true)
                            : profileNotActiveOrNotApprovedHandler
                        }
                        className=" bg-gray-50 py-1 text-sr px-2 rounded-sm flex items-center text-xs lg:text-base gap-1 hover:bg-pr cursor-pointer"
                      >
                        <BsMessenger className="w-5 h-5 cursor-pointer" />
                        {t("Message")}
                      </div>
                    </>
                  )}
                </div>

                <div className="sm:w-48 sm:h-48 md:w-52 md:h-52 w-32 h-32 z-50 relative rounded-full p-2 profile_container bg-pr shadow-md">
                  <div className="w-full h-full rounded-full overflow-hidden">
                    {albumcontainer !== null ? (
                      albumcontainer?.pictures?.length !== 0 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          onClick={() => setViewPhofilePhoto(true)}
                          className="rounded-full cursor-pointer"
                          src={`${process.env.REACT_APP_IMAGE}${profilepicture?.path}`}
                          alt=""
                          onError={imageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      ) : (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                          className="rounded-full cursor-pointer"
                          src={imageSrc}
                          alt=""
                          onError={imageOnError}
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      )
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="rounded-full cursor-pointer"
                        src={imageSrc}
                        onError={imageOnError}
                        onContextMenu={(e) => e.preventDefault()}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="text-white absolute top-0 right-3 h-16 w-16">
                    {profileattributes.subscriptiontype === "Platinum" && (
                      <img src={platinum} alt={t("Platinum")} />
                    )}
                    {profileattributes.subscriptiontype === "Gold" && (
                      <img src={gold} alt={t("Gold")} />
                    )}
                    {profileattributes.subscriptiontype === "Basic" && (
                      <img src={basic} alt={t("Basic")} />
                    )}
                  </div>
                  {albumcontainer?.pictures?.length !== 0 &&
                    profileattributes?.ispicturesprivate === true &&
                    profileattributes?.hascurrentuseraccesstoviewcardimages ===
                      false && (
                      <div className="text-pr absolute bottom-3 md:bottom-3 lg:bottom-3 left-1/2 transform -translate-x-1/2 rounded-full bg-sr border-0 h-7 w-7 md:h-7 md:w-7 lg:h-7 lg:w-7 flex items-center justify-center">
                        <FiLock className="w-5 h-4 " />
                      </div>
                    )}
                </div>
              </div>
              <div className="flex w-full items-center justify-center flex-col mt-24 gap-2">
                <div className="flex w-full justify-between">
                  <div className="items-left gap-1 pt-1 text-xl font-bold">
                    {language === "ar" ? (
                      <FaAngleRight
                        className={`font-extrabold ${
                          language === "ar" ? "rtl" : "ltr"
                        } ${
                          enablePreviousProfileBtn
                            ? " likeup hover:text-green-500 cursor-pointer"
                            : "text-gray-400"
                        }`}
                        title={t("PreviousProfile")}
                        onClick={
                          enablePreviousProfileBtn
                            ? handlePreviousProfile
                            : null
                        }
                      />
                    ) : (
                      <FaAngleLeft
                        className={`font-extrabold ${
                          language === "ar" ? "rtl" : "ltr"
                        } ${
                          enablePreviousProfileBtn
                            ? " likeup  hover:text-green-500 cursor-pointer"
                            : " text-gray-400"
                        }`}
                        title={t("PreviousProfile")}
                        onClick={
                          enablePreviousProfileBtn
                            ? handlePreviousProfile
                            : null
                        }
                      />
                    )}
                  </div>
                  <div className="flex items-center gap-1 pt-1 ">
                    {profileattributes?.online ? (
                      <div className="w-5 h-5 bg-green-500 ml-2  rounded-full "></div>
                    ) : (
                      <div className="w-5 h-5 bg-gray-400 ml-2  rounded-full "></div>
                    )}
                    {profileattributes?.online ? (
                      <p className="text-green-500 text-xl font-bold animate-pulse">
                        {t("Online")}
                      </p>
                    ) : (
                      <p className="text-gray-500 text-xl font-bold ">
                        {t("Offline")}
                      </p>
                    )}
                  </div>
                  <div className="flex items-right gap-1 pt-1 text-xl font-bold">
                    {language === "ar" ? (
                      <FaAngleLeft
                        className={` font-extrabold  ${
                          language === "ar" ? "rtl" : "ltr"
                        } ${
                          enableNextProfileBtn
                            ? " likeup hover:text-green-500 cursor-pointer"
                            : " text-gray-400"
                        }`}
                        title={t("NextProfile")}
                        onClick={
                          enableNextProfileBtn ? handleNextProfile : null
                        }
                      />
                    ) : (
                      <FaAngleRight
                        className={`font-extrabold  ${
                          language === "ar" ? "rtl" : "ltr"
                        } ${
                          enableNextProfileBtn
                            ? " likeup  hover:text-green-500 cursor-pointer"
                            : " text-gray-400"
                        }`}
                        title={t("NextProfile")}
                        onClick={
                          enableNextProfileBtn ? handleNextProfile : null
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-center flex-wrap w-full">
                  <h1 className="font-md text-2xl  flex items-center flex-wrap lg:pl-12">
                    <span
                      className="text-3xl font-medium text-sr ml-2"
                      style={{ direction: "ltr" }}
                    >
                      {username}
                    </span>
                  </h1>
                  <div className="w-52 h-8 flex items-center gap-2 pl-8 lg:pl-0 ml-4">
                    {" "}
                    <div className=" p-2 flex items-center justify-center bg-sr rounded-full border-2 border-pr">
                      {block ? (
                        like ? (
                          <BsFillHeartFill
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        ) : (
                          <BsHeart
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        )
                      ) : like ? (
                        <BsFillHeartFill
                          className="likeup text-yellow-500"
                          title={t("Already_Expressed_Interest")}
                        />
                      ) : block ? (
                        <BsHeart
                          className="likeup text-gray-500 cursor-not-allowed"
                          // onClick={() => setLikeOpen(true)}
                          title={t("Blocked")}
                        />
                      ) : (
                        <BsHeart
                          className=" cursor-pointer likeup text-yellow-500"
                          onClick={
                            isCurrentProfileApproved &&
                            isCurrentProfileAvailable &&
                            !isMarkedForDeletion
                              ? confirmShowInterestHandler //() => setLikeOpen(true)
                              : profileNotActiveOrNotApprovedHandler
                          }
                          title={t("Express_Interest")}
                        />
                      )}
                    </div>
                    <div className=" p-2 flex items-center bg-sr justify-center rounded-full border-2 border-pr">
                      {block ? (
                        faveourite ? (
                          <BsStarFill
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        ) : (
                          <BsStar
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        )
                      ) : faveourite ? (
                        <BsStarFill
                          className=" likeup text-yellow-500"
                          title={t("Remove_From_Favorites")}
                          onClick={
                            isCurrentProfileApproved &&
                            isCurrentProfileAvailable &&
                            !isMarkedForDeletion
                              ? removefaverouteProfile
                              : profileNotActiveOrNotApprovedHandler
                          }
                        />
                      ) : (
                        <BsStar
                          className="cursor-pointer likeup text-yellow-500"
                          onClick={
                            isCurrentProfileApproved &&
                            isCurrentProfileAvailable &&
                            !isMarkedForDeletion
                              ? faverouteProfile
                              : profileNotActiveOrNotApprovedHandler
                          }
                          title={t("Add_To_Favorites")}
                        />
                      )}
                    </div>
                    <div className="p-2 flex items-center justify-center bg-sr rounded-full border-2 border-pr">
                      {block ? (
                        flag ? (
                          <BsFlagFill
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        ) : (
                          <BsFlag
                            className=" likeup text-gray-500 cursor-not-allowed"
                            title={t("Blocked")}
                          />
                        )
                      ) : flag ? (
                        <BsFlagFill
                          className="likeup text-yellow-500"
                          title={t("Already_Reported_Abuse")}
                        />
                      ) : (
                        <BsFlag
                          onClick={
                            isCurrentProfileApproved &&
                            isCurrentProfileAvailable &&
                            !isMarkedForDeletion
                              ? () => setModalShow(true)
                              : profileNotActiveOrNotApprovedHandler
                          }
                          className="cursor-pointer likeup text-yellow-500"
                          title={t("Report_Abuse")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 mt-10 lg:grid-cols-7 lg:px-20 gap-3">
                <div className=" col-span-1 lg:col-span-3">
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <GoInfo className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_Introdution")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 flex-wrap gap-2 " ${
                          location !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        <MdLocationOn className="mr-4 text-yellow-500 " />
                        {location !== "" ? location : `${t("No_Data")}`}
                      </p>
                      <p className="flex items-center font-medium my-1 flex-wrap gap-2  text-gray-600">
                        <RiGenderlessLine className="mr-4 text-yellow-500" />
                        {gender ? `${t("Male")}` : `${t("Female")}`}
                      </p>
                      <p className="text-gray-600 flex items-center font-medium my-1 flex-wrap gap-2 ">
                        <FaIdCard className="mr-4 text-yellow-500 " /> {userid}
                      </p>
                      <p
                        className={`flex items-center font-medium my-1 flex-wrap gap-2 " ${
                          profileattributes.lastactivitydatetime !== null
                            ? "text-gray-600"
                            : "text-gray-300"
                        }`}
                      >
                        <FiActivity className="mr-4 text-yellow-500" />
                        {t("Profile_View_LastActive")}&nbsp;
                        {profileattributes.lastactivitydatetime !== null
                          ? profileattributes.lastactivitydatetime.slice(0, 10)
                          : `${t("No_Data")}`}
                      </p>
                    </div>
                  </div>
                  <>
                    {albumcontainer !== null && (
                      <>
                        {albumcontainer?.pictures?.length !== 0 && (
                          <>
                            {thumbnileimages.length !== 0 && (
                              <div className=" w-full p-2 bg-white rounded-md shadow-md">
                                <div className="py-2 flex items-center gap-2 text-xl font-bold">
                                  <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                                    <IoImageOutline className="w-5 h-5  text-white" />
                                  </div>{" "}
                                  {t("Profile_View_Photos")}
                                </div>
                                <div className="grid grid-cols-2 w-full gap-1">
                                  {thumbnileimages.map((item, ind) => (
                                    <div key={ind} className="cursor-pointer">
                                      <img
                                        onClick={() => {
                                          photomix(item);
                                          setOpenpic(true);
                                        }}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "5%",
                                          boxShadow:
                                            "0 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                        alt=""
                                        src={`${process.env.REACT_APP_IMAGE}${item.path}`}
                                        onError={(event) =>
                                          (event.target.style.display = "none")
                                        }
                                        onContextMenu={(e) =>
                                          e.preventDefault()
                                        }
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  <>
                    {albumcontainer?.pictures?.length !== 0 &&
                      profileattributes?.ispicturesprivate === true &&
                      profileattributes?.hascurrentuseraccesstoviewcardimages ===
                        false &&
                      profileattributes?.numberofapprovedimages > 1 && (
                        <div className=" w-full p-2 mt-3 bg-white rounded-md shadow-md mb-6">
                          <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                            <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                              <FiLock className="w-5 h-5  text-white" />
                            </div>
                          </div>
                          <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                            <div className="text-gray-600 flex items-center font-medium my-1 mt-2">
                              <div
                                onClick={
                                  () => {
                                    if (
                                      !profileattributes?.hasaccessalreadybeenrequested
                                    ) {
                                      if (
                                        isCurrentProfileApproved &&
                                        isCurrentProfileAvailable &&
                                        !isMarkedForDeletion
                                      ) {
                                        photoRequest(profileid);
                                      } else {
                                        profileNotActiveOrNotApprovedHandler();
                                      }
                                    }
                                  }
                                  // profileattributes?.hasaccessalreadybeenrequested ? null : isCurrentProfileApproved && isCurrentProfileAvailable ? photoRequest : profileNotActiveOrNotApprovedHandler
                                }
                                className={
                                  profileattributes?.hasaccessalreadybeenrequested
                                    ? "px-2 py-1 bg-gray-500 z-40 left-32 text-white text-sm rounded-md shadow-xl"
                                    : "px-2 py-1 hover:bg-sr cursor-pointer z-40 left-32 text-white text-sm rounded-md bg-pr shadow-xl"
                                }
                              >
                                {profileattributes?.hasaccessalreadybeenrequested
                                  ? t("Request_Already_Sent")
                                  : t("Ask_About_My_Photo")}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                  <div className=" w-full p-2 mt-3 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <SiInformatica className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_About_Me")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 mt-2 ${
                          aboutme !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        {aboutme !== "" ? aboutme : t("No_Data")}
                      </p>
                    </div>
                  </div>
                  <div className=" w-full p-2 mt-3 bg-white rounded-md shadow-md mb-6">
                    <div className=" py-2 flex items-center gap-2 text-xl font-bold">
                      <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                        <SiLooker className="w-5 h-5  text-white" />
                      </div>{" "}
                      {t("Profile_View_Looking_For")}
                    </div>
                    <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                      <p
                        className={`flex items-center font-medium my-1 mt-2 ${
                          looking !== "" ? "text-gray-600" : "text-gray-300"
                        }`}
                      >
                        {looking !== "" ? looking : t("No_Data")}
                      </p>
                    </div>
                  </div>
                  <div className="hidden sm:block">
                    {fontpageProfiles.length !== 0 && (
                      <div className=" w-full p-2 bg-sr shadow-md mt-3 rounded-md">
                        <div className=" py-2 text-white  flex items-center gap-2 text-xl font-bold">
                          <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                            <MdOutlineRecommend className="w-5 h-5  text-white" />
                          </div>{" "}
                          {t("Recommended_Profiles")}
                        </div>
                        <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                          <Carousel>
                            {fontpageProfiles.map((data, ind) => (
                              <Carousel.Item key={ind}>
                                <div className="p-5">
                                  <Card
                                    className="shadow-md"
                                    //   style={{ height: "480px" }}
                                  >
                                    {data.online ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOnlineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderOfflineTooltip}
                                      >
                                        <div
                                          className={
                                            "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                            (language === "ar"
                                              ? "left-0"
                                              : "right-0")
                                          }
                                        ></div>
                                      </OverlayTrigger>
                                    )}
                                    {data.isnew && (
                                      <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 right-left text-xs">
                                        {t("New")}
                                      </div>
                                    )}

                                    <Link
                                      onClick={() => {
                                        setUsername("");
                                        setReload(true);
                                      }}
                                      to={"/profile/" + data.profileid}
                                      style={{
                                        // heigth: "100px",
                                        //    heigth: "300px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "100%",
                                      }}
                                    >
                                      <div class="flex justify-center items-center relative">
                                        {data.url !== "" ? (
                                          <img
                                            style={
                                              data.ispicturesprivate === true &&
                                              data.hascurrentuseraccesstoviewcardimages ===
                                                false
                                                ? {
                                                    //     height: "300px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                  }
                                                : {
                                                    //     height: "300px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                  }
                                            }
                                            src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                                            alt=""
                                            onError={imageOnError}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        ) : (
                                          <img
                                            src={imageSrc}
                                            style={{
                                              height: "200px",
                                              width: "100%",
                                              objectFit: "contain",
                                            }}
                                            alt=""
                                            onError={imageOnError}
                                            onContextMenu={(e) =>
                                              e.preventDefault()
                                            }
                                          />
                                        )}
                                        <div
                                          className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                                          style={{
                                            zIndex: "10",
                                          }}
                                        >
                                          <Flag
                                            code={data?.countryoforigincode}
                                            className="h-6"
                                            title={data?.countryoforigin}
                                          />
                                        </div>
                                      </div>

                                      {data.ispicturesprivate === true &&
                                        data.hascurrentuseraccesstoviewcardimages ===
                                          false &&
                                        data?.totalimages > 0 && (
                                          <div
                                            style={{
                                              zIndex: "6000",
                                              position: "absolute",
                                              left: "0%",
                                              right: "0%",
                                              top: "85%",
                                              // transform: "translateY(-50%)",
                                              // backgroundColor: "lightblue",
                                              textAlign: "center",
                                            }}
                                            onClick={
                                              data.hasaccessalreadybeenrequested
                                                ? null
                                                : isCurrentProfileApproved &&
                                                  isCurrentProfileAvailable &&
                                                  !isMarkedForDeletion
                                                ? photoRequest
                                                : profileNotActiveOrNotApprovedHandler
                                            }
                                            className={
                                              data.hasaccessalreadybeenrequested
                                                ? " px-2 py-2 absolute bottom-15 bg-gray-500 text-white text-sm rounded-lg "
                                                : "px-2 py-2 absolute bottom-15 hover:bg-sr cursor-pointer  text-white text-sm rounded-lg bg-pr"
                                            }
                                          >
                                            {data.hasaccessalreadybeenrequested
                                              ? t("Request_Already_Sent")
                                              : t("Ask_About_My_Photo")}
                                          </div>
                                        )}
                                    </Link>

                                    <Card.Body>
                                      <div className="h-28 overflow-hidden">
                                        <h1
                                          className="text-xl pb-2 text-center font-bold text-gray-600"
                                          style={{ direction: "ltr" }}
                                        >
                                          <Link
                                            style={{ direction: "ltr" }}
                                            onClick={() => {
                                              setUsername("");
                                              setReload(true);
                                            }}
                                            to={"/profile/" + data.profileid}
                                          >
                                            {data.displayusername}
                                          </Link>
                                        </h1>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.age !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Age")} :
                                          </span>{" "}
                                          {data.age !== ""
                                            ? data.age
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.cityname !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Location")} :
                                          </span>{" "}
                                          {data.cityname !== "" &&
                                          data.cityname !== null
                                            ? data.cityname
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.country !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          {" "}
                                          <span className="font-medium">
                                            {t("CountryOfResidence")}:{" "}
                                          </span>{" "}
                                          {data.country !== "" &&
                                          data.country !== null
                                            ? data.country
                                            : `${t("No_Data")}`}
                                        </p>
                                        <p
                                          className={`text-center text-sm " ${
                                            data.seekinggender !== ""
                                              ? "text-gray-600"
                                              : "text-gray-300"
                                          }`}
                                        >
                                          <span className="font-medium">
                                            {t("Seeking")} :
                                          </span>{" "}
                                          {data.seekinggender
                                            ? t("Male")
                                            : t("Female")}{" "}
                                          {data.seekingminage}-
                                          {data.seekingmaxage}
                                        </p>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    )}

                    {searchResult?.length !== 0 &&
                      searchResult !== undefined &&
                      searchResult !== null && (
                        <div className=" w-full p-2 bg-sr shadow-md mt-3 rounded-md">
                          <div className=" py-2 flex items-center gap-2 text-white text-xl font-bold capitalize">
                            <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                              <BsTrophyFill className="w-5 h-5  text-white" />
                            </div>{" "}
                            {t("Top_Ten_Profiles")}
                          </div>
                          <div className=" px-2 flex flex-col col-span-1 lg:col-span-4">
                            <Carousel>
                              {searchResult?.map((data, ind) => (
                                <Carousel.Item key={ind}>
                                  <div className="p-5">
                                    <Card
                                      className="shadow-md"
                                      //    style={{ height: "480px" }}
                                    >
                                      {data.online ? (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOnlineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderOfflineTooltip}
                                        >
                                          <div
                                            className={
                                              "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                                              (language === "ar"
                                                ? "left-0"
                                                : "right-0")
                                            }
                                          ></div>
                                        </OverlayTrigger>
                                      )}
                                      {data.isnew && (
                                        <div className=" bg-green-500 text-white rounded p-1 absolute z-10 top-0 text-xs">
                                          {t("New")}
                                        </div>
                                      )}

                                      <Link
                                        onClick={() => {
                                          setUsername("");
                                          setReload(true);
                                        }}
                                        to={"/profile/" + data.profileid}
                                        style={{
                                          //  heigth: "300px",
                                          overflow: "hidden",
                                          position: "relative",
                                          width: "100%",
                                        }}
                                      >
                                        <div class="flex justify-center items-center relative">
                                          {data.url !== "" ? (
                                            <img
                                              style={
                                                data.ispicturesprivate ===
                                                  true &&
                                                data.hascurrentuseraccesstoviewcardimages ===
                                                  false
                                                  ? {
                                                      //     height: "300px",
                                                      width: "100%",
                                                      //     objectFit: "cover",
                                                    }
                                                  : {
                                                      //    height: "300px",
                                                      width: "100%",
                                                      //   objectFit: "cover",
                                                    }
                                              }
                                              src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                                              alt=""
                                              onError={imageOnError}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={imageSrc}
                                              style={{
                                                height: "200px",
                                                width: "100%",
                                                objectFit: "contain",
                                              }}
                                              alt=""
                                              onError={imageOnError}
                                              onContextMenu={(e) =>
                                                e.preventDefault()
                                              }
                                            />
                                          )}
                                          <div
                                            className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                                            style={{
                                              zIndex: "10",
                                            }}
                                          >
                                            <Flag
                                              code={data?.countryoforigincode}
                                              className="h-6"
                                              title={data?.countryoforigin}
                                            />
                                          </div>
                                        </div>
                                      </Link>
                                      {data.ispicturesprivate === true &&
                                        data.hascurrentuseraccesstoviewcardimages ===
                                          false &&
                                        mappedUsers.length > 0 &&
                                        data?.totalimages > 0 && (
                                          <div
                                            onClick={() => {
                                              if (
                                                !data.hasaccessalreadybeenrequested &&
                                                !mappedUsers[ind].success
                                              ) {
                                                if (
                                                  isCurrentProfileApproved &&
                                                  isCurrentProfileAvailable &&
                                                  !isMarkedForDeletion
                                                ) {
                                                  photoRequest(data.profileid);
                                                } else {
                                                  profileNotActiveOrNotApprovedHandler();
                                                }
                                              }
                                            }}
                                            style={{
                                              textAlign: "center",
                                            }}
                                            className={
                                              data.hasaccessalreadybeenrequested ||
                                              mappedUsers[ind].success
                                                ? "px-2 py-2 bg-gray-500 text-white text-sm"
                                                : "px-2 py-2 hover:bg-sr cursor-pointer text-white text-sm bg-pr"
                                            }
                                          >
                                            {data.hasaccessalreadybeenrequested ||
                                            mappedUsers[ind].success
                                              ? t("Request_Already_Sent")
                                              : t("Ask_About_My_Photo")}
                                          </div>
                                        )}

                                      <Card.Body>
                                        <div className="h-28 overflow-hidden">
                                          <h1
                                            className="text-xl pb-2 text-center font-bold text-gray-600"
                                            style={{ direction: "ltr" }}
                                          >
                                            <Link
                                              style={{ direction: "ltr" }}
                                              onClick={() => {
                                                setUsername("");
                                                setReload(true);
                                              }}
                                              to={"/profile/" + data.profileid}
                                            >
                                              {data.displayusername}
                                            </Link>
                                          </h1>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.age !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Age")} :
                                            </span>{" "}
                                            {data.age !== ""
                                              ? data.age
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.cityname !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Location")} :
                                            </span>{" "}
                                            {data.cityname !== "" &&
                                            data.cityname !== null
                                              ? data.cityname
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.country !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            {" "}
                                            <span className="font-medium">
                                              {t("CountryOfResidence")}:{" "}
                                            </span>{" "}
                                            {data.country !== "" &&
                                            data.country !== null
                                              ? data.country
                                              : `${t("No_Data")}`}
                                          </p>
                                          <p
                                            className={`text-center text-sm " ${
                                              data.seekinggender !== ""
                                                ? "text-gray-600"
                                                : "text-gray-300"
                                            }`}
                                          >
                                            <span className="font-medium">
                                              {t("Seeking")} :
                                            </span>{" "}
                                            {data.seekinggender
                                              ? t("Male")
                                              : t("Female")}{" "}
                                            {data.seekingminage}-
                                            {data.seekingmaxage}
                                          </p>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className=" w-full lg:col-span-4 h-full">
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className="w-full">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaStreetView className="w-5 h-5  text-white" />
                        </div>
                        {t("Overview")}
                      </div>
                      {bodyshapedescription !== null && (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Height")}
                              attribute={bodyshapedescription.bodyheightid}
                              mydata={formdata.bodyheight}
                              description={t("cm")}
                            />
                            <MakeRow
                              title={t("Hair_Color")}
                              attribute={bodyshapedescription.haircolorid}
                              mydata={formdata.haircolor}
                            />
                            <MakeRow
                              title={t("Hair_Type")}
                              attribute={bodyshapedescription.hairtypeid}
                              mydata={formdata.hairtype}
                            />
                          </tbody>
                        </Table>
                      )}

                      {lifestylesandattitudes !== null && (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Children_Have_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenhaveattitudeid
                              }
                              mydata={formdata.childrenhaveattitude}
                            />
                            <MakeRow
                              title={t("Children_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenattitudeid
                              }
                              mydata={formdata.childrenattitude}
                            />
                            <MakeRow
                              title={t("Smoke_Attitude")}
                              attribute={lifestylesandattitudes.smokeattitudeid}
                              mydata={formdata.smokeattitude}
                            />
                          </tbody>
                        </Table>
                      )}

                      <Table bordered>
                        <tbody>
                          <MakeRow
                            title={t("CountryOfResidence")}
                            attribute={country}
                            mydata={formdata.country}
                          />
                          <MakeRow
                            title={t("Country_Of_Origin")}
                            attribute={countryorigin}
                            mydata={formdata.country}
                          />
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className="w-full">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <BsPersonBoundingBox className="w-5 h-5  text-white" />
                        </div>
                        {t("Appearance")}
                      </div>
                      {bodyshapedescription !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Height")}
                              attribute={bodyshapedescription.bodyheightid}
                              mydata={formdata.bodyheight}
                              description={t("cm")}
                            />
                            <MakeRow
                              title={t("Body_Look")}
                              attribute={bodyshapedescription.bodylookid}
                              mydata={formdata.bodylook}
                            />
                            <MakeRow
                              title={t("Body_Type")}
                              attribute={bodyshapedescription.bodytypeid}
                              mydata={formdata.bodytype}
                            />
                            <MakeRow
                              title={t("Body_Weight")}
                              attribute={bodyshapedescription.bodyweightid}
                              mydata={formdata.bodyweight}
                              description={t("kg")}
                            />
                            <MakeRow
                              title={t("Disability")}
                              attribute={bodyshapedescription.disabilityid}
                              mydata={formdata.disabilitie}
                            />
                            <MakeRow
                              title={t("Ethnicity")}
                              attribute={bodyshapedescription.ethnicityid}
                              mydata={formdata.ethnicitie}
                            />
                            <MakeRow
                              title={t("Eye_Wear")}
                              attribute={bodyshapedescription.eyewearid}
                              mydata={formdata.eyewear}
                            />
                            <MakeRow
                              title={t("Facial_Hair")}
                              attribute={bodyshapedescription.facialhairid}
                              mydata={formdata.facialhair}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>

                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <BsPersonLinesFill className="w-5 h-5  text-white" />
                        </div>
                        {t("Personality")}
                      </div>
                      {personalitydescription !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Romance_Type")}
                              attribute={personalitydescription.romancetypeid}
                              mydata={formdata.romancetype}
                            />
                            <MakeRow
                              title={t("Characteristics")}
                              attribute={
                                personalitydescription.characteristicsid
                              }
                              mydata={formdata.characteristics}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <GiBackup className="w-5 h-5  text-white" />
                        </div>
                        {t("Background")}
                      </div>
                      {backgroundandculturalvalues !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Convert")}
                              attribute={backgroundandculturalvalues.convertid}
                              mydata={formdata.convert}
                            />
                            <MakeRow
                              title={t("Education")}
                              attribute={
                                backgroundandculturalvalues.educationid
                              }
                              mydata={formdata.education}
                            />
                            <MakeRow
                              title={t("Faith_Attitude")}
                              attribute={
                                backgroundandculturalvalues.faithattitudeid
                              }
                              mydata={formdata.faithattitude}
                            />
                            <MakeRow
                              title={t("Faith")}
                              attribute={backgroundandculturalvalues.faithid}
                              mydata={formdata.faith}
                            />
                            <MakeRow
                              title={t("Family_Values")}
                              attribute={
                                backgroundandculturalvalues.familyvaluesid
                              }
                              mydata={formdata.familyvalue}
                            />
                            <MakeRow
                              title={t("Nationality")}
                              attribute={
                                backgroundandculturalvalues.nationalityid
                              }
                              mydata={formdata.nationality}
                            />
                            <MakeRow
                              title={t("Polygamy")}
                              attribute={backgroundandculturalvalues.polygamyid}
                              mydata={formdata.polygamy}
                            />
                            <MakeRow
                              title={t("Profile_Creator")}
                              attribute={
                                backgroundandculturalvalues?.profilecreatorid
                              }
                              mydata={formdata?.profilecreator}
                            />
                            <MakeRow
                              title={t("Read_Quran")}
                              attribute={
                                backgroundandculturalvalues?.readquranid
                              }
                              mydata={formdata?.readquran}
                            />
                            <MakeRow
                              title={t("Religion_Services")}
                              attribute={
                                backgroundandculturalvalues?.religionservicesid
                              }
                              mydata={formdata?.religionservice}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className="w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaGlassCheers className="w-5 h-5  text-white" />
                        </div>
                        {t("LifeStyle")}
                      </div>
                      {lifestylesandattitudes !== null ? (
                        <Table bordered>
                          <tbody>
                            <MakeRow
                              title={t("Alcohol_Attitude")}
                              attribute={
                                lifestylesandattitudes.alcoholattitudeid
                              }
                              mydata={formdata.alcoholattitude}
                            />
                            <MakeRow
                              title={t("Animals_Attitude")}
                              attribute={
                                lifestylesandattitudes.animalsattitudeid
                              }
                              mydata={formdata.animalsattitude}
                            />
                            <MakeRow
                              title={t("Annual_Income")}
                              attribute={lifestylesandattitudes.annualincomeid}
                              mydata={formdata.annualincome}
                            />
                            <MakeRow
                              title={t("Children_Have_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenhaveattitudeid
                              }
                              mydata={formdata.childrenhaveattitude}
                            />
                            {lifestylesandattitudes.childrenhaveattitudeid !==
                              1 && (
                              <>
                                <MakeRow
                                  title={t("Children_Number_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrennumberattitudeid
                                  }
                                  mydata={formdata.childrennumberattitude}
                                />
                                <MakeRow
                                  title={t("Children_Oldest_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrenoldestattitudeid
                                  }
                                  mydata={formdata.childrenoldestattitude}
                                />
                                <MakeRow
                                  title={t("Children_Youngest_Attitude")}
                                  attribute={
                                    lifestylesandattitudes.childrenyoungesttattitudeidid
                                  }
                                  mydata={formdata.childrenyoungesttattitude}
                                />
                              </>
                            )}
                            <MakeRow
                              title={t("Children_Attitude")}
                              attribute={
                                lifestylesandattitudes.childrenattitudeid
                              }
                              mydata={formdata.childrenattitude}
                            />
                            <MakeRow
                              title={t("Clothing_Attitude")}
                              attribute={
                                lifestylesandattitudes.clothingattitudeid
                              }
                              mydata={formdata.clothingattitude}
                            />
                            <MakeRow
                              title={t("Employment_Attitude")}
                              attribute={
                                lifestylesandattitudes.employmentattitudeid
                              }
                              mydata={formdata.employmentattitude}
                            />
                            <MakeRow
                              title={t("Food_Attitude")}
                              attribute={lifestylesandattitudes.foodattitudeid}
                              mydata={formdata.foodattitude}
                            />
                            <MakeRow
                              title={t("Gambling_Attitude")}
                              attribute={
                                lifestylesandattitudes.gamblingattitudeid
                              }
                              mydata={formdata.gamblingattitude}
                            />
                            <MakeRow
                              title={t("Have_Any_Car")}
                              attribute={lifestylesandattitudes.haveacar}
                              mydata={formdata.haveacar}
                            />
                            <MakeRow
                              title={t("Have_A_Motorcycle")}
                              attribute={lifestylesandattitudes.haveamotorcycle}
                              mydata={formdata.haveamotorcycle}
                            />
                            <MakeRow
                              title={t("Home_Type")}
                              attribute={lifestylesandattitudes.hometypeid}
                              mydata={formdata.hometype}
                            />
                            <MakeRow
                              title={t("Living_Situation")}
                              attribute={
                                lifestylesandattitudes.livingsituationid
                              }
                              mydata={formdata.livingsituation}
                            />
                            <MakeRow
                              title={t("Marital_Status_Attitude")}
                              attribute={
                                lifestylesandattitudes.maritalstatusattitudeid
                              }
                              mydata={formdata.maritalstatusattitude}
                            />
                            <MakeRow
                              title={t("Relocate")}
                              attribute={lifestylesandattitudes.relocateid}
                              mydata={formdata.relocate}
                            />
                            <MakeRow
                              title={t("Residence")}
                              attribute={lifestylesandattitudes.residenceid}
                              mydata={formdata.residence}
                            />
                            <MakeRow
                              title={t("Residency_Status")}
                              attribute={
                                lifestylesandattitudes.residencystatusid
                              }
                              mydata={formdata.residencystatus}
                            />

                            <MakeRow
                              title={t("Travling_Attitude")}
                              attribute={
                                traveling.length !== 0
                                  ? traveling[0].travlingattitudeid
                                  : null
                              }
                              mydata={formdata.travlingattitude}
                            />
                          </tbody>
                        </Table>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <FaAmericanSignLanguageInterpreting className="w-5 h-5  text-white" />
                        </div>
                        {t("Language")}
                      </div>
                      {spokenlanguages.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {spokenlanguages.map((item, index) => (
                            <MakeEmpRow
                              key={index}
                              gdata={item}
                              mydata={formdata.language}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <GiHobbitDwelling className="w-5 h-5  text-white" />
                        </div>
                        {t("Hobbies")}
                      </div>
                      {profilehobbies?.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {profilehobbies.map((item, index) => (
                            <MakeEmpRow2
                              key={index}
                              gdata={item}
                              mydata={hobbies?.hobbies}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                  <div className=" w-full p-2 bg-white rounded-md shadow-md mb-6 flex items-center justify-between">
                    <div className=" w-full ">
                      <div className=" py-2 flex items-center gap-2 text-xl font-bold text-sr">
                        <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                          <MdSportsKabaddi className="w-5 h-5  text-white" />
                        </div>
                        {t("Sport_Attitude")}
                      </div>
                      {sports?.length !== 0 ? (
                        <div className=" flex items-center flex-wrap justify-center w-full gap-1">
                          {sports?.map((item, index) => (
                            <MakeSportRow
                              key={index}
                              gdata={item}
                              mydata={formdata.sportattitude}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-300">{t("No_Data")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
    </>
  );
};
export default PublicProfile;
