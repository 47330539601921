import React, { useEffect, useMemo, useContext } from "react";
import { MainContent } from "./../App";
import heart from "../images/heart.gif";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const Loader = ({ red }) => {
  const { isAuthenticated } = useContext(MainContent);
  const history = useNavigate();
  const location = useLocation();
  // Memoize the pathname to prevent unnecessary re-renders
  const memoizedPathname = useMemo(
    () => location.pathname,
    [location.pathname]
  );

  useEffect(() => {
    try {
      let isAuthenticationStillValid = Cookies.get("isAuthenticated");
      if (
        red &&
        isAuthenticationStillValid != null &&
        (isAuthenticationStillValid === "false" ||
          isAuthenticationStillValid === false)
      ) {
        Cookies.set("location", memoizedPathname);
        history("/");
      } else {
        history(memoizedPathname);
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
      history("/");
      // Handle the error, e.g., set a default value for 'storedIsAuthenticated'
    }
  }, [red, memoizedPathname, history, isAuthenticated]);

  return (
    <div className=" min-h-screen flex items-start justify-center">
      <img src={heart} className="h-96  rounded-full" alt="" />
    </div>
  );
};

export default Loader;
