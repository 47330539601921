import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { MainContent } from "../../App";
import { useGetSubscriptionData } from "../../hooks/CustomHooks";

function SubscriptionType() {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);

  const { data } = useGetSubscriptionData(language);
  const {
    subscriptionfeature_basic: basic,
    subscriptionfeature_gold: gold,
    subscriptionfeature_platinum: platinum,
  } = data || {};

  return (
    <div>
      <h1 className="text-xl font-medium text-sr">{t("Subscription_Type")}</h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-3">
        <div className="bg-white shadow-md rounded-md py-2 px-4">
          <h6 className="text-4xl font-bold w-full text-center py-5 text-sr">
            {t("Basic")}
          </h6>
          <div className="mt-2 grid grid-cols-1 gap-4 pb-5">
            {basic?.map((item, ind) => (
              <div key={ind} className="flex items-center w-full gap-2">
                <div className="w-7">
                  {item.enabled ? (
                    <AiFillCheckCircle className="text-green-500 w-7 h-7" />
                  ) : (
                    <AiFillCloseCircle className="text-red-700 w-7 h-7" />
                  )}
                </div>
                <p className="text-sr text-lg font-medium">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md py-2 px-4">
          <h6 className="text-4xl font-bold w-full text-center py-5 text-sr">
            {" "}
            {t("Gold")}
          </h6>
          <div className="mt-2 grid grid-cols-1 gap-4 pb-5">
            {gold?.map((item, ind) => (
              <div key={ind} className="flex items-center gap-2">
                <div className=" w-7">
                  {item.enabled ? (
                    <AiFillCheckCircle className="text-green-500 w-7 h-7" />
                  ) : (
                    <AiFillCloseCircle className="text-red-700 w-7 h-7" />
                  )}
                </div>
                <p className="text-sr text-lg font-medium">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white shadow-md rounded-md py-2 px-4">
          <h6 className="text-4xl font-bold w-full text-center py-5 text-sr">
            {" "}
            {t("Platinum")}
          </h6>
          <div className="mt-2 grid grid-cols-1 gap-4 pb-5">
            {platinum?.map((item, ind) => (
              <div key={ind} className="flex items-center gap-2">
                <div className=" w-7">
                  {item.enabled ? (
                    <AiFillCheckCircle className="text-green-500 w-7 h-7" />
                  ) : (
                    <AiFillCloseCircle className="text-red-700 w-7 h-7" />
                  )}
                </div>
                <p className="text-sr text-lg font-medium">{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionType;
