import Cookies from "js-cookie";
import React, { Fragment, useState, useContext, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiSettings, FiMail } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { errorHandler } from "../api/Api";
import { MainContent } from "../App";
import muslimf from "../images/avatar_female.jpg";
import noimage from "../images/avatar_male.jpg";
import { FaUserCircle, FaUserEdit, FaUserFriends } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import Badge from "@mui/material/Badge";
import {
  MdAutoDelete,
  MdPhotoCamera,
  MdEmail,
  MdOutlinePermContactCalendar,
  MdNotifications,
  MdGraphicEq,
} from "react-icons/md";
import { SiOpenaccess } from "react-icons/si";
import { HiOutlineSearch } from "react-icons/hi";
import { RiLockPasswordFill, RiDashboardLine } from "react-icons/ri";
import { BiSitemap } from "react-icons/bi";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import endpoints, { HANDLE_API_REQUEST } from "../api/EndPoints";
import { signalrWebsocket } from "../utils/socketUtils";

const ProfileNavbar = ({ changed, setChanged }) => {
  const { t } = useTranslation();
  const {
    changenow,
    setBasicshow,
    setAdvancesho,
    myown_data,
    language,
    updateMailbadge,
    setUpdateMailbadge,
    newMessage,
    setNewMessage,
    isAuthenticated,
    isMailNotificationInitialized,
    setIsMailNotificationInitialized,
  } = useContext(MainContent);
  const [profilepic, setProfilepic] = useState([]);
  const [displayname, setDisplayname] = useState("");

  const imageOnError = (event) => {
    event.currentTarget.src = myown_data?.data?.profileattributes?.gender
      ? noimage
      : muslimf;
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  useEffect(() => {
    const getData = () => {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
      myHeaders.append("Accept-Language", language || "en");

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      HANDLE_API_REQUEST.GET(endpoints.NEW_MESSAGE, null, requestOptions)
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((result) => {
          setNewMessage(result);
        });
    };
    getData();
    const getOwnData = () => {
      if (setChanged) {
        setChanged(false);
      }
      if (myown_data?.data?.albumcontainer !== null) {
        if (myown_data?.data?.albumcontainer?.pictures?.length !== 0) {
          const profilepic = myown_data?.data?.albumcontainer?.pictures?.filter(
            (item) =>
              item.ismicroimage === true &&
              item.isprimary === true &&
              item.isthumbnail === false
          );
          setProfilepic(profilepic);
        } else {
          setProfilepic([]);
        }
      }
      setDisplayname(myown_data?.data?.profileattributes?.displayusername);
    };
    getOwnData();

    if (updateMailbadge) {
      setUpdateMailbadge(false);
    }
  }, [changenow, changed, updateMailbadge]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Profile_Settings")}
    </Tooltip>
  );
  const renderTooltip2 = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Members_Settings")}
    </Tooltip>
  );
  const dash = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Dashboard")}
    </Tooltip>
  );
  const match = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Matches")}
    </Tooltip>
  );
  const acti = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Activity")}
    </Tooltip>
  );
  const sear = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Search")}
    </Tooltip>
  );
  const message = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("Messages")}
    </Tooltip>
  );

  const globalNewMailReceived = () => {
    if (signalrWebsocket && !isMailNotificationInitialized) {
      if (signalrWebsocket?.state === "Connected") {
        signalrWebsocket.on("ReceiveMailNotification", async (message) => {
          setUpdateMailbadge(true);
        });
        setIsMailNotificationInitialized(true);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      globalNewMailReceived();
    }

    // return () => {
    //   if (signalrWebsocket) {
    //     if (signalrWebsocket?.state === "Connected") {
    //       signalrWebsocket.off("ReceiveMailNotification");
    //       console.log("ReceiveMailNotification on");
    //       alert("ReceiveMailNotification off");
    //     } else {
    //       console.log("ReceiveMailNotification not connected");
    //       alert("ReceiveMailNotification not connected");
    //     }
    //   }
    // };
  }, [isAuthenticated]);

  return (
    <div className=" border-b w-full pb-2 shadow-md bg-white rounded-md lg:py-2">
      <nav
        className={`hidden lg:grid grid-cols-4 gap-2 mt-32 lg:mt-0 lg:gap-0 items-center md:grid-cols-4 lg:grid-cols-7 ${
          language === "ar" ? "mr-2" : ""
        }`}
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={message}
        >
          <NavLink
            to="/chat/active"
            className="w-7 h-7 ml-3 text-sr hover:text-pr cursor-pointer relative"
          >
            <Badge
              color="secondary"
              badgeContent={newMessage > 99 ? "99+" : newMessage}
            >
              <FiMail className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Badge>{" "}
          </NavLink>
        </OverlayTrigger>
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={sear}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <HiOutlineSearch className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ right: "0px" }}
              className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Menu.Item style={{ width: "100%" }}>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/basic"
                  onClick={() => {
                    setBasicshow(true);
                    localStorage.removeItem("basic");
                  }}
                >
                  {t("Search_Basic")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/advance"
                  onClick={() => {
                    setAdvancesho(true);
                    localStorage.removeItem("advance");
                  }}
                >
                  {t("Search_Advanced")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/profilename"
                  onClick={() => {
                    localStorage.removeItem("name");
                    localStorage.removeItem("stext");
                  }}
                >
                  {t("Search_By_Profile_Name")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/memberid"
                >
                  {t("Search_By_Profile_ID")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/top10"
                >
                  {t("Top_Ten_Profiles")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/latest"
                >
                  {t("Latest_Profiles")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/online"
                >
                  {t("Online_Users")}
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>

        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={acti}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <MdGraphicEq className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ right: "0px" }}
              className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <>
                <Menu.Item style={{ width: "100%" }}>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/likedme"
                  >
                    {t("Activity_Liked_Me")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/favoriteme"
                  >
                    {t("Activity_I_Am_Their_Favorite")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myviewers"
                  >
                    {t("Activity_Viewed_My_Profile")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/mylikes"
                  >
                    {t("Activity_My_Likes")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myfavorite"
                  >
                    {t("Activity_My_Favorites")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myviewed"
                  >
                    {t("Activity_Profile_I_Viewed")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/blocklist"
                  >
                    {t("Activity_Blocked_Profiles_List")}
                  </NavLink>
                </Menu.Item>
              </>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={match}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <FaUserFriends className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ right: "0px" }}
              className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Menu.Item style={{ width: "100%" }}>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/mymatches"
                >
                  {t("My_Matches")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/filter"
                >
                  {t("Filter")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/matches-setting"
                >
                  {t("Matches_Settings")}
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={dash}
        >
          <Link
            to="/dashboard"
            className="w-7 h-7 ml-3 text-sr hover:text-pr cursor-pointer"
          >
            {" "}
            <RiDashboardLine className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
          </Link>
        </OverlayTrigger>
        <Link
          to="/profile-settings/view"
          className=" py-2  px-3 text-center text-sr font-medium"
          style={{ direction: "ltr" }}
        >
          {displayname}
        </Link>
        <div className="flex items-center  lg:flex-row justify-center gap-3">
          <Menu as="div" className="ml-3 relative">
            <div>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Menu.Button className="flex text-sm rounded-full ">
                  {profilepic && profilepic?.length !== 0 ? (
                    <img
                      className="h-12 w-12 rounded-full cursor-pointer border-1 bg-gray-500"
                      src={`${process.env.REACT_APP_IMAGE}${profilepic[0]?.path}`}
                      style={{ objectFit: "cover" }}
                      alt=""
                      onError={imageOnError}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  ) : (
                    <img
                      className="h-12 w-12 rounded-full cursor-pointer border-1 bg-gray-500"
                      src={`${process.env.REACT_APP_IMAGE}${myown_data?.data?.profileattributes?.avatarimageurl}`}
                      style={{ objectFit: "cover" }}
                      alt=""
                      onError={imageOnError}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  )}
                </Menu.Button>
              </OverlayTrigger>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                style={{ right: "0px" }}
                className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile-settings/view"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                      )}
                    >
                      <FaUserCircle /> {t("Profile_Settings_View_Profile")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile-settings/edit"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <FaUserEdit />
                      {t("Profile_Settings_Edit_Profile")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile-settings/delete"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <MdAutoDelete />
                      {t("Profile_Settings_Delete_Profile")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile-settings/photos"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <MdPhotoCamera />
                      {t("Profile_Photos")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/profile-settings/image-access"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <SiOpenaccess />
                      {t("Profile_Settings_Images_Access")}
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="ml-0 relative">
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip2}
              >
                <Menu.Button className="flex text-sm rounded-full">
                  <div className="text-3xl cursor-pointer text-sr">
                    <FiSettings />
                  </div>
                </Menu.Button>
              </OverlayTrigger>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                style={
                  language === "ar" ? { left: "-30px" } : { right: "-30px" }
                }
                className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/memberssettings/email"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                      )}
                    >
                      <MdEmail /> {t("Member_Settings_Change_Email_Address")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/memberssettings/password"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <RiLockPasswordFill />
                      {t("Member_Settings_Change_Password")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/memberssettings/notification"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <MdNotifications />
                      {t("Notifications_Settings_Short")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/memberssettings/site-settings"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <BiSitemap />
                      {t("Site_Settings")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      onClick={() => {
                        localStorage.removeItem("cid");
                        localStorage.removeItem("contact");
                      }}
                      to="/memberssettings/contact"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <MdOutlinePermContactCalendar />
                      {t("Contact")}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/payment"
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                      )}
                    >
                      <GiArmorUpgrade />
                      {t("Membership")}
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </nav>
      <nav
        className={`grid lg:hidden grid-cols-4 gap-y-2 mt-24 pt-6 lg:mt-0 lg:gap-0 items-center md:grid-cols-4 lg:grid-cols-7 ${
          language === "ar" ? "mr-2" : ""
        }`}
      >
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={message}
          >
            <NavLink to="/chat/active">
              <Badge
                color="secondary"
                badgeContent={newMessage > 99 ? "99+" : newMessage}
              >
                <FiMail className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
              </Badge>
            </NavLink>
          </OverlayTrigger>
        </Menu>
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={sear}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <HiOutlineSearch className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item style={{ width: "100%" }}>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/basic"
                  onClick={() => {
                    setBasicshow(true);
                    localStorage.removeItem("basic");
                  }}
                >
                  {t("Search_Basic")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/advance"
                  onClick={() => {
                    setAdvancesho(true);
                    localStorage.removeItem("advance");
                  }}
                >
                  {t("Search_Advanced")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/profilename"
                  onClick={() => {
                    localStorage.removeItem("name");
                    localStorage.removeItem("stext");
                  }}
                >
                  {t("Search_By_Profile_Name")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/memberid"
                >
                  {t("Search_By_Profile_ID")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/top10"
                >
                  {t("Top_Ten_Profiles")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/latest"
                >
                  {t("Latest_Profiles")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/search/online"
                >
                  {t("Online_Users")}
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={acti}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <MdGraphicEq className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ right: "0" }}
              className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <>
                <Menu.Item style={{ width: "100%" }}>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/likedme"
                  >
                    {t("Activity_Liked_Me")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/favoriteme"
                  >
                    {t("Activity_I_Am_Their_Favorite")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myviewers"
                  >
                    {t("Activity_Viewed_My_Profile")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/mylikes"
                  >
                    {t("Activity_My_Likes")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myfavorite"
                  >
                    {t("Activity_My_Favorites")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/myviewed"
                  >
                    {t("Profiles_I_Viewed")}
                  </NavLink>
                </Menu.Item>
                <Menu.Item>
                  <NavLink
                    end
                    className={(navData) =>
                      navData.isActive
                        ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                        : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                    }
                    style={{ width: "100%" }}
                    to="/activity/blocklist"
                  >
                    {t("Activity_Blocked_Profiles_List")}
                  </NavLink>
                </Menu.Item>
              </>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as="div" className="ml-3 relative">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={match}
          >
            <Menu.Button className="flex text-sm rounded-full">
              <FaUserFriends className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
            </Menu.Button>
          </OverlayTrigger>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ right: "0" }}
              className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <Menu.Item style={{ width: "100%" }}>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/mymatches"
                >
                  {t("My_Matches")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/matches-setting"
                >
                  {t("Matches_Settings")}
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink
                  end
                  className={(navData) =>
                    navData.isActive
                      ? "px-4 py-2 text-sm  flex items-center gap-2 text-center w-full bg-sr text-white"
                      : "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full hover:bg-gray-100"
                  }
                  style={{ width: "100%" }}
                  to="/matches/filter"
                >
                  {t("Filter")}
                </NavLink>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <Link
          to="/profile-settings/view"
          className="text-sm cursor-pointer text-center text-sr font-medium"
        >
          {displayname}
        </Link>
        <Menu as="div" className=" ml-2 relative">
          <div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Menu.Button className="flex text-sm rounded-full">
                {profilepic && profilepic.length !== 0 ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE}${profilepic[0]?.path}`}
                    className="h-12 w-12 rounded-full cursor-pointer border-1"
                    style={{ objectFit: "cover" }}
                    alt=""
                    // onContextMenu={(e) => e.preventDefault()}
                    // onError={(event) => (event.target.style.display = "none")}
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : (
                  <img
                    src={noimage}
                    className="h-12 w-12 rounded-full cursor-pointer border-2 border-sr"
                    style={{ objectFit: "cover" }}
                    alt=""
                    // onContextMenu={(e) => e.preventDefault()}
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}{" "}
              </Menu.Button>
            </OverlayTrigger>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile-settings/view"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                    )}
                  >
                    <FaUserCircle /> {t("Profile_Settings_View_Profile")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile-settings/edit"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <FaUserEdit />
                    {t("Profile_Settings_Edit_Profile")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile-settings/delete"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <MdAutoDelete />
                    {t("Profile_Settings_Delete_Profile")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile-settings/photos"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <MdPhotoCamera />
                    {t("Profile_Photos")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile-settings/image-access"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <SiOpenaccess />
                    {t("Profile_Settings_Images_Access")}
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as="div" className="ml-3 relative">
          <div>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip2}
            >
              <Menu.Button className="flex text-sm rounded-full">
                <div className="text-3xl cursor-pointer text-sr">
                  <FiSettings />
                </div>
              </Menu.Button>
            </OverlayTrigger>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute z-50 mt-2 w-60 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/memberssettings/email"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full "
                    )}
                  >
                    <MdEmail /> {t("Member_Settings_Change_Email_Address")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/memberssettings/password"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <RiLockPasswordFill />
                    {t("Password")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/memberssettings/notification"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <MdNotifications />
                    {t("Notifications_Settings_Short")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/memberssettings/site-settings"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      " px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <BiSitemap />
                    {t("Site_Settings")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/memberssettings/contact"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <MdOutlinePermContactCalendar />
                    {t("Contact")}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/payment"
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "px-4 py-2 text-sm text-gray-700 flex items-center gap-2 text-center w-full"
                    )}
                  >
                    <GiArmorUpgrade />
                    {t("Membership")}
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={dash}
        >
          <Link
            to="/dashboard"
            className="w-7 h-7 ml-3 text-sr hover:text-pr cursor-pointer"
          >
            {" "}
            <RiDashboardLine className="w-7 h-7 text-sr hover:text-pr cursor-pointer" />
          </Link>
        </OverlayTrigger>
      </nav>
    </div>
  );
};

export default ProfileNavbar;
