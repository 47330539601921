import Switch from "@mui/material/Switch";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Carousel, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  AiFillCheckCircle,
  AiOutlineArrowDown,
  AiOutlineDelete,
  AiOutlineDoubleRight,
  AiOutlineFileSearch,
  AiOutlineLike,
  AiOutlineUsergroupAdd,
  AiOutlineDoubleLeft,
} from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import { BiMailSend, BiSearch } from "react-icons/bi";
import { DiMagento } from "react-icons/di";
import { FaFilter, FaUserEdit } from "react-icons/fa";
import { GiArmorUpgrade } from "react-icons/gi";
import { HiOutlinePhotograph } from "react-icons/hi";
import { ImWarning } from "react-icons/im";
import { FiMail } from "react-icons/fi";
import Badge from "@mui/material/Badge";
import {
  MdApproval,
  MdContactSupport,
  MdOutlineAdminPanelSettings,
  MdOutlineNotificationsNone,
} from "react-icons/md";
import { RiUserSettingsLine, RiHeartsFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { errorHandler, url } from "../../api/Api";
import fetchHandler from "../../api/fetchHandler";
import { MainContent } from "../../App";
import heart from "../../images/heart.gif";
import ProfileNavbar from "../../layout/ProfileNavbar";
import Contact from "../userSettings/membersettings/Contact";
import "./dasboard.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../utils/constants";
import FixedHeader from "../../layout/FixedHeader";
import DynamicButton from "../DynamicButton";
import CompleteProfileReminder from "../CompleteProfileReminder";

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const {
    changnow,
    language,
    setShow,
    setBasicshow,
    setFreshReload,
    myown_data,
    currentGender,
  } = useContext(MainContent);
  const [profiledata, setProfiledata] = useState(myown_data?.data?.profileattributes || null);
  const [isProfileContentComplete, setIsProfileContentComplete] =
    useState(false);
  const [profileIsActivated, setProfileIsActivated] = useState();
  const [annonims, setAnnonims] = useState();
  const [markd, setMarkd] = useState(false);
  const [markedfordeletiondaysleft, setMarkedForDeletionDaysLeft] =
    useState(null);
  const [expriredate, setExpriredate] = useState(null);
  const [subscriptiondaysleft, SetSubscriptionDaysLeft] = useState(null);
  const [statisticspage, setStatisticspage] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [reload, setReload] = useState(false);
  const [platinum, setPlatinum] = useState(false);
  const [totalMaleOnline, setTotalMaleOnline] = useState(null);
  const [totalFemaleOnline, setTotalFemaleOnline] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getStatisticsPage = () => {
    const myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=24&LangCode=${language}`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      setStatisticspage(result.data);
    });
  };
  const getStatistics = () => {
    fetchHandler(`${url}Statistics`, "GET", undefined).then((result) => {
      setStatistics(result.data);
    });
  };
  const [requestCount, setRequestCount] = useState([]);

  const getWaitingAPproval = () => {
    fetchHandler(`${url}profile/getwaitingdaccess`, "GET", undefined).then(
      (result) => {
        if (result.data.length !== 0) {
          setRequestCount(result.data);
        } else {
          setRequestCount([]);
        }
      }
    );
  };
  const [systemAlert, setSystemAlert] = useState([]);

  const getAlert = () => {
    fetchHandler(`${url}systemalert/site/activealerts`, "GET", undefined).then(
      (result) => {
        setSystemAlert(result.data);
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAlert();
    getWaitingAPproval();
    setReload(false);
    getStatistics();
    getStatisticsPage();

    fetchHandler(`${url}profile/totalonline`, "GET").then((result) => {
      setTotalMaleOnline(result.male);
      setTotalFemaleOnline(result.female);
    });
    const getOwnData = () => {
      if (myown_data?.data?.profileattributes) {
        setProfiledata(myown_data.data.profileattributes);
        setProfileIsActivated(myown_data.data.profileattributes.available);
        setAnnonims(myown_data.data.profileattributes.anonymous);
        setPlatinum(
          myown_data.data.profileattributes.subscriptiontype === "Platinum"
            ? true
            : false
        );
        setMarkd(myown_data.data.profileattributes.markedfordeletion);
        setMarkedForDeletionDaysLeft(
          myown_data?.data?.profileattributes?.daysleftbeforedeletion || 0
        );
        setExpriredate(
          myown_data.data.profileattributes.subscriptionexpiredate
        );
        SetSubscriptionDaysLeft(
          myown_data.data.profileattributes.subscriptiondaysleft
        );
      }

      if (
        myown_data?.data?.profilecontent !== null ||
        myown_data?.data?.profilecontentdraft !== null
      ) {
        setIsProfileContentComplete(true);
      }
    };

    getOwnData();
  }, [changnow, language, reload]);

  const [support, setSupport] = useState(false);

  const handleConfirmation = (e) => {
    let isChecked = e.target.checked;

    Swal.fire({
      title: t("Confirmation"),
      text: isChecked
        ? t("Account_Activated_Message")
        : t("Account_Deactivated_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: isChecked ? t("Activate") : t("Deactivate"),
      confirmButtonColor: "#032E42",
      cancelButtonText: t("Cancel"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        activate_deactivate(isChecked);
        // setProfileIsActivated(isChecked);
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire({
      //     title: t("Cancelled"),
      //     text: "",
      //     confirmButtonText: t("Ok"),
      //     confirmButtonColor: "#032E42",
      //     icon: "error",
      //   });
      // }
    });
  };
  const toastHandle = (result, message) => {
    if (result.success) {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const ShowSwalMessage = (isActive) => {
    Swal.fire({
      title: isActive ? t("Account_Activated") : t("Account_Deactivated"),
      text: "",
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: "success",
    });
  };

  const activate_deactivate = (isActive) => {
    if (isActive) {
      fetchHandler(`${url}profile/activate`, "PATCH").then((result) => {
        // toastHandle(result, t("Account_Activated"));
        setProfileIsActivated(isActive);
        ShowSwalMessage(isActive);
      });
    } else {
      fetchHandler(`${url}profile/diactivate`, "PATCH").then((result) => {
        // toastHandle(result, t("Account_Deactivated"));
        setProfileIsActivated(isActive);
        ShowSwalMessage(isActive);
      });
    }
    setFreshReload(true);
    setReload(true);
  };

  const activate_deactivateAnnonims = () => {
    fetchHandler(`${url}profile/enabledisableprofileanonymous`, "PATCH").then(
      (result) => {
        toastHandle(result, result.message);
      }
    );
  };

  const redeem = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}Payment/redeemcashpoints`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setReload(true);
          toast.success(result.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        }
      });
  };

  const upgradeMemeberShipHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      text: t("Platinum_Membership_Required"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Yes_Upgrade"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        history("/payment");
      }
    });
  };

  const handleProfileEdit = () => {
    history("/profile-settings/edit");
  };

  return (
    <>
      {profiledata !== null ? (
        <>
          {support && (
            <div
              className="fixed flex top-0 left-0 z-50"
              style={{
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0,0.3)",
              }}
            >
              <div
                style={{ maxHeight: "80vh", minHeight: "580px" }}
                className="flex space-x-5 py-3  h-full fixed bottom-2 px-3 bg-gray-800 shadow-md  right-0 lg:right-10 "
              >
                <Contact setSupport={setSupport} />
              </div>
            </div>
          )}
          {/* <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          /> */}
          {/* <MyVerticallyCenteredModal2
            show={modalShow2}
            onHide={() => setModalShow2(false)}
          /> */}
          <ToastContainer />
          <div className="bg-blue-50 mt-16 lg:pt-10 min-h-screen">
            <div className="container">
              <div className="mb-2 px-2">
                <ProfileNavbar />
              </div>
              <FixedHeader
                scrollPosition={scrollPosition}
                headerText={t("Profile_Dashboard")}
                language={language}
              />
              <div>
                {/* <div className="w-full flex items-center justify-center gap-2">
                  <h1 className="uppercase text-3xl lg:text-5xl mt-3 text-sr text-center ">
                    {t("Profile_Dashboard")}
                  </h1>
                  <div>
                    {profiledata.approved ? (
                      <AiFillCheckCircle className="text-green-600 w-8 h-8" />
                    ) : (
                      <AiFillCheckCircle className="text-gray-400 w-6 h-6" />
                    )}
                  </div>
                </div>
                <div className="ml-auto bg-gray-100">
                  <DynamicButton />
                </div> */}
                <div className="w-full flex flex-col lg:flex-row items-center gap-2">
                  <div className="flex items-center justify-center flex-grow">
                    <h1 className="uppercase text-3xl lg:text-5xl mt-3 text-sr text-center lg:text-left">
                      {t("Profile_Dashboard")}
                    </h1>
                    <div className="ml-2">
                      {profiledata.approved ? (
                        <AiFillCheckCircle className="text-green-600 w-8 h-8" />
                      ) : (
                        <AiFillCheckCircle className="text-gray-400 w-6 h-6" />
                      )}
                    </div>
                  </div>
                  {(isProfileContentComplete === false || profiledata.profilepercentcomplete < 60) && (
                      <div className="flex justify-center w-full lg:w-auto lg:ml-auto mt-2 lg:mt-0">
                        <DynamicButton />
                      </div>
                    )}
                </div>
                <div>
                  {markd && markedfordeletiondaysleft > 0 && (
                    <h1 className="px-3 py-2 bg-sr font-medium flex items-center gap-2 text-white justify-center mt-2">
                      {" "}
                      <ImWarning /> {t(
                        "Your_Profile_Will_Be_Deleted_Message"
                      )}{" "}
                      / {t("You_Still_Have_Days_Left")}{" "}
                      {markedfordeletiondaysleft} {t("Days_left")}
                    </h1>
                  )}
                </div>
                <div className="bg-sr my-4 shadow-lg rounded-md ">
                  {systemAlert.length !== 0 && (
                    <div className=" notice">
                      <Carousel>
                        {systemAlert.map((item, ind) => (
                          <Carousel.Item key={ind}>
                            <div className="py-2 ml-28 mr-28 text-lg text-pr text-center font-bold">
                              {item.text}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    </div>
                  )}
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 pb-20">
                  <div className=" bg-white shadow-md rounded-md">
                    <div className="my-5 grid grid-cols-1 gap-2 px-2">
                      <div className="flex items-center gap-1">
                        {profiledata?.approved ? (
                          <FcApproval
                            color="green"
                            size="30"
                            className="mb-4"
                          />
                        ) : (
                          <MdApproval color="red" size="30" />
                        )}
                        <div className="flex gap-1 text-sr font-medium">
                          <p
                            className={
                              "py-0 mt-0 mb-4 px-2 flix " +
                              (profiledata?.approved
                                ? " text-green-600 "
                                : " text-red-900 animate-bounce")
                            }
                          >
                            {profiledata?.approved
                              ? t("Profile_Is_A_Approved")
                              : t("Profile_Is_Waiting_For_Approval")}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-1">
                        <div className="flex gap-1 text-sr font-normal">
                          <p>
                            {t("Your_Profile_Is")}{" "}
                            {profiledata.profilepercentcomplete}%{" "}
                            {t("Complete")}
                          </p>
                        </div>
                        <Link
                          to="/profile-settings/edit"
                          className=" text-gray-700 py-2 hover:text-pr "
                        >
                          <FaUserEdit
                            className="h-5 w-5 mr-1"
                            title={t("Profile_Edit")}
                          />
                        </Link>
                      </div>
                      <div
                        className="flex items-center gap-1"
                        onClick={handleProfileEdit}
                        style={{ cursor: "pointer" }}
                      >
                        <ProgressBar
                          animated
                          variant="success"
                          className="w-full"
                          now={profiledata.profilepercentcomplete}
                        />
                        {/* <div className="flex gap-1 text-sr font-normal">
                          <p>
                            {t("Your_Profile_Is")}{" "}
                            {profiledata.profilepercentcomplete}%{" "}
                            {t("Complete")}
                          </p>
                        </div> */}
                        {/* <Link
                          to="/profile-settings/edit"
                          className=" text-gray-700 py-2 hover:text-pr "
                        >
                          <FaUserEdit
                            className="h-5 w-5 mr-1"
                            title={t("Profile_Edit")}
                          />
                        </Link> */}
                      </div>
                      <div
                        className={
                          "py-2 my-1 px-2" +
                          (subscriptiondaysleft <= 0
                            ? " bg-red-700 "
                            : " bg-green-700 ") +
                          " shadow-md  flex items-center justify-center gap-2 transition ease-linear duration-300  text-center rounded text-white font-medium"
                        }
                      >
                        {subscriptiondaysleft <= 0 ? (
                          <ImWarning className="text-yellow-300" />
                        ) : (
                          ""
                        )}
                        <p>
                          {" "}
                          {subscriptiondaysleft <= 0
                            ? t("Subscription_Has_Expired")
                            : t("Subscription_Expiry_Date")}
                        </p>{" "}
                        {expriredate !== null && expriredate.slice(0, 10)}
                      </div>
                      <Link
                        to="/payment"
                        className="py-2 px-2 bg-sr shadow-md justify-center cursor-pointer flex items-center gap-1 transition ease-linear duration-300 hover:bg-pr text-center rounded text-white"
                      >
                        <p>{t("Upgrade_My_Subscription")} </p>
                        <GiArmorUpgrade />
                      </Link>
                      <div className="flex items-left gap-1 justify-between w-full">
                        {platinum ? (
                          <div className="flex flex-row items-center gap-2 pl-3">
                            <FormControlLabel
                              control={
                                <Switch
                                  inputProps={{ "aria-label": "controlled" }}
                                  onChange={(e) => {
                                    activate_deactivateAnnonims();
                                    setAnnonims(e.target.checked);
                                  }}
                                  checked={annonims}
                                />
                              }
                              // label={
                              //   annonims
                              //     ? t("Surf_Anonymously")
                              //     : t("Set_Your_Profile_As_Anonym")
                              // }
                            />
                            <span className=" font-bold">
                              {`${
                                annonims
                                  ? t("Surf_Anonymously")
                                  : t("Set_Your_Profile_As_Anonym")
                              }`}
                            </span>
                          </div>
                        ) : (
                          <div className="flex flex-row items-center gap-2 pl-3">
                            <FormControlLabel
                              control={
                                <Switch
                                  inputProps={{ "aria-label": "controlled" }}
                                  onClick={upgradeMemeberShipHandler}
                                  checked={false}
                                />
                              }
                              // label={`${t("Set_Your_Profile_As_Anonym")}`}
                              // <p className="text-xs text-sr font-medium">
                              //   {t("Set_Your_Profile_As_Anonym")}
                              // </p>
                            />
                            <span className="font-bold text-gray-900">
                              {t("Set_Your_Profile_As_Anonym")}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="flex items-left gap-1 justify-between w-full">
                        <div className="flex flex-row items-center gap-2 pl-3 ">
                          <FormControlLabel
                            control={
                              <Switch
                                inputProps={{ "aria-label": "controlled" }}
                                onChange={handleConfirmation}
                                checked={profileIsActivated}
                              />
                            }
                            // label={`${
                            //   profileIsActivated
                            //     ? t("Disable_Profile")
                            //     : t("Enable_Profile")
                            // }`}
                            // <p className="text-xs text-sr font-medium">
                            //   {check ? t("Disable_Profile") : t("Enable_Profile")}
                            // </p>
                          />
                          <span className="font-bold">
                            {`${
                              profileIsActivated
                                ? t("Disable_Profile")
                                : t("Enable_Profile")
                            }`}
                          </span>
                        </div>
                      </div>
                      {totalMaleOnline !== null &&
                        (currentGender === false ||
                          currentGender === "false") && (
                          <div className=" mt-4 border-t border-b py-2 px-5 w-full grid grid-cols-2">
                            <p className=" text-sm font-medium text-green-500 col-start-1 col-end-3">
                              <Link
                                className="text-pr font-medium"
                                to="/search/online"
                              >
                                {t("Males_Online_Now")}{" "}
                              </Link>
                            </p>{" "}
                            <p className=" text-green-500 text-sm font-extrabold col-start-3 col-end-4">
                              {totalMaleOnline}
                            </p>
                          </div>
                        )}

                      {totalFemaleOnline !== null &&
                        (currentGender === true ||
                          currentGender === "true") && (
                          <div className=" mt-0  border-b py-2 px-5 w-full grid grid-cols-2">
                            <p className=" text-sm font-medium text-green-500 col-start-1 col-end-3">
                              <Link
                                className="text-pr font-medium"
                                to="/search/online"
                              >
                                {t("Females_Online_Now")}{" "}
                              </Link>
                            </p>{" "}
                            <p className=" text-green-500 text-sm font-extrabold col-start-3 col-end-4">
                              {totalFemaleOnline}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className=" bg-white shadow-md rounded-md">
                    {statisticspage !== null && (
                      <div
                        className="border-b py-2 px-2 text-sr"
                        dangerouslySetInnerHTML={{
                          __html: statisticspage.text,
                        }}
                      ></div>
                    )}
                    {statistics !== null && (
                      <div className="p-4 gap-3">
                        <div className="w-full">
                          <div
                            className={
                              statistics.totalcashpoints !== 0
                                ? "text-gray-600 text-sm border-b py-2 grid-cols-3 items-center grid"
                                : "text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid"
                            }
                          >
                            <p className="font-medium">
                              {t("Total_Cash_Point")}
                            </p>
                            <p className="flex items-center justify-center font-extrabold">
                              {statistics.totalcashpoints}
                            </p>

                            {statistics.totalcashpoints !== 0 && (
                              <div className="flex items-center justify-end">
                                <button
                                  onClick={redeem}
                                  className="bg-pr hover:bg-sr text-white py-2 px-3 w-32 rounded-md"
                                >
                                  {t("Reedem")}
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Unread_Messages")}
                            </p>
                            <p className="flex items-center justify-center font-extrabold ">
                              {statistics.totalunreadmessages !== 0 ? (
                                <Link to="/chat/active">
                                  <Badge
                                    color="secondary"
                                    badgeContent={
                                      statistics.totalunreadmessages > 99
                                        ? "99+"
                                        : statistics.totalunreadmessages
                                    }
                                  >
                                    <FiMail className="w-7 h-7 text-sr" />
                                  </Badge>{" "}
                                </Link>
                              ) : (
                                statistics.totalunreadmessages
                              )}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_New_Likes")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalnewlikes !== 0 ? (
                                <Link to="/activity/likedme">
                                  <Badge
                                    color="secondary"
                                    badgeContent={
                                      statistics.totalnewlikes > 99
                                        ? "99+"
                                        : statistics.totalnewlikes
                                    }
                                  >
                                    <RiHeartsFill className="w-6 h-6 text-red-600 " />
                                  </Badge>
                                </Link>
                              ) : (
                                statistics.totalnewlikes
                              )}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Sent_Likes")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totallikes}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Received_Likes")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totallikedme}
                            </p>
                          </div>

                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">{t("Total_Viewers")}</p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalviewers}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Viewed_Me")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalviewedme}
                            </p>
                          </div>

                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Favorites")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalfavorites}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Favorited_Me")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalfavoritedme}
                            </p>
                          </div>

                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">{t("Total_Blocked")}</p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalblocked}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Active_Agents")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalactiveagents}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_InActive_Agents")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalinactiveagents}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">{t("Total_Images")}</p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalimages}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Active_Images")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalactiveimages}
                            </p>
                          </div>
                          <div className="text-gray-600 text-sm border-b py-2 grid-cols-2 items-center grid">
                            <p className="font-medium">
                              {t("Total_Inactive_Images")}
                            </p>
                            <p className="flex items-center justify-center  font-extrabold ">
                              {statistics.totalinactiveimages}
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    )}
                  </div>
                  <div className=" bg-white shadow-md rounded-md p-2">
                    <h1 className="text-sr font-bold text-sm pl-4 pb-2 flex items-center gap-2">
                      {t("Quick_Links")}{" "}
                      <AiOutlineArrowDown className="text-sr" />
                    </h1>
                    <div className="grid grid-cols-1 gap-2 px-3">
                      <Link
                        to="/chat/active"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <BiMailSend
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />{" "}
                        {t("New_Mails")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/activity/likedme"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                      >
                        <AiOutlineLike
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("New_Likes")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>

                      <Link
                        to="/search/basic"
                        onClick={() => {
                          setBasicshow(true);
                          localStorage.removeItem("basic");
                        }}
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <BiSearch
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Search_Basic")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/search/advance"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                        onClick={() => localStorage.removeItem("advance")}
                      >
                        <AiOutlineFileSearch
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Advanced_Search")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>

                      <Link
                        to="/profile-settings/photos"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <HiOutlinePhotograph
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Profile_Settings_My_Photos")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/matches/mymatches"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                      >
                        <AiOutlineUsergroupAdd
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("My_Matches")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/profile-settings/edit"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <FaUserEdit
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Profile_Edit")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/profile-settings/image-access"
                        className="text-center golink flex items-center relative justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                      >
                        <DiMagento
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />{" "}
                        {t("Profile_Settings_Images_Access")}
                        {requestCount.length !== 0 && (
                          <p
                            className="absolute right-2 top-2 px-2 text-pr  rounded-full"
                            style={{ background: "#9C27B0" }}
                          >
                            {requestCount.length}
                          </p>
                        )}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/profile-settings/delete"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <AiOutlineDelete
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Delete_Account")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/matches/filter"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                      >
                        <FaFilter
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Manage_Queries")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/memberssettings/notification"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <MdOutlineNotificationsNone
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Notifications_Settings")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <Link
                        to="/memberssettings/site-settings"
                        className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                      >
                        <RiUserSettingsLine
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />
                        {t("Site_Settings")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </Link>
                      <div
                        onClick={() => setSupport(true)}
                        className="text-center golink flex items-center cursor-pointer justify-center uppercase text-white py-2 bg-sr hover:bg-pr transition ease-linear duration-300  rounded"
                      >
                        <MdContactSupport
                          className={`h-5 w-5 ${
                            language === "ar" ? "ml-1" : "mr-1"
                          } `}
                        />{" "}
                        {t("Support")}{" "}
                        {language === "ar" ? (
                          <AiOutlineDoubleLeft className="icon" />
                        ) : (
                          <AiOutlineDoubleRight className="icon" />
                        )}
                      </div>
                      {(profiledata.isadmin || profiledata.ismanager) && (
                        <Link
                          to="/admin/profiles"
                          onClick={() => {
                            setShow(false);
                          }}
                          className="text-center golink flex items-center justify-center uppercase text-white py-2 bg-pr hover:bg-sr transition ease-linear duration-300  rounded"
                        >
                          <MdOutlineAdminPanelSettings
                            className={`h-5 w-5 ${
                              language === "ar" ? "ml-1" : "mr-1"
                            } `}
                          />
                          {t("Admin_Panel")}{" "}
                          {language === "ar" ? (
                            <AiOutlineDoubleLeft className="icon" />
                          ) : (
                            <AiOutlineDoubleRight className="icon" />
                          )}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
      {profiledata && statistics && (
        <CompleteProfileReminder
          isProfileCompleted={profiledata?.profilepercentcomplete >= 60}
          hasProfileImage={statistics?.totalimages > 0}
        />
      )}
    </>
  );
};

export default Dashboard;
