import React, { useContext, useState, useEffect } from "react";
import { VscThreeBars } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cart from "../card/Cart";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import {
  BsFillGrid3X3GapFill,
  BsBlockquoteLeft,
  BsBlockquoteRight,
} from "react-icons/bs";
import { GiDuration } from "react-icons/gi";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import heart from "../../../images/heart.gif";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Latest = () => {
  const { grid, setGrid, changnow, language } = useContext(MainContent);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("OneWeek");
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });
  const showFontPageData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${url}profile/getfrontpageprofiles`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        setFontpageProfiles(result.data);
      });
  };
  const prev = (crl) => {
    window.scrollTo(0, 0);
    showFontPageData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,

      redirect: "follow",
    };

    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
        } else {
          setSearchResult(null);
          saveProfileIds(null);
        }
      });
  };
  const next = (crl) => {
    window.scrollTo(0, 0);
    showFontPageData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(crl, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
        } else {
          setSearchResult(null);
          saveProfileIds(null);
        }
      });
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    showFontPageData();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${url}profile/getnew?timeduration=${selectedDuration}`,
      requestOptions
    )
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.data.length !== 0) {
          setSearchResult(result.data);
          saveProfileIds(result.data);
          setNxturl(result.nextpage);
          setPrvurl(result.previouspage);
        } else {
          setSearchResult(null);
          saveProfileIds(null);
        }
        setLoading(false);
      });
  }, [changnow, language, selectedDuration]);

  // if (loading) return <h1>Loading...</h1>;
  if (loading)
    return (
      <div className="flex items-start justify-center h-screen">
        <img src={heart} className="h-96  rounded-full" alt="" />
      </div>
    );

  const handleDurationChange = (event) => {
    setSelectedDuration(event.target.value);
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Latest_Profiles")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl  md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Latest_Profiles")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div className="mt-3 pb-3">
          <div className="bg-white py-3 px-2 w-full shadow-md rounded-lg mb-3">
            <div>
              <div className=" py-2 flex items-center gap-1">
                <div className="p-2 rounded-full bg-pr flex items-center justify-center">
                  <GiDuration className="w-5 h-5 text-white" />
                </div>{" "}
                {t("Select_Duration")}
                {/* <GiDuration className="text-pr flex ml-2" />
            <label htmlFor="durationSelect">{t("Select_Duration")}</label> */}
                <select
                  id="durationSelect"
                  value={selectedDuration}
                  onChange={handleDurationChange}
                  className="text-green-600 font-extrabold"
                >
                  {/* <option value="">{t("--Select --")}</option> */}
                  <option className="text-black" value="TwentyFourHours">
                    {t("24_hours")}
                  </option>
                  <option className="text-black" value="FortyEightHours">
                    {t("48_hours")}
                  </option>
                  <option className="text-black" value="OneWeek">
                    {t("1_week")}
                  </option>
                  <option className="text-black" value="TwoWeeks">
                    {t("2_weeks")}
                  </option>
                  <option className="text-black" value="OneMonth">
                    {t("1_month")}
                  </option>
                  <option className="text-black" value="ThreeMonths">
                    {t("3_months")}
                  </option>
                </select>{" "}
              </div>
            </div>
          </div>
          {searchResult !== null ? (
            <>
              {searchResult.length !== 0 ? (
                <>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {searchResult.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                  <div className="w-full flex mt-14 items-center justify-end gap-5">
                    {prvurl !== null || nxturl !== null ? (
                      <>
                        {prvurl !== null ? (
                          <button
                            onClick={() => next(prvurl)}
                            className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                          >
                            {t("Previous")}
                          </button>
                        )}
                        {searchResult.length !== 10 ? (
                          <button
                            disabled
                            className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        ) : (
                          <button
                            onClick={() => prev(nxturl)}
                            className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                  {t("No_Data_Found")}
                </div>
              )}

              {fontpageProfiles?.length !== 0 && (
                <>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                  {t("Recommended_Profiles")}
                </h1> */}
                  <SectionSeparator
                    Section_Title={t("Recommended_Profiles")}
                  ></SectionSeparator>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {fontpageProfiles.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
              {t("No_Profile_Was_Found_For_The_specified_Period")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Latest;
