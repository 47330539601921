import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n.js";
import "./index.css";
import heart from "./images/heart.gif";
import ReactGA from "react-ga4";

ReactGA.initialize("G-EP53E1WRVW");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const spinner = (
  <div
    className="flex items-start justify-center h-screen"
    role="status"
    aria-label="Loading"
  >
    <img src={heart} className="h-96  rounded-full" alt="Loading spinner" />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={spinner}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
