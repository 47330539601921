import React, { useState, useEffect, useContext } from "react";
import { url } from "../../../api/Api";
import { MainContent } from "../../../App";
import { useTranslation } from "react-i18next";
import { BsBlockquoteRight, BsBlockquoteLeft } from "react-icons/bs";
import heart from "../../../images/heart.gif";
import fetchHandler from "../../../api/fetchHandler";
import PeopleCard from "./PeopleCard";
import { ToastContainer } from "react-toastify";
import ActivityNoteMessage from "../../ActivityNoteMessage";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Liked = () => {
  const { changenow, language } = useContext(MainContent);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [nexturl, setNexturl] = useState();
  const [response, setResponse] = useState(null);
  const [prvurl, setPrvurl] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getlikedme = (crl) => {
    fetchHandler(crl, "GET", undefined).then((result) => {
      setResponse(result);
      setData(result.data);
      saveProfileIds(result.data);
      setPrvurl(result.previouspage);
      setNexturl(result.nextpage);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getlikedme(`${url}profile/like/likedme`);
  }, [changenow, language]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Activity_Liked_Me")}
        language={language}
      />
      {response !== null ? (
        <div className="pb-20">
          <div>
            <div className=" w-full flex items-center justify-center ">
              <h1 className="text-center my-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
                <BsBlockquoteLeft className="text-sr" />
                {t("Activity_Liked_Me")}
                <BsBlockquoteRight className="text-sr" />
              </h1>
            </div>
            <ToastContainer />
            <div className=" w-full h-full">
              {data.length !== 0 ? (
                <>
                  {/* <div className="flex flex-wrap gap-2 justify-center items-center p-4"> */}
                  <div className="my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto">
                    {data.map((item, ind) => (
                      <PeopleCard
                        key={ind}
                        item={item}
                        cardProfileId={item.profileid}
                      ></PeopleCard>
                    ))}
                  </div>
                  <div className="pb-20">
                    <div
                      className={
                        language !== "ar"
                          ? "float-right flex items-center gap-4"
                          : "float-left flex items-center gap-4"
                      }
                    >
                      {prvurl !== null || nexturl !== null ? (
                        <>
                          {prvurl !== null && prvurl !== undefined ? (
                            <button
                              onClick={() => getlikedme(prvurl)}
                              className="bg-sr hover:bg-pr px-3 text-xs py-2 rounded text-white transition ease-linear duration-150 "
                            >
                              {t("Previous")}
                            </button>
                          ) : (
                            <button
                              disabled
                              className="bg-sr hover:bg-pr px-3 text-xs cursor-not-allowed py-2 rounded text-white transition ease-linear duration-150 "
                            >
                              {t("Previous")}
                            </button>
                          )}
                          {nexturl !== null && nexturl !== undefined ? (
                            <button
                              onClick={() => getlikedme(nexturl)}
                              className="bg-sr hover:bg-pr px-3 text-xs py-2  rounded text-white transition ease-linear duration-150 "
                            >
                              {t("Next")}
                            </button>
                          ) : (
                            <button
                              disabled
                              className="bg-sr hover:bg-pr px-3 text-xs py-2 cursor-not-allowed rounded text-white transition ease-linear duration-150 "
                            >
                              {t("Next")}
                            </button>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="bg-white rounded-xl shadow-md text-black flex py-5">
                  <h1 className={language === "ar" ? "mr-8" : "ml-8"}>
                    {t("LikesReceived_NoData")}
                    {/* {t("No_Data_Found")} */}
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
      <ActivityNoteMessage
        Section_Title={t("Note")}
        paddingBotton={" pb-2"}
      ></ActivityNoteMessage>
    </>
  );
};

export default Liked;
