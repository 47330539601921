import React, { useContext, useState, useEffect } from "react";
import { VscThreeBars } from "react-icons/vsc";
import { MainContent } from "../../../App";
import Cart from "../card/Cart";
import { url } from "../../../api/Api";
import { useTranslation } from "react-i18next";
import {
  BsFillGrid3X3GapFill,
  BsBlockquoteLeft,
  BsBlockquoteRight,
} from "react-icons/bs";
import heart from "../../../images/heart.gif";
import fetchHandler from "../../../api/fetchHandler";
import { ToastContainer } from "react-toastify";
import SectionSeparator from "../../SectionSeparator";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Topten = () => {
  const { grid, setGrid, changnow, language } = useContext(MainContent);
  const [prvurl, setPrvurl] = useState(null);
  const [nxturl, setNxturl] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [fontpageProfiles, setFontpageProfiles] = useState([]);

  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const showFontPageData = () => {
    fetchHandler(`${url}profile/getfrontpageprofiles`, "GET").then((result) => {
      setFontpageProfiles(result.data);
    });
  };
  const { t } = useTranslation();

  const prev = (crl) => {
    showFontPageData();
    fetchHandler(crl, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult(null);
        saveProfileIds(null);
      }
    });
  };
  const next = (crl) => {
    showFontPageData();
    fetchHandler(crl, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult(null);
        saveProfileIds(null);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    showFontPageData();
    fetchHandler(`${url}profile/gettopten`, "GET").then((result) => {
      if (result.data.length !== 0) {
        setSearchResult(result.data);
        saveProfileIds(result.data);
        setNxturl(result.nextpage);
        setPrvurl(result.previouspage);
      } else {
        setSearchResult([]);
        saveProfileIds(null);
      }
    });
  }, [changnow, language]);

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Top_Ten_Profiles")}
        language={language}
      />
      <div>
        <div className=" w-full flex items-center justify-center ">
          <h1 className="text-center my-9 text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
            <BsBlockquoteLeft className="text-sr" />
            {t("Top_Ten_Profiles")}
            <BsBlockquoteRight className="text-sr" />
          </h1>
        </div>
        <ToastContainer />
        <div>
          {searchResult !== null ? (
            <>
              {searchResult.length !== 0 ? (
                <>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {searchResult.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                  {/* <div className="w-full flex mt-14 items-center justify-end gap-5 pb-5">
                  {prvurl !== null ? (
                    <button
                      onClick={() => next(prvurl)}
                      className="px-3 py-2 bg-pr text-white rounded-md hover:bg-sr"
                    >
                      {t("Previous")}
                    </button>
                  ) : (
                    <button
                      disabled
                      className="px-3 py-2 bg-pr cursor-not-allowed text-white rounded-md hover:bg-sr"
                    >
                      {t("Previous")}
                    </button>
                  )}
                  {searchResult.length !== 12 ? (
                    <button
                      disabled
                      className="px-3 py-2 cursor-not-allowed bg-sr text-white rounded-md hover:bg-pr"
                    >
                      {t("Next")}
                    </button>
                  ) : (
                    <button
                      onClick={() => prev(nxturl)}
                      className="px-3 py-2 bg-sr text-white rounded-md hover:bg-pr"
                    >
                      {t("Next")}
                    </button>
                  )}
                </div> */}
                </>
              ) : (
                <div className="bg-white py-5 px-2 w-full shadow-md rounded-lg">
                  {t("No_Result_Found")}
                </div>
              )}
              {fontpageProfiles.length !== 0 && (
                <>
                  {/* <h1 className="text-3xl mt-3 font-medium text-gray-700">
                  {t("Recommended_Profiles")}
                </h1> */}
                  <SectionSeparator
                    Section_Title={t("Recommended_Profiles")}
                  ></SectionSeparator>
                  <div className="flex items-center justify-end gap-3 mt-3">
                    <div>
                      <BsFillGrid3X3GapFill
                        onClick={() => setGrid(true)}
                        // className="cursor-pointer text-3xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                    <div>
                      <VscThreeBars
                        onClick={() => setGrid(false)}
                        // className="cursor-pointer text-4xl text-sr"
                        className={`cursor-pointer text-3xl  ${
                          !grid ? "text-gray-400" : "text-sr"
                        }`}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      grid
                        ? "my-8 grid grid-cols-1 justify-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center"
                        : "my-8 grid grid-cols-1 lg:grid-cols-2 justify-center gap-2 items-center"
                    }
                  >
                    {fontpageProfiles.map((item, ind) => (
                      <Cart key={ind} data={item} />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="flex items-start justify-center h-screen">
              <img src={heart} className="h-96  rounded-full" alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Topten;
