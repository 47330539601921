import Cookies from "js-cookie";
import React, { useState, useEffect, useContext } from "react";
import {
  BsFillEyeFill,
  BsHeartFill,
  BsHeart,
  BsBlockquoteRight,
  BsBlockquoteLeft,
} from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { url, errorHandler } from "../../../api/Api";
import { MainContent } from "../../../App";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import heart from "../../../images/heart.gif";
import PeopleCard from "./../peopleActivity/PeopleCard";
import ActivityNoteMessage from "../../ActivityNoteMessage";
import { saveProfileIds } from "../../../utils/profileUtils";
import FixedHeader from "../../../layout/FixedHeader";

const Mylikes = () => {
  const { changenow, language } = useContext(MainContent);
  const [response, setResponse] = useState(null);
  const [data, setData] = useState([]);
  const [realod, setRealod] = useState(false);
  const [nexturl, setNexturl] = useState();
  const [prvurl, setPrvurl] = useState();
  const { t } = useTranslation();
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getMyLikes = (uri) => {
    setRealod(false);
    let getheders = new Headers();
    getheders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    getheders.append("Accept-Language", language || "en");

    const getRequestOptions = {
      method: "GET",
      headers: getheders,
      redirect: "follow",
    };
    fetch(uri, getRequestOptions)
      .then((response) => {
        errorHandler(response);
        setResponse(response);
        return response.json();
      })
      .then((result) => {
        setData(result.data);
        saveProfileIds(result.data);
        setPrvurl(result.previouspage);
        setNexturl(result.nextpage);
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getMyLikes(`${url}profile/like/ilike`);
  }, [changenow, realod, language]);

  const likeRemove = (myid, profileid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");
    let raw = JSON.stringify({
      profileId: myid,
      likeprofileid: profileid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}profile/like/remove`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setRealod(true);
        } else {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Activity_My_Likes")}
        language={language}
      />
      {response !== null ? (
        <div>
          <div className=" w-full flex items-center justify-center ">
            <h1 className="text-center my-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
              <BsBlockquoteLeft className="text-sr" />
              {t("Activity_My_Likes")}
              <BsBlockquoteRight className="text-sr" />
            </h1>
          </div>
          <ToastContainer />
          <>
            {data.length > 0 ? (
              <>
                {data.length > 0 && (
                  <>
                    {/* <div className="flex flex-wrap gap-2 justify-center items-center p-4"> */}
                    <div className="my-8 grid grid-cols-1 justify-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 items-center mx-auto">
                      {data.map((item, ind) => (
                        <PeopleCard
                          key={ind}
                          item={item}
                          cardProfileId={item.peoplecardprofileid}
                          likedmeback={item.likedmeback}
                        >
                          <div className="flex items-center justify-evenly text-pr text-xl mt-4">
                            {item.likedmeback ? (
                              <div>
                                <BsHeartFill className="h-5 cursor-pointer w-5" />
                              </div>
                            ) : (
                              <div>
                                <BsHeart className="h-5 cursor-pointer w-5" />
                              </div>
                            )}
                            <Link to={"/profile/" + item.likeprofileid}>
                              <BsFillEyeFill className="h-5 cursor-pointer w-5" />
                            </Link>
                            <AiFillDelete
                              onClick={() =>
                                likeRemove(item.profileid, item.likeprofileid)
                              }
                              className="h-5 cursor-pointer w-5"
                            />
                          </div>
                        </PeopleCard>
                      ))}
                    </div>
                  </>
                )}
                <div className="pb-20">
                  <div
                    className={
                      language !== "ar"
                        ? "float-right flex items-center gap-4"
                        : "float-left flex items-center gap-4"
                    }
                  >
                    {prvurl !== null || nexturl !== null ? (
                      <>
                        {prvurl !== null && prvurl !== undefined ? (
                          <button
                            onClick={() => getMyLikes(prvurl)}
                            className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Previous")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="bg-sr text-xs px-3 py-2 cursor-not-allowed rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Previous")}
                          </button>
                        )}
                        {nexturl !== null && nexturl !== undefined ? (
                          <button
                            onClick={() => getMyLikes(nexturl)}
                            className="bg-sr text-xs px-3 py-2 rounded text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        ) : (
                          <button
                            disabled
                            className="bg-sr text-xs px-3 py-2 rounded cursor-not-allowed text-white transition ease-linear duration-150 hover:bg-pr"
                          >
                            {t("Next")}
                          </button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="bg-white rounded-xl shadow-md text-black flex  py-5">
                {/* <h1 className=" ml-8"> {t("No_Data_Found")}</h1> */}
                <h1 className={language === "ar" ? "mr-8" : "ml-8"}>
                  {" "}
                  {t("LikesSent_NoData")}
                </h1>
              </div>
            )}
          </>
        </div>
      ) : (
        <div className="flex items-start justify-center h-screen">
          <img src={heart} className="h-96  rounded-full" alt="" />
        </div>
      )}
      <ActivityNoteMessage
        Section_Title={t("Note")}
        paddingBotton={" pb-2"}
      ></ActivityNoteMessage>
    </>
  );
};

export default Mylikes;
