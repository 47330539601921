import React, { useContext, useState, useEffect, useRef } from "react";
import { BiCrop } from "react-icons/bi";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ProfileNavbar from "../../../layout/ProfileNavbar";
import { url, errorHandler } from "../../../api/Api";
import { MainContent } from "../../../App";
import { ImProfile } from "react-icons/im";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlinePendingActions, MdDelete } from "react-icons/md";
import { BsBlockquoteRight, BsBlockquoteLeft, BsCheck2 } from "react-icons/bs";
import fetchHandler from "../../../api/fetchHandler";
import muslimf from "../../../images/avatar_female.jpg";
import noimage from "../../../images/avatar_male.jpg";
import Swal from "sweetalert2";
import { customToasterStyle } from "../../../utils/constants";
import FixedHeader from "../../../layout/FixedHeader";
import { MdFileUpload } from "react-icons/md";
import { BsPersonCheck } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import { TbCameraCancel } from "react-icons/tb";
import DynamicButton from "./../../../components/DynamicButton";

const ProfilePhotos = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { changenow, language, setFreshReload } = useContext(MainContent);
  const [src, selectFile] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 60,
    height: 40,
    aspect: 1,
    x: 5,
    y: 5,
  });

  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [uploadfile, setUploadfile] = useState(null);
  const [profileid, setprofileid] = useState("");
  const [load, setLoad] = useState(false);
  const [showfinal, setShowfinal] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [reload, setReload] = useState(false);
  const [albumid, setAlbumid] = useState();
  const [changed, setChanged] = useState(false);
  const [rules, setRules] = useState(null);
  const [ppublic, setPpublic] = useState(true);
  const [warningText, setWarningText] = useState("");
  const [show, setShow] = useState(false);
  const [startpage, setStartpage] = useState(false);
  const [platinum, setPlatinum] = useState(false);
  const [isImageContainerEmpty, setIsImageContainerEmpty] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isProfileContentComplete, setIsProfileContentComplete] =
    useState(null);
  const [profileComplet, setProfileComplet] = useState(0);
  const imageListRef = useRef(null);
  const cropResult = useRef(null);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  const getFile = (file) => {
    if (/\.(jpe?g|png|jpg|webp)$/i.test(file.name)) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const height = this.height;
          if (height > 800) {
            setWarningText(`${t("Please_Crop_Image")}`);
            setShow(false);
            const objectUrl = URL.createObjectURL(file);
            selectFile(objectUrl);
            setUploadfile(file);
          } else {
            setWarningText("");
            setShow(true);
            const objectUrl = URL.createObjectURL(file);
            selectFile(objectUrl);
            setUploadfile(file);
          }
        };
      };
    } else {
      setWarningText(t("Invalid_Image_Format"));
    }
  };

  const imageOnError = (event) => {
    event.currentTarget.src = getOwnData?.profileattributes?.gender
      ? noimage
      : muslimf;
  };

  const getRules = () => {
    let myHeaders = new Headers();
    myHeaders.append("ApiKey", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    fetchHandler(
      `${url}sitepage/pagetype?PageTypeId=13&LangCode=${language}`,
      "GET",
      undefined,
      myHeaders
    ).then((result) => {
      if (result.data) {
        setRules(result.data.text);
      }
    });
  };

  const getOwnData = () => {
    fetchHandler(`${url}profile/own`, "GET").then((result) => {
      if (result.data) {
        setprofileid(result.data.profileid);
        setPlatinum(
          result.data.profileattributes.subscriptiontype === "Platinum"
        );
        setIsProfileContentComplete(
          result?.data?.profilecontent !== null ||
            result?.data?.profilecontentdraft !== null
        );
        setProfileComplet(
          result?.data?.profileattributes.profilepercentcomplete
        );

        setIsImageContainerEmpty(result.data.albumcontainer === null);
        if (result.data.albumcontainer !== null) {
          setAlbumid(result.data.albumcontainer.albumcontainerid);
          setPpublic(result.data.albumcontainer.ispublic);
          setStartpage(result.data.albumcontainer.showonfrontpage);
          if (result.data.albumcontainer.pictures.length !== 0) {
            setReload(false);
            const filterdta = result.data.albumcontainer.pictures.filter(
              (item) => item.ismain === true && item.ismainblurred === false
            );
            setImageData(filterdta);
          } else {
            setImageData([]);
          }
        }
      }
    });
  };

  useEffect(() => {
    //window.scrollTo(0, 0);
    getRules();
    getOwnData();
  }, [changenow, reload, language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getCroppedImg() {
    setWarningText("");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
  }

  function dataURLtoFile(dataurl, filename) {
    setShowfinal(true);
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], filename, { type: mime });
    setUploadfile(file);
  }

  const toastcontroller = (result) => {
    if (result.success) {
      toast.success(result?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result?.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };

  const cancelUploadimage = () => {
    setShow(false);
    setUploadfile(null);
    setShowfinal(false);
    selectFile(null);
    setImage(null);
    setShowfinal(false);
    setResult(null);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  const uploadimage = (e) => {
    e.preventDefault();
    setLoad(true);
    setShow(false);
    setReload(false);
    setUploadfile(null);
    setShowfinal(false);
    selectFile(null);
    setImage(null);
    setShowfinal(false);
    setResult(null);

    const sendFormData = () => {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
      myHeaders.append("Accept-Language", language || "en");

      let formdata = new FormData();
      formdata.append("Files", uploadfile);
      formdata.append("ProfileId", profileid);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${url}image/upload`, requestOptions)
        .then((response) => {
          errorHandler(response);
          return response.json();
        })
        .then((result) => {
          setLoad(false);
          if (result.success) {
            setReload(true);
            setChanged(true);
          }
          toastcontroller(result);
        });
    };
    sendFormData();
  };
  let pheaders = new Headers();
  pheaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  pheaders.append("Content-Type", "application/json");
  pheaders.append("Accept-Language", language || "en");

  const deletePhoto = (id) => {
    let raw = JSON.stringify({
      containerId: albumid,
      pictureId: id,
    });
    fetchHandler(`${url}image/delete`, "DELETE", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setReload(true);
          setChanged(!changed);
        }
        toastcontroller(result);
      }
    );
  };

  const makePrimaryPhoto = (id) => {
    let raw = JSON.stringify({
      containerId: albumid,
      pictureId: id,
    });
    fetchHandler(`${url}image/primary`, "PATCH", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setReload(true);
          setChanged(true);
        }
        toastcontroller(result);
      }
    );
  };

  const makePrivate = () => {
    let raw = JSON.stringify({
      containerid: albumid,
    });
    fetchHandler(`${url}container/access`, "PATCH", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setReload(true);
          setFreshReload(true);
        }
        toastcontroller(result);
      }
    );
  };
  const showOnStartPage = () => {
    let raw = JSON.stringify({
      containerid: albumid,
    });
    fetchHandler(`${url}container/frontpage`, "PATCH", raw, pheaders).then(
      (result) => {
        if (result.success) {
          setReload(true);
        }
        toastcontroller(result);
      }
    );
  };

  const upgradeMemeberShipHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      text: t("Platinum_Membership_Required"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Yes_Upgrade"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        history("/payment");
      }
    });
  };

  const scrollToSection = () => {
    if (imageListRef.current) {
      imageListRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToCropResultSection = () => {
    if (cropResult.current) {
      cropResult.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <FixedHeader
        scrollPosition={scrollPosition}
        headerText={t("Profile_Settings_My_Photos")}
        language={language}
      />
      <div className="container bg-blue-50 mt-24 lg:mt-16 lg:pt-10 min-h-screen ">
        <ToastContainer />
        {load && (
          <div
            style={{ background: "rgba(0,0,0,0.9)" }}
            className="flex absolute z-40 top-0 left-0 w-full items-center justify-center min-h-screen"
          >
            <Spinner animation="grow" variant="primary" />{" "}
            <h1 className="text-white text-xl lg:text-2xl ml-2 ">Uploading</h1>
          </div>
        )}
        <div className=" w-full">
          <ProfileNavbar changed={changed} setChanged={setChanged} />
        </div>
        <div className=" w-full pt-4">
          <div className="w-full flex flex-col lg:flex-row items-center gap-2 mb-10">
            <div className=" flex items-center justify-center flex-grow ">
              <h1 className="text-center mt-9  text-sr font-medium sm:text-xl md:text-2xl lg:text-4xl flex items-center gap-2 capitalize">
                <BsBlockquoteLeft className="text-sr" />
                {t("Profile_Settings_My_Photos")}
                <BsBlockquoteRight className="text-sr" />
              </h1>
            </div>
            {(isProfileContentComplete === false || profileComplet < 60) && (
              <div className="flex justify-center w-full lg:w-auto lg:ml-auto mt-2 lg:mt-0">
                <DynamicButton />
              </div>
            )}
          </div>

          {!load && (
            <>
              {imageData.length >= 5 ? null : (
                <div className="mt-3 lg:mt-5 flex justify-center px-6 pt-5 pb-6  bg-white shadow-md p-4  rounded-md ">
                  <div className="space-y-1 text-center ">
                    {!src ? (
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400 "
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <div className="w-full grid grid-cols-1 divide-x-2 md:grid-cols-2 gap-5">
                        {!showfinal && (
                          <div className="flex justify-center flex-col gap-2">
                            <h4 className="text-xl font-medium text-sr mb-1">
                              {t("Drag_To_Crop_The_Image")}
                            </h4>
                            <ReactCrop
                              src={src}
                              className="object-cover rounded-md  "
                              crop={crop}
                              onChange={setCrop}
                              onImageLoaded={setImage}
                            />
                            <div className="w-full flex flex-col items-center justify-center gap-2 ">
                              {crop.width && crop.height !== 0 ? (
                                <>
                                  <div className="w-full">
                                    <button
                                      onClick={() => {
                                        getCroppedImg();
                                        scrollToCropResultSection();
                                      }}
                                      className="w-full text-white  font-medium px-2 py-2 text-sm bg-sr hover:bg-pr rounded-md flex items-center gap-1 "
                                    >
                                      <BiCrop size="20" />
                                      {t("Crop")}
                                    </button>
                                  </div>
                                  {result === null && (
                                    <div className="w-full">
                                      <form
                                        onSubmit={(e) => {
                                          uploadimage(e);
                                          scrollToSection();
                                        }}
                                        //className="flex items-center justify-center gap-3"
                                      >
                                        {show === false && (
                                          <div>
                                            <input
                                              id="file-upload"
                                              name="file-upload"
                                              type="file"
                                              className="sr-only"
                                              onChange={(e) =>
                                                e.target.files.length !== 0 &&
                                                getFile(e.target.files[0])
                                              }
                                            />
                                          </div>
                                        )}
                                        {uploadfile !== null && (
                                          <div>
                                            <button
                                              type="submit"
                                              className="w-full px-2 py-2 bg-sr text-white text-sm rounded-md  hover:bg-pr"
                                            >
                                              <MdFileUpload size="20" />
                                              <span
                                                className={`text-md font-bold ${
                                                  language === "ar"
                                                    ? "ml-1"
                                                    : "mr-1"
                                                }`}
                                              >
                                                {t("Upload_Image")}
                                              </span>
                                            </button>
                                          </div>
                                        )}
                                      </form>
                                    </div>
                                  )}

                                  <div className="w-full">
                                    <form
                                      onSubmit={(e) => {
                                        uploadimage(e);
                                      }}
                                      // className="flex items-center justify-center gap-3"
                                    >
                                      <div className="w-full">
                                        <label
                                          htmlFor="file-upload"
                                          // className=" flex  cursor-pointer bg-sr text-sm  font-medium text-white hover:bg-pr px-2 py-2 rounded-md"
                                          className="flex items-center cursor-pointer bg-sr text-sm font-medium text-white hover:bg-pr px-2 py-2 rounded-md"
                                        >
                                          <span className="flex items-center justify-center flex-1">
                                            <AiFillCamera
                                              size="20"
                                              className={
                                                language === "ar"
                                                  ? "ml-1"
                                                  : "mr-1"
                                              }
                                            />{" "}
                                            {t("ChooseAnotherPhoto")}
                                          </span>
                                          <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            className="sr-only"
                                            onChange={(e) =>
                                              e.target.files.length !== 0 &&
                                              getFile(e.target.files[0])
                                            }
                                            // required
                                          />
                                        </label>
                                      </div>
                                    </form>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                        {result && (
                          <div
                            className="flex justify-center w-full flex-col gap-2"
                            ref={cropResult}
                          >
                            <h4 className="text-xl text-sr font-medium mb-1">
                              {t("Crop_Result")}
                            </h4>
                            <div className=" w-full flex items-center justify-center">
                              <img
                                src={result}
                                alt=""
                                onError={imageOnError}
                                className="rounded-md"
                              />
                            </div>
                            <div className=" flex items-center justify-center w-full">
                              {showfinal ? (
                                <>
                                  {/* <span className="text-white font-medium px-3 text-sm py-2 bg-gray-600 rounded-md flex items-center gap-1">
                                    {t("CropComplete")}112
                                    <IoCheckmarkDoneSharp className="text-green-400 text-xl" />
                                  </span> */}
                                  <div className=" flex gap-2 ">
                                    <form
                                      onSubmit={(e) => {
                                        uploadimage(e);
                                        scrollToSection();
                                      }}
                                      className="flex items-center justify-center gap-3"
                                    >
                                      <button
                                        type="submit"
                                        className="px-2 py-2 bg-sr text-white text-sm rounded-md  hover:bg-pr"
                                      >
                                        <MdFileUpload size="20" />
                                        <span className="text-md font-bold ">
                                          {t("Upload_Image")}
                                        </span>
                                      </button>
                                    </form>
                                    <button
                                      type="button"
                                      className="px-2 py-2 bg-sr text-white text-sm rounded-md  hover:bg-pr"
                                      onClick={() => {
                                        cancelUploadimage();
                                      }}
                                    >
                                      <TbCameraCancel size="20" />
                                      <span className="text-md font-bold ">
                                        {t("Cancel")}
                                      </span>
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    setShow(true);
                                    dataURLtoFile(result, uploadfile.name);
                                    // window.scroll({
                                    //   top: 0,
                                    //   behavior: "smooth",
                                    // });
                                  }}
                                  className="text-white font-medium px-3 text-sm py-2 bg-sr hover:bg-pr rounded-md flex items-center gap-1"
                                >
                                  {t("CropComplete")}
                                  <IoCheckmarkDoneSharp className="text-green-400 text-xl" />{" "}
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {!uploadfile && (
                      <div className="mt-4">
                        <form
                          onSubmit={(e) => {
                            uploadimage(e);
                          }}
                          className="flex items-center justify-center gap-3"
                        >
                          {show === false && (
                            <div>
                              <label
                                htmlFor="file-upload"
                                className="relative flex  cursor-pointer bg-sr text-sm  font-medium text-white hover:bg-pr px-2 py-2 rounded-md"
                              >
                                <AiFillCamera size="25" />
                                <span
                                  className={`text-md font-bold mt-1 align-middle ${
                                    language === "ar" ? "mr-1" : "ml-1"
                                  }`}
                                >
                                  {t("Select_Photo")}
                                </span>
                                <input
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  onChange={(e) => {
                                    e.target.files.length !== 0 &&
                                      getFile(e.target.files[0]);
                                  }}
                                  // required
                                />
                              </label>
                            </div>
                          )}
                        </form>
                        <p className="text-red-500 text-sm pt-2">
                          {warningText}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="w-full mt-3 lg:mt-5">
                <div className="bg-gray-200 py-5 px-5 w-full shadow-md rounded-lg font-medium">
                  {rules !== null && (
                    <div dangerouslySetInnerHTML={{ __html: rules }}></div>
                  )}
                </div>
                {platinum ? (
                  <div className="flex items-start mt-4 w-full flex-wrap flex-col  justify-start">
                    <div className="py-0 flex flex-row items-center gap-2 pl-3">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={startpage}
                            onChange={(e) => {
                              setStartpage(e.target.checked);
                              showOnStartPage();
                            }}
                          />
                        }
                        disabled={isImageContainerEmpty}
                      />
                      <span
                        className={`font-bold ${
                          isImageContainerEmpty
                            ? "text-gray-400"
                            : "text-gray-700"
                        }`}
                      >
                        {" "}
                        {t("Display_In_Recommendation_List")}
                      </span>
                    </div>
                    <div className="py-0 flex flex-row items-center gap-2 pl-3">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ppublic}
                            onChange={(e) => {
                              setPpublic(e.target.checked);
                              makePrivate();
                            }}
                          />
                        }
                        disabled={
                          isImageContainerEmpty || imageData.length <= 0
                        }
                      />
                      <span
                        className={`font-bold ${
                          isImageContainerEmpty || imageData.length <= 0
                            ? "text-gray-400"
                            : "text-gray-800"
                        }`}
                      >
                        {ppublic
                          ? t("Your_Profile_Images_Is_Now_Public")
                          : t("Your_Profile_Images_Is_Now_Private")}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-start w-full flex-col justify-start pt-2 py-0 gap-2 pl-3">
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={ppublic}
                            onClick={upgradeMemeberShipHandler}
                          />
                        }
                        disabled={isImageContainerEmpty}
                      />
                      <span
                        className={`font-bold ${
                          isImageContainerEmpty || imageData.length <= 0
                            ? "text-gray-400"
                            : "text-gray-800"
                        }`}
                      >
                        {ppublic
                          ? t("Your_Profile_Images_Is_Now_Public")
                          : t("Your_Profile_Images_Is_Now_Private")}
                      </span>
                    </div>
                    <div className="flex flex-row items-center gap-2 ">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={startpage}
                            onClick={upgradeMemeberShipHandler}
                          />
                        }
                        disabled={isImageContainerEmpty}
                      />
                      <span
                        className={`font-bold ${
                          isImageContainerEmpty
                            ? "text-gray-400"
                            : "text-gray-800"
                        }`}
                      >
                        {t("Display_In_Recommendation_List")}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {imageData.length !== 0 && (
                <div className="w-full pb-3 lg:mt-5  mb-40" ref={imageListRef}>
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2 md:gap-2">
                    {imageData.map((items, ind) => (
                      <div
                        key={ind}
                        className="w-full rounded-sm bg-whit p-2 shadow-md"
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE}${items.path}`}
                          alt=""
                          className="w-full sm:h-auto md:h-80 rounded-md "
                          style={{ objectFit: "cover" }}
                          onError={imageOnError}
                        />
                        <div className="py-2 w-full flex items-cente border-t px-2 justify-between">
                          {items.approved ? (
                            <div className="flex items-center gap-2">
                              <div className="w-6 h-6 bg-green-600 rounded-full"></div>
                              <p className="text-xs text-green-600 flex items-center gap-1">
                                {" "}
                                <BsCheck2 title={t("ImageHasBeenApproved")} />
                                {t("Active")}
                              </p>
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <div className="w-6 h-6 bg-gray-400 rounded-full"></div>
                              <p className="text-xs text-gray-400 flex items-center gap-1">
                                {" "}
                                <MdOutlinePendingActions
                                  title={t("Waiting_For_Approval")}
                                />{" "}
                                {t("Waiting_For_Approval")}
                              </p>
                            </div>
                          )}
                          {!items.isprimary ? (
                            <ImProfile
                              onClick={() => makePrimaryPhoto(items.pictureid)}
                              className="cursor-pointer w-6 h-6 text-sr hover:text-pr"
                              title={t("SetImageAsProfilePrimaryImage")}
                            />
                          ) : (
                            <BsPersonCheck
                              className="w-6 h-6 text-green-500 "
                              title={t("ProfilePrimaryImage")}
                            />
                          )}
                          <MdDelete
                            onClick={() => deletePhoto(items.pictureid)}
                            className="cursor-pointer w-6 h-6 text-sr hover:text-pr"
                            title={t("RemoveImage")}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {imageData.length === 0 && (
                <div class="bg-white py-5 px-5 w-full shadow-md rounded-lg font-normal mt-4 mb-20">
                  <div
                    dangerouslySetInnerHTML={{ __html: t("Image_Upload_Tip") }}
                  ></div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfilePhotos;
