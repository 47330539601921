import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MainContent } from "../../App";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import { signalrWebsocket } from "../../utils/socketUtils";
import { useTimeFunction } from "../../utils/useTimeFunction";
import heart from "../../images/heart.gif";

export default function Conversation({
  item,
  inboxid,
  index,
  activeProfileId,
  available,
  status,
  checkPaidUser,
  handleChatMobile,
  setActiveChat,
  setActiveChatUsername,
  setActiveProfileId,
  setActiveChatImage,
  setUpdateUserInbox,
  isPaidMember,
  currentProfileId,
  loading,
}) {
  const { language } = useContext(MainContent);
  const time_ago = useTimeFunction();
  const { t } = useTranslation();
  const [typing, setTyping] = useState(false);
  const [callerid, setCallerid] = useState();

  const imageOnError = (event) => {
    event.currentTarget.src = item.gender ? noimage : muslimf;
  };

  useEffect(() => {
    let timeoutId = null;
    if (item.inbox.inboxid) {
      signalrWebsocket?.on(
        "UpdateUserInbox_" + item.inbox.inboxid,
        (chatid, callerid) => {
          if (chatid === item.inbox.inboxid && activeProfileId !== callerid) {
            setUpdateUserInbox(true);
          }
        }
      );

      signalrWebsocket?.on(item.inbox.inboxid, (chatid, callerid) => {
        setCallerid(callerid);
        if (chatid === item.inbox.inboxid && item.profileid === callerid) {
          if (typing) return;
          setTyping(true);
          timeoutId = setTimeout(() => {
            setTyping(false);
          }, 2000);
        }
      });
     
    }
    return () => {
      if (signalrWebsocket) {
        signalrWebsocket.off("UpdateUserInbox_" + item.inbox.inboxid);
        signalrWebsocket.off(item.inbox.inboxid);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [item.inbox.inboxid]);

  const handleChatClick = (event) => {
    setActiveChat(index);
    checkPaidUser(index);
    handleChatMobile();
    setActiveProfileId(item?.profileid);
    setActiveChatUsername(item?.username);
    setActiveChatImage(item?.imageurl);
  };

  return (
    <NavLink
      to={`/chat/${status}/${item.inbox.inboxid}`}
      style={{
        pointerEvents: loading ? "none" : "auto",
        opacity: loading ? 0.5 : 1,
      }}
    >
      <li
        className={
          "chat_item " +
          (item?.inbox?.inboxid === inboxid ? "bg-green-100" : "")
        }
        key={item.profileid}
        onClick={handleChatClick}
      >
        <div
          className={
            "w-4 mt-12 h-4 rounded-full absolute z-20 top-0 " +
            (item?.isonline && available ? "bg-green-500 " : "bg-gray-600 ") +
            (language !== "ar" ? " left-0 ml-12 " : " right-0 mr-12 ")
          }
        ></div>
        <div className="image">
          <img
            src={`${process.env.REACT_APP_IMAGE}${item?.imageurl}`}
            alt=""
            onError={imageOnError}
            onContextMenu={(e) => e.preventDefault()}
          />

          {item.totalunread > 0 ? (
            <div className="unread_messages">
              <h3>{item.totalunread}</h3>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="username_msg">
          <h3 style={{ direction: "ltr" }}>
            {item?.username &&
            item?.username.trim() !== "" &&
            item?.username.length > 15
              ? item?.username.substring(0, 15) + " ..."
              : item?.username}
          </h3>
          <h4 className="msg">
            {isPaidMember
              ? item.inbox.lastmessage.slice(0, 14)
              : item.profileid === currentProfileId || item.totalunread === 0
              ? item.inbox.lastmessage.slice(0, 14)
              : t("Please_Upgrade_To_See")}
          </h4>
          {typing && callerid === item.profileid ? (
            <div
              style={{
                width: "30px",
                height: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <span style={{ fontSize: "9px" }}>{t("IsTyping")} </span>
              <img alt="giphy" src="/giphy.gif" />
            </div>
          ) : (
            <div
              style={{
                width: "100px",
                height: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            />
          )}
        </div>
        <div
          className="time"
          style={language === "ar" ? { right: "80%" } : { right: "5%" }}
        >
          <p className="mt-2 text-xs italic">
            {!available ? t("NotAvailable") : time_ago(item.lastactivitydate)}
          </p>
        </div>
        <div
          className="turn"
          style={language === "ar" ? { right: "80%" } : { right: "5%" }}
        >
          <h3>
            {item.profileid !== item.inbox.lastsentprofileid
              ? t("Their_Turn")
              : t("Your_Turn")}
          </h3>
        </div>
      </li>
    </NavLink>
  );
}
