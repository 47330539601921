import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/da";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/sv";
import "moment/locale/tr";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as ai from "react-icons/ai";
import * as bi from "react-icons/bi";
import * as bs from "react-icons/bs";
import * as hi from "react-icons/hi";
import {
  RiInboxUnarchiveFill,
  RiInboxArchiveFill,
  RiChatDeleteFill,
} from "react-icons/ri";
import { FaTrashRestore } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { errorHandler } from "../../api/Api";
import { ArchiveRequest } from "../../api/archieveRequest";
import { BlockUserRequest } from "../../api/blockUserRequest";
import { getInboxRequest } from "../../api/getInboxRequest";
import { RemoveInboxRequest } from "../../api/removeInboxRequest";
import { RestoreMessageRequest } from "../../api/restoreMessageRequest";
import { searchInboxRequest } from "../../api/searchInboxRequest";
import { unArchiveRequest } from "../../api/unArchieveRequest";

import { getAdviceListRequest } from "../../api/getAdviceListRequest";
import { MainContent } from "../../App";
import ProfileNavbar from "../../layout/ProfileNavbar";
import "./chat.css";
import Conversation from "./Conversation";
import Header from "./Header";
import HeaderRight from "./HeaderRight";
import MessagesSection from "./MessagesSection";
import UserInformation from "./UserInformation";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";
import Swal from "sweetalert2";
import heart from "../../images/heart.gif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTimes } from "react-icons/fa";
import { GiTruce } from "react-icons/gi";
import FixedHeader from "../../layout/FixedHeader";

const Chat = () => {
  const navigate = useNavigate();
  let { status, inboxid, page } = useParams();
  const { t } = useTranslation();
  const {
    language,
    myown_data,
    setUpdateMailbadge,
    isCurrentProfileApproved,
    isCurrentProfileAvailable,
    isMarkedForDeletion,
  } = useContext(MainContent);
  const subscriptionExpiryDate =
    myown_data?.data?.profileattributes?.subscriptionexpiredate;
  const subscExpiryObject = new Date(subscriptionExpiryDate);
  const menuRef = useRef();
  const archiveMenuRef = useRef(null);
  const isPaidMember = myown_data.data.profileattributes.isapaidmember;
  const [inboxes, setInboxes] = useState([]);
  const [inboxMessageTotalRecords, setInboxMessageTotalRecords] = useState(0);
  const [refreshMessages, setRefreshMessages] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const [archiveMenu, setArchiveMenu] = useState(false);
  const [selectedChatTypeText, setSelectedChatTypeText] =
    useState("All_Conversations");
  const [disableBox, setDisableBox] = useState(null);
  const [searchInbox, setSearchInbox] = useState(false);
  const [searchInboxValue, setSearchInboxValue] = useState("");
  const [activeProfileId, setActiveProfileId] = useState("");
  const [activeChatMobile, setActiveChatMobile] = useState(false);
  const [mobilePopup, setMobilePopup] = useState(false);
  const [activeChatImage, setActiveChatImage] = useState("");
  const [activeChatUsername, setActiveChatUsername] = useState("");
  const accessToken = Cookies.get("accessToken");
  const [profilepic, setProfilepic] = useState([]);
  const [chatState, setChatState] = useState(status ?? "Active");
  const userName = myown_data.data.profileattributes.username;
  const currentProfileId =
    myown_data.data.profileattributes.profileattributesid.toString();
  const [updateUserInbox, setUpdateUserInbox] = useState(false);
  const [refreshProfileInboxList, setRefreshProfileInboxList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSliderVisible, setSliderVisibility] = useState(false);
  const [adviceMessages, setAdviceMessages] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  window.addEventListener("scroll", (e) => {
    setScrollPosition(window.scrollY);
  });

  useEffect(() => {
    const storedTimestamp = localStorage.getItem("hideUntilTimestamp");
    const currentTimestamp = new Date().getTime();

    if (!storedTimestamp || currentTimestamp > parseInt(storedTimestamp, 10)) {
      // Show the slider if it's the first time or if the time has passed
      setSliderVisibility(true);
    }
  }, []);

  const toggleSliderVisibility = () => {
    setSliderVisibility(false);
    // Save the timestamp to local storage when the user clicks the button
    const twoWeeksLaterTimestamp =
      new Date().getTime() + 2 * 7 * 24 * 60 * 60 * 1000;
    localStorage.setItem("hideUntilTimestamp", twoWeeksLaterTimestamp);
    showSwal(
      t("YourSafetyMatters_Header"),
      t("YourSafetyMatters_Message"),
      "info"
    );
  };

  const imageOnError = (event) => {
    event.currentTarget.src = inboxes[activeChat]?.gender ? noimage : muslimf;
    //  event.currentTarget.className = "error";
  };

  useEffect(() => {
    let navigateTo =
      status !== undefined ? status : page !== undefined ? page : "Active";
    setSelectedChatHeaderText(navigateTo);
  }, [status, page]);

  useEffect(() => {
    if (myown_data.data.albumcontainer !== null) {
      if (myown_data.data.albumcontainer.pictures.length !== 0) {
        const profilepic1 = myown_data.data.albumcontainer.pictures.filter(
          (item) =>
            item.ismicroimage === true &&
            item.isprimary === true &&
            item.isthumbnail === false
        );
        setProfilepic(profilepic1);
      } else {
        setProfilepic([]);
      }
    }
  }, []);

  const handleChatMobile = () => {
    setActiveChatMobile(window.innerWidth < 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleChatMobile);

    return () => {
      window.removeEventListener("resize", handleChatMobile);
    };
  }, []);

  useEffect(() => {
    if (language === "no") {
      moment.locale("da");
    } else {
      moment.locale(language);
    }
  }, [language]);

  const messageSentRef = useRef();

  const ArchiveMenuHandle = () => {
    setArchiveMenu(!archiveMenu);
  };

  // Move to Achive a chat
  const moveToArchive = async () => {
    try {
      const data = await ArchiveRequest(
        {
          inboxid: inboxes[activeChat].inbox.inboxid,
        },
        accessToken
      );
      if (data.success) {
        swalController(t("Chat_Successfully_archived"), "", "success");
      } else {
        errorHandler(data);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setRefreshProfileInboxList(true);
    }
  };

  const unArchiveChat = async () => {
    try {
      const data = await unArchiveRequest(
        {
          inboxid: inboxes[activeChat].inbox.inboxid,
        },
        accessToken
      );

      if (data.success) {
        swalController(t("Chat_Successfully_unarchived"), "", "success");
      } else {
        errorHandler(data);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setRefreshProfileInboxList(true);
    }
  };

  const checkPaidUser = (index) => {
    if (
      inboxes[index]?.isblocked ||
      !inboxes[index]?.available ||
      !isPaidMember ||
      chatState === "Archived"
    ) {
      setDisableBox(true);
    } else {
      setDisableBox(false);
    }
  };

  const searchInboxHandle = (e) => {
    setSearchInboxValue(e.target.value);
  };
  const searchInboxes = async () => {
    try {
      if (searchInboxValue !== "") {
        const data = await searchInboxRequest(
          searchInboxValue,
          chatState,
          accessToken
        );
        if (data.length === 0) {
          setInboxes([]);
          setRefreshMessages(true);
          navigate(`/chat/${chatState}`);
        }
        setInboxes(data);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const setSelectedChatHeaderText = (value) => {
    switch (value.toLowerCase()) {
      case "archived":
        setSelectedChatTypeText("Archived_Conversations");
        setChatState("Archived");
        break;
      case "deleted":
        setSelectedChatTypeText("Deleted_Conversations");
        setChatState("Deleted");
        break;
      default:
        setSelectedChatTypeText("All_Conversations");
        setChatState("Active");
        break;
    }
  };

  useEffect(() => {
    const fetchAdviceList = async () => {
      if (isSliderVisible) {
        const data = await getAdviceListRequest(language, accessToken);
        if (data.length > 0) {
          setAdviceMessages(data);
        }
      } else {
        setAdviceMessages([]);
      }
    };
    fetchAdviceList();
  }, [language, isSliderVisible]);

  const getInboxes = async (value) => {
    if (!loading) {
      try {
        setLoading(true);
        const data = await getInboxRequest(value, accessToken);
        if (inboxid !== undefined) {
          const response = data.filter(
            (item) => item.inbox.inboxid === inboxid
          );
          let currentIndex = data.findIndex(
            (item) => item.inbox.inboxid === inboxid
          );

          setActiveChat(currentIndex);
          if (response.length > 0) {
            setActiveChatImage(response[0]?.imageurl);
            setActiveChatUsername(response[0]?.username);
            setActiveProfileId(response[0]?.profileid);
          } else {
            navigate(`/chat/${value}`);
            setActiveChatImage("");
            setActiveChatUsername("");
            setInboxMessageTotalRecords(0);
          }
        }

        if (data.length === 0) {
          setInboxes([]);
        } else {
          setInboxes(data);
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
        setLoader(true);
      }
    }
  };

  useEffect(() => {
    if (refreshProfileInboxList) {
      getInboxes(chatState);
      setRefreshProfileInboxList(false);
    }
    setRefreshProfileInboxList(false);
  }, [refreshProfileInboxList]);

  useEffect(() => {
    setArchiveMenu(false);
    if (chatState === "Active") {
      setSelectedChatTypeText("All_Conversations");
    }
    if (chatState === "Archived") {
      setSelectedChatTypeText("Archived_Conversations");
    }
    if (chatState === "Deleted") {
      setSelectedChatTypeText("Deleted_Conversations");
    }

    getInboxes(chatState);
    setRefreshMessages(true);
  }, [chatState]);

  useEffect(() => {
    if (updateUserInbox) {
      setUpdateMailbadge(true);
      setRefreshProfileInboxList(true);
      setUpdateUserInbox(false);
    }
    return () => {
      setUpdateUserInbox(false);
    };
  }, [updateUserInbox]);

  useEffect(() => {
    setRefreshMessages(true);
    setUpdateMailbadge(true);

    return () => {
      setRefreshMessages(false);
    };
  }, [inboxid]);

  // Block a User
  const blockUser = async () => {
    try {
      const data = await BlockUserRequest(
        {
          blockedProfileId: inboxes[activeChat]?.profileid,
        },
        accessToken
      );
      if (data.success) {
        swalController(t("User_Has_Been_blocked"), "", "success");
        checkPaidUser(activeChat);
      } else {
        errorHandler(data);
      }
      //   navigate(`/chat/${chatState}`);
    } catch (err) {
      errorHandler(err);
    } finally {
      setRefreshProfileInboxList(true);
    }
  };

  // restore deleted messages in the current chat
  const restoreMessages = async () => {
    try {
      const data = await RestoreMessageRequest({ inboxid }, accessToken);
      if (data.success) {
        swalController(t("Chat_Messages_Successfully_Restored"), "", "success");
        setUpdateMailbadge(true);
      } else {
        errorHandler(data);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setRefreshProfileInboxList(true);
    }
  };

  // Remove Inbox by inbox id
  const removeInbox = async () => {
    try {
      const data = await RemoveInboxRequest(
        inboxes[activeChat].inbox.inboxid,
        accessToken
      );
      swalController(t("Chat_Successfully_Removed"), "", "");
      if (data.success) {
        swalController(t("Chat_Successfully_Removed"), "", "success");
      } else {
        errorHandler(data);
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setRefreshProfileInboxList(true);
    }
  };

  useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current?.contains(event.target)) {
        setMobilePopup(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    const handler2 = (event) => {
      if (!archiveMenuRef.current?.contains(event.target)) {
        setArchiveMenu(false);
      }
    };
    document.addEventListener("mousedown", handler2);

    return () => {
      document.removeEventListener("mousedown", handler2);
    };
  }, []);

  useEffect(() => {
    messageSentRef.current?.scrollIntoView();
  }, [inboxMessageTotalRecords]);

  useEffect(() => {
    if (searchInbox) {
      searchInboxes();
    } else {
      setRefreshProfileInboxList(true);
    }
  }, [searchInboxValue, searchInbox]);

  const swalController = (title, message, icon) => {
    Swal.fire({
      title: title,
      text: message,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
      icon: icon,
      timer: 10000,
    });
  };

  const profileNotActiveOrNotApprovedHandler = () => {
    if (!isCurrentProfileApproved) {
      showSwal(
        t("AwaitingApproval_Header"),
        t("AwaitingApproval_Message"),
        "info"
      );
      return;
    }

    if (!isCurrentProfileAvailable) {
      showSwal(
        t("ProfileNotActive_Header"),
        t("ProfileNotActive_Message"),
        "info"
      );
      return;
    }

    if (isMarkedForDeletion) {
      showSwal(
        t("ProfileMarkedForDeletion_Header"),
        t("ProfileMarkedForDeletion_Message"),
        "info"
      );
    }
  };

  const showSwal = (title, message, type) => {
    Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
    });
  };

  const handleConfirmationController = (
    message,
    confirmButtonText,
    callbackFunction
  ) => {
    Swal.fire({
      title: t("Confirmation"),
      text: message,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      confirmButtonColor: "#032E42",
      cancelButtonText: t("Cancel"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        callbackFunction();
      }
    });
  };

  const sliderSettings = {
    dots: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
  };

  return (
    <div className="bg-blue-50 mt-16 lg:pt-10 min-h-screen ">
      <div className="container ">
        <div className="mb-2 px-2">
          <ProfileNavbar />
        </div>
        <FixedHeader
          scrollPosition={scrollPosition}
          headerText={t(selectedChatTypeText)}
          language={language}
        />
        <ToastContainer />
        {loader ? (
          <div className="chat-wrapper border shadow-md">
            <div className="chat-header">
              <div
                className={
                  language !== "ar"
                    ? " border-1 all_conversations "
                    : "border-0 all_conversations"
                }
              >
                <div>
                  {activeChatMobile &&
                    (language !== "ar" ? (
                      <ai.AiOutlineArrowLeft
                        onClick={() => setActiveChatMobile(false)}
                        style={{
                          fontSize: "1.2rem",
                          marginLeft: "-16px",
                          marginRight: "40px",
                        }}
                      />
                    ) : (
                      <ai.AiOutlineArrowRight
                        onClick={() => setActiveChatMobile(false)}
                        style={{
                          fontSize: "1.2rem",
                          marginLeft: "60px",
                          marginRight: "20px",
                        }}
                      />
                    ))}
                  <h3 onClick={ArchiveMenuHandle} className="h-10 flex">
                    {t(selectedChatTypeText)}

                    <hi.HiChevronDown
                      onClick={ArchiveMenuHandle}
                      className="arrow_down"
                    />
                  </h3>
                </div>
                {archiveMenu && (
                  <ul ref={archiveMenuRef} className="archive_menu border">
                    <NavLink to={"/chat/active"}>
                      <li
                        onClick={() => {
                          setChatState("Active");
                        }}
                      >
                        <div className="flex items-center space-x-2 mt-2">
                          <bs.BsFillChatDotsFill className="text-gray-500" />
                          <span>{t("All_Conversations")}</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink to={"/chat/archived"}>
                      <li
                        onClick={() => {
                          setChatState("Archived");
                        }}
                      >
                        <div className="flex items-center space-x-2 mt-2">
                          <bs.BsFillArchiveFill className="text-gray-500" />
                          <span>{t("Archived_Conversations")}</span>
                        </div>
                      </li>
                    </NavLink>
                    <NavLink to={"/chat/deleted"}>
                      <li
                        onClick={() => {
                          setChatState("Deleted");
                        }}
                      >
                        <div className="flex items-center space-x-2 mt-2">
                          <ai.AiFillDelete className="text-gray-500" />
                          <span> {t("Deleted_Conversations")}</span>
                        </div>
                      </li>
                    </NavLink>
                  </ul>
                )}
                {!activeChatMobile ? (
                  <div className="search_icon">
                    <bs.BsSearch
                      onClick={() => {
                        setSearchInboxValue("");
                        setSearchInbox(!searchInbox);
                      }}
                    />
                  </div>
                ) : (
                  <div className="search_icon flex gap-3 rounded-full">
                    {" "}
                    <NavLink to={"/profile/" + activeProfileId}>
                      {inboxes.length > 0 &&
                      inboxMessageTotalRecords > 0 &&
                      inboxid !== null &&
                      inboxid !== undefined ? (
                        <div className="h-10 w-10 rounded-full cursor-pointer border-1 border-gray-400">
                          <img
                            src={`${process.env.REACT_APP_IMAGE}${activeChatImage}`}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={imageOnError}
                            className="h-10 w-10 rounded-full cursor-pointer border-1 border-gray-400"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </NavLink>
                    {inboxMessageTotalRecords > 0 ? (
                      <bs.BsThreeDots
                        style={{ fontSize: "1.3rem" }}
                        onClick={() => {
                          setMobilePopup(!mobilePopup);
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* POPUP Options Menu Mobile  */}
                {inboxes.length > 0 &&
                  inboxMessageTotalRecords > 0 &&
                  mobilePopup &&
                  inboxid !== null &&
                  inboxid !== undefined && (
                    <ul ref={menuRef} className="popup_menu border shadow">
                      <li>
                        {inboxes[activeChat]?.isblocked ? (
                          ""
                        ) : (
                          <span
                            title={t("Block_User")}
                            onClick={() => {
                              if (
                                isCurrentProfileApproved &&
                                isCurrentProfileAvailable &&
                                !isMarkedForDeletion
                              ) {
                                handleConfirmationController(
                                  t("Confirm_Block_User"),
                                  t("Block"),
                                  blockUser
                                );
                                setMobilePopup(false);
                              } else {
                                profileNotActiveOrNotApprovedHandler();
                              }
                            }}
                            style={{ fontSize: "1.2rem" }}
                          >
                            <bi.BiBlock /> <span>{t("Block_User")}</span>
                          </span>
                        )}
                      </li>
                      {chatState !== "Deleted" && (
                        <li>
                          <span
                            onClick={() => {
                              if (
                                isCurrentProfileApproved &&
                                isCurrentProfileAvailable &&
                                !isMarkedForDeletion
                              ) {
                                handleConfirmationController(
                                  t("Confirm_Remove_Chat"),
                                  t("Remove_User_Chat"),
                                  removeInbox
                                );
                                setMobilePopup(false);
                              } else {
                                profileNotActiveOrNotApprovedHandler();
                              }
                            }}
                            style={{ fontSize: "1.2rem" }}
                            title={t("Remove_User_Chat")}
                          >
                            <RiChatDeleteFill />{" "}
                            <span>{t("Remove_User_Chat")}</span>
                          </span>
                        </li>
                      )}
                      {chatState !== "Deleted" && (
                        <li>
                          {!inboxes[activeChat]?.archived ? (
                            <span
                              onClick={() => {
                                if (
                                  isCurrentProfileApproved &&
                                  isCurrentProfileAvailable &&
                                  !isMarkedForDeletion
                                ) {
                                  handleConfirmationController(
                                    t("Confirm_Archive_Chat"),
                                    t("Move_To_Archive"),
                                    moveToArchive
                                  );
                                  setMobilePopup(false);
                                } else {
                                  profileNotActiveOrNotApprovedHandler();
                                }
                              }}
                              title={t("Move_To_Archive")}
                              style={{ fontSize: "1.2rem" }}
                            >
                              <RiInboxArchiveFill />
                              {t("Move_To_Archive")}
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                if (
                                  isCurrentProfileApproved &&
                                  isCurrentProfileAvailable &&
                                  !isMarkedForDeletion
                                ) {
                                  handleConfirmationController(
                                    t("Confirm_UnArchive_Chat"),
                                    t("UnArchive_Chat"),
                                    unArchiveChat
                                  );
                                  setMobilePopup(false);
                                } else {
                                  profileNotActiveOrNotApprovedHandler();
                                }
                              }}
                              title={t("UnArchive_Chat")}
                              style={{ fontSize: "1.2rem" }}
                            >
                              <RiInboxUnarchiveFill />
                              {t("UnArchive_Chat")}
                            </span>
                          )}
                        </li>
                      )}

                      {inboxes[activeChat]
                        ?.hascurrentprofilehistoricalmessages && (
                        <li>
                          <span
                            onClick={() => {
                              if (
                                isCurrentProfileApproved &&
                                isCurrentProfileAvailable &&
                                !isMarkedForDeletion
                              ) {
                                handleConfirmationController(
                                  t("Confirm_Restore_Chat_Messages"),
                                  t("Restore_Messages"),
                                  restoreMessages
                                );
                                setMobilePopup(false);
                              } else {
                                profileNotActiveOrNotApprovedHandler();
                              }
                            }}
                            style={{ fontSize: "1.2rem" }}
                            title={t("Restore_Messages")}
                          >
                            <FaTrashRestore />
                            {t("Restore_Messages")}
                          </span>
                        </li>
                      )}
                    </ul>
                  )}

                {/* Search Inboxes  */}
                {searchInbox && (
                  <div className="search_inbox">
                    <input
                      onChange={searchInboxHandle}
                      value={searchInboxValue}
                      type="text"
                      placeholder={t("Search_Inbox")}
                    />
                    <ai.AiOutlineClose
                      style={{
                        fontSize: "1.2rem",
                        color: "gray",
                        marginTop: "-4px",
                      }}
                      id="search"
                      onClick={() => {
                        setSearchInbox(false);
                      }}
                    />
                  </div>
                )}
              </div>

              <Header
                inboxid={inboxid}
                activeProfileId={activeProfileId}
                inboxes={inboxes}
                isChatSelected={inboxid !== undefined && inboxid !== null}
                activeChatUsername={activeChatUsername}
                activeChatImage={activeChatImage}
                gender={inboxes[activeChat]?.gender}
                isblocked={inboxes[activeChat]?.isblocked}
                available={inboxes[activeChat]?.available}
              />
              <HeaderRight
                inboxid={inboxid}
                activeProfileId={activeProfileId}
                inboxes={inboxes}
                isChatSelected={inboxid !== undefined && inboxid !== null}
                activeChatUsername={activeChatUsername}
                activeChatImage={activeChatImage}
                gender={inboxes[activeChat]?.gender}
                isblocked={inboxes[activeChat]?.isblocked}
                available={inboxes[activeChat]?.available}
              />
            </div>
            <div className="chat-container">
              <div
                className="left_side"
                style={
                  activeChatMobile ? { display: "none" } : { display: "block" }
                }
              >
                <div className="chat_list">
                  <ul id="ul_list">
                    {inboxes?.map((item, index) => {
                      return (
                        <Conversation
                          key={item.inbox.inboxid}
                          item={item}
                          inboxid={inboxid}
                          index={index}
                          activeProfileId={activeProfileId}
                          available={item?.available}
                          status={chatState}
                          checkPaidUser={checkPaidUser}
                          handleChatMobile={handleChatMobile}
                          setActiveChat={setActiveChat}
                          setActiveChatUsername={setActiveChatUsername}
                          setActiveProfileId={setActiveProfileId}
                          setActiveChatImage={setActiveChatImage}
                          setUpdateUserInbox={setUpdateUserInbox}
                          isPaidMember={isPaidMember}
                          currentProfileId={currentProfileId}
                          loading={loading}
                        />
                      );
                    })}
                  </ul>
                  {/* )} */}
                </div>
              </div>
              <MessagesSection
                activeChatMobile={activeChatMobile}
                inboxes={inboxes}
                activeChat={activeChat}
                own_image={profilepic}
                isPaidMember={isPaidMember}
                isArchived={chatState === "Archived"}
                subscExpiryObject={subscExpiryObject}
                disableBox={disableBox}
                inboxid={inboxid}
                avatarimageurl={
                  myown_data.data?.profileattributes?.avatarimageurl
                }
                userName={userName}
                currentProfileId={currentProfileId}
                activeProfileId={activeProfileId}
                own_gender={myown_data.data?.profileattributes?.gender}
                chatState={chatState}
                refreshMessages={refreshMessages}
                setRefreshMessages={setRefreshMessages}
                setInboxMessageTotalRecords={setInboxMessageTotalRecords}
                setRefreshProfileInboxList={setRefreshProfileInboxList}
                isCurrentProfileApproved={isCurrentProfileApproved}
                isCurrentProfileAvailable={isCurrentProfileAvailable}
                isMarkedForDeletion={isMarkedForDeletion}
                setActiveChatMobile={setActiveChatMobile}
              />
              {inboxes.length > 0 && (
                <UserInformation
                  isChatSelected={inboxid !== undefined && inboxid !== null}
                  inboxes={inboxes}
                  activeChat={activeChat}
                  chatState={chatState}
                  setMobilePopup={setMobilePopup}
                  isblocked={inboxes[activeChat]?.isblocked}
                  available={inboxes[activeChat]?.available}
                  handleConfirmationController={handleConfirmationController}
                  profileNotActiveOrNotApprovedHandler={
                    profileNotActiveOrNotApprovedHandler
                  }
                  blockUser={blockUser}
                  restoreMessages={restoreMessages}
                  removeInbox={removeInbox}
                  unArchiveChat={unArchiveChat}
                  moveToArchive={moveToArchive}
                  isCurrentProfileApproved={isCurrentProfileApproved}
                  isCurrentProfileAvailable={isCurrentProfileAvailable}
                  isMarkedForDeletion={isMarkedForDeletion}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="flex items-start justify-center h-screen">
            <img src={heart} className="h-96  rounded-full" alt="" />
          </div>
        )}
      </div>
      <div
        className={`container mt-0 lg:pt-0 pb-2 ${
          isSliderVisible ? "" : "hidden"
        }`}
      >
        <div className="relative">
          <div
            className="bg-yellow-400 py-2 px-5 w-full shadow-md rounded-lg font-medium"
            style={{ border: "1px solid #000" }}
          >
            <div className=" text-sr font-medium text-center">
              <button
                onClick={toggleSliderVisibility}
                className=" absolute top-2 right-2 cursor-pointer "
              >
                <FaTimes size={20} color="#000" /> {/* Close icon */}
              </button>
              <Slider {...sliderSettings}>
                {adviceMessages.map((advice) => (
                  <div key={advice.matrimonialadviceid}>
                    <p
                      className={`${language === "ar" ? " text-right " : ""} `}
                      dir={language === "ar" ? "rtl" : "ltr"}
                    >
                      {advice.text}
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className="text-center text-gray-400 pb-5 text-sm px-6">
        <Slider {...sliderSettings}>
          {messages.map((message, index) => (
            <div key={index}>
              <p>{message}</p>
            </div>
          ))}
        </Slider>
      </div> */}
    </div>
  );
};

export default Chat;
