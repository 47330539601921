import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import muslimf from "../../../images/avatar_female.jpg";
import noimage from "../../../images/avatar_male.jpg";
import { MainContent } from "../../../App";
import { useTranslation } from "react-i18next";
import { BsHeartFill, BsHeart } from "react-icons/bs";
import { RiHeartsFill, RiMessage2Fill } from "react-icons/ri";
import {
  AiTwotoneUnlock,
  AiFillDelete,
  AiFillCamera,
  AiFillCloseCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { url, errorHandler } from "../../../api/Api";
import { useNavigate } from "react-router";
import fetchHandler from "../../../api/fetchHandler";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import debounce from "../../../utils/debounce";
import { customToasterStyle } from "../../../utils/constants";
import Flag from "react-world-flags";

function PeopleCard(props) {
  const history = useNavigate();
  const { t } = useTranslation();
  const {
    changenow,
    myown_data,
    language,
    isCurrentProfileApproved,
    isCurrentProfileAvailable,
    isMarkedForDeletion,
  } = useContext(MainContent);
  const [like, setLike] = useState(false);
  const [sendmessage, setSendmessage] = useState(false);
  const [body, setBody] = useState("");
  const [ispaid, setIspaid] = useState(false);
  const [myid, setMyid] = useState("");
  const { item } = props;
  const isLikedRef = useRef(item.isliked);
  const profileIdRef = useRef(myown_data.data.profileid);
  const isPaidMemberRef = useRef(
    myown_data.data.profileattributes.isapaidmember
  );
  const [succeed, setSucceed] = useState();
  const renderOnlineTooltip = (data) => (
    <Tooltip id="button-tooltip" {...data}>
      {t("IsOnlineNow")}
    </Tooltip>
  );

  const renderOfflineTooltip = (data) => (
    <Tooltip id="button-tooltip" {...data}>
      {t("IsOffline")}
    </Tooltip>
  );

  const imageOnError = (event) => {
    event.currentTarget.src = item.gender ? noimage : muslimf;
  };

  const imageSrc = item.gender ? noimage : muslimf;

  const photoRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");
    const raw = JSON.stringify({
      profileId: item.profileid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}image/requestaccess`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setSucceed(true);
          toast.success(t("Successfully_Requesting_For_Profile_Photos"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(
            result.errors === null ? result.message : result.errors[0],
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: customToasterStyle(language),
            }
          );
        }
      });
  };

  const sendPhotoRequest = debounce(photoRequest, 500); // Debounce delay in milliseconds

  const handleButtonClick = () => {
    sendPhotoRequest(); // Call the debounced function
  };

  useEffect(() => {
    setLike(isLikedRef.current);
    setSucceed(item.hasaccessalreadybeenrequested);
    setMyid(profileIdRef.current);
    setIspaid(isPaidMemberRef.current);
  }, [changenow]);

  const blockRemove = (myid, profileid) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");
    const raw = JSON.stringify({
      profileId: myid,
      blockedProfileId: props.cardProfileId,
      profileName: props.displayusername,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}profile/unblock`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          props.setReload(true);
          toast.success(result.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        }
      });
  };

  const pheaders = new Headers();
  pheaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
  pheaders.append("Content-Type", "application/json");
  pheaders.append("Accept-Language", language || "en");

  const toastcontroller = (result) => {
    if (result.success) {
      toast.success(result.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    } else {
      toast.error(result.message ? result.message : result.errors[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: customToasterStyle(language),
      });
    }
  };
  const faverouteRemove = useCallback(() => {
    const raw = JSON.stringify({
      profileId: myid,
      favoriteProfileId: props.cardProfileId,
    });
    fetchHandler(`${url}profile/favorite/remove`, "POST", raw, pheaders).then(
      (result) => {
        if (result.success) {
          props.setReaload(true);
        }
        toastcontroller(result);
      }
    );
  }, [myid, props.cardProfileId, pheaders]);

  const sendMessage = (e) => {
    e.preventDefault();
    setSendmessage(false);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      body: body,
      fromProfileId: myid,
      toProfileId: props.cardProfileId,
      messagetypeid: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}inbox/newmessage`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          toast.success(result.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        }
      });
  };
  const likeProfile = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    const raw = JSON.stringify({
      profileId: myid,
      likeprofileid: props.cardProfileId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}profile/like/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setLike(true);
          if (result.success) {
            Swal.fire({
              title: result.message,
              //text: result.message,
              confirmButtonText: t("Ok"),
              confirmButtonColor: "#032E42",
              icon: "success",
              timer: 4000,
            });
          }
        } else {
          Swal.fire({
            title: t("Error"),
            text: result.message,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#d33",
            icon: "error",
          });
        }
      });
  };

  const confirmShowInterestHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      html: t("Express_Interest_Confirm_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Express_Interest_Button"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        likeProfile();
      }
    });
  };

  const profileNotActiveOrNotApprovedHandler = () => {
    if (!isCurrentProfileApproved) {
      showSwal(
        t("AwaitingApproval_Header"),
        t("AwaitingApproval_Message"),
        "info"
      );
      return;
    }

    if (!isCurrentProfileAvailable) {
      showSwal(
        t("ProfileNotActive_Header"),
        t("ProfileNotActive_Message"),
        "info"
      );
      return;
    }
    if (isMarkedForDeletion) {
      showSwal(
        t("ProfileMarkedForDeletion_Header"),
        t("ProfileMarkedForDeletion_Message"),
        "info"
      );
    }
  };

  const showSwal = (title, message, type) => {
    Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
    });
  };

  return (
    <>
      {sendmessage &&
        (item.inboxid === null ||
        item.inboxid === undefined ||
        item.isinboxempty ? (
          <div
            className="fixed top-0 left-0 "
            style={{
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0,0.3)",
              zIndex: "9999999",
            }}
          >
            <div
              style={{ height: "500px" }}
              // className="sm:w-80 md:w-96 lg:w-96 xl:w-96 xxl:w-96 fixed bottom-2 px-2 border-2 border-sr overflow-hidden bg-white shadow-md rounded-xl right-10"
              // className="w-96 fixed bottom-2 px-2 border-2 border-sr overflow-hidden bg-white shadow-md rounded-xl right-10"
              className="w-full sm:w-96 md:w-96 lg:w-96 fixed lg:right-10 md:right-5 right-1 bottom-2 px-2 border-2 border-pr overflow-hidden bg-white  shadow-md rounded-xl "
              // className="w-full sm:max-w-xs md:max-w-sm lg:max-w-lg fixed lg:right-10 md:right-5 right-1 bottom-2 px-2 border-2 border-pr overflow-hidden bg-white shadow-md rounded-xl "
            >
              <div className="flex items-center justify-end pt-2 mb-2">
                <AiFillCloseCircle
                  onClick={() => setSendmessage(false)}
                  className="text-sr w-6 h-6 cursor-pointer"
                />
              </div>
              <div className="flex items-center justify-center flex-col mb-3">
                <Link to={"/profile/" + item.profileid}>
                  <div className="h-16 w-16 rounded-full  border-gray-300 border-2 overflow-hidden mb-2 ">
                    {item.url !== "" ? (
                      <img
                        className="h-full w-full object-cover "
                        src={`${process.env.REACT_APP_IMAGE}${item.url}`}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                        onError={imageOnError}
                      />
                    ) : (
                      <img
                        className="h-full w-full object-cover "
                        src={imageSrc}
                        alt=""
                        onError={imageOnError}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}
                  </div>
                  <h6
                    style={{ marginLeft: "-10px", direction: "ltr" }}
                    className="font-medium text-gray-700"
                  >
                    {item.displayusername}
                  </h6>
                </Link>
              </div>
              <form
                className="relative h-full"
                onSubmit={(e) => sendMessage(e)}
              >
                {ispaid &&
                isCurrentProfileApproved &&
                isCurrentProfileAvailable &&
                !isMarkedForDeletion ? (
                  <div>
                    <textarea
                      onChange={(e) => setBody(e.target.value)}
                      required
                      maxLength="4000"
                      autoFocus
                      cols="25"
                      rows="10"
                      placeholder={t("Write_Your_Message_Here")}
                      className="outline-none border rounded-md p-3 w-full h-72 "
                    ></textarea>
                  </div>
                ) : !ispaid &&
                  isCurrentProfileApproved &&
                  isCurrentProfileAvailable &&
                  !isMarkedForDeletion ? (
                  <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                    <p className="text-red-500 flex  gap-2">
                      <AiOutlineWarning className="h-5 w-5" />
                      {t("Only_Paid_Member_Can_Send_Message")}
                    </p>
                    <Link
                      to="/payment"
                      className=" text-green-600 text-sm font-medium"
                    >
                      {t("Upgrade_Your_Subscription")}
                    </Link>
                  </div>
                ) : (
                  <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                    <p className="text-red-500 flex  gap-2">
                      <AiOutlineWarning className="h-5 w-5" />
                      {t("Profile_Must_Be_Active_To_Send_Messages")}
                    </p>
                  </div>
                )}

                <div className="absolute bottom-40 right-2 w-full">
                  {ispaid &&
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion && (
                      <div className=" flex items-center w-full justify-between pl-4">
                        <p className="text-green-500 text-xs font-medium">
                          {body.length} /4000
                        </p>
                        <button
                          type="submit"
                          className="bg-pr hover:bg-sr px-2 py-2 text-white rounded-sm"
                        >
                          {t("Send")}
                        </button>
                      </div>
                    )}
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {item.isinboxarchived
              ? history("/chat/Archived/" + item.inboxid)
              : history("/chat/Active/" + item.inboxid)}
          </div>
        ))}
      <div
        className="shadow-md rounded-md overflow-hidden relative bg-white flex flex-column"
        // style={{ height: "450px" }}
        style={{ minHeight: "5rem" }}
      >
        <ToastContainer />
        {item.online ? (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderOnlineTooltip}
          >
            <div
              className={
                "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                (language === "ar" ? "left-0" : "right-0")
              }
            ></div>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderOfflineTooltip}
          >
            <div
              className={
                "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                (language === "ar" ? "left-0" : "right-0")
              }
            ></div>
          </OverlayTrigger>
        )}

        {item.isnew ? (
          <div className=" bg-green-500 text-white rounded p-1 absolute z-20 top-0  text-xs ">
            {t("New")}
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            //  height: "250px",
            overflow: "hidden",
            position: "relative",
            zIndex: "10",
          }}
        >
          {/* <Link
              to={"/profile/" + item.profileid}
              // style={{
              //   heigth: "230px",
              //   overflow: "hidden",
              //   position: "relative",
              //   zIndex: "10",
              // }}
            >
              <div class="flex justify-center items-center ">
                {item.url !== "" ? (
                  <img
                    className={`h-auto ${
                      item.url.includes("avatar") ? "w-full" : "w-full"
                    }`}
                    src={`${process.env.REACT_APP_IMAGE}images/${item.url}`}
                    alt=""
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : (
                  <img
                    className="h-auto w-auto  "
                    src={imageSrc}
                    alt=""
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
              </div>
            </Link> */}
          {item.isavailable ? (
            <Link to={"/profile/" + props.cardProfileId}>
              <div class="flex justify-center items-center relative">
                {item.url !== "" ? (
                  <img
                    className={`w-full sm:h-80 md:h-80 rounded-md${
                      item.url.includes("avatar") ? "w-full" : "w-full"
                    }`}
                    src={`${process.env.REACT_APP_IMAGE}${item.url}`}
                    alt=""
                    onContextMenu={(e) => e.preventDefault()}
                    onError={imageOnError}
                  />
                ) : (
                  <img
                    className="h-auto w-auto "
                    src={imageSrc}
                    alt=""
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
                <div
                  className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                  style={{
                    zIndex: "10",
                  }}
                >
                  <Flag
                    code={item?.countryoforigincode}
                    //height="16"
                    className="h-6"
                    //fallback={<span>?</span>}
                    title={item?.countryoforigin}
                  />
                </div>
              </div>
            </Link>
          ) : (
            <div class="flex justify-center items-center relative">
              {item.url !== "" ? (
                <img
                  className={`h-auto ${
                    item.url.includes("avatar") ? "w-full" : "w-full"
                  }`}
                  src={`${process.env.REACT_APP_IMAGE}${item.url}`}
                  alt=""
                  onContextMenu={(e) => e.preventDefault()}
                  onError={imageOnError}
                />
              ) : (
                <img
                  className="h-auto w-auto "
                  src={imageSrc}
                  alt=""
                  onError={imageOnError}
                  onContextMenu={(e) => e.preventDefault()}
                />
              )}
              <div
                className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                style={{
                  zIndex: "10",
                }}
              >
                <Flag
                  code={item?.countryoforigincode}
                  //height="16"
                  className="h-6"
                  //fallback={<span>?</span>}
                  title={item?.countryoforigin}
                />
              </div>
            </div>
          )}

          {item.ispicturesprivate === true &&
            item.hascurrentuseraccesstoviewcardimages === false &&
            item?.totalimages > 0 && (
              <div
                onClick={
                  succeed
                    ? null
                    : isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion
                    ? handleButtonClick
                    : profileNotActiveOrNotApprovedHandler
                }
                // style={{ zIndex: "6000", left: "15%", right: "15%" }}
                style={{
                  zIndex: "6000",
                  // transform: "translateY(-50%)",
                  // backgroundColor: "lightblue",
                  textAlign: "center",
                }}
                className={
                  succeed
                    ? " px-2 py-2 absolute bottom-0 start-0 bg-gray-500 text-white text-sm w-100 "
                    : "px-2 py-2 absolute bottom-0 start-0 hover:bg-sr cursor-pointer  text-white text-sm w-100 bg-pr"
                }
              >
                {succeed ? t("Request_Already_Sent") : t("Ask_About_My_Photo")}
              </div>
            )}
        </div>

        <Card.Body className="p-3 flex flex-column ">
          <div className=" overflow-hidden" style={{ minHeight: "6rem" }}>
            <h2
              className={
                "text-lg text-left font-bold text-gray-800  mb-1 " +
                (language === "ar" ? "text-right" : "text-left")
              }
              style={{ direction: "ltr" }}
            >
              {/* <Link to={"/profile/" + item.profileid}>
                  {item.displayusername}
                </Link> */}

              {item?.cardProfileId !== null ? (
                item.isavailable ? (
                  <Link>{item.displayusername}</Link>
                ) : (
                  item.displayusername
                )
              ) : (
                `${t("Profile_Doesnt_Exist")}`
              )}
            </h2>
            <p className=" text-gray-800 text-sm mb-1 ">
              <span className="font-medium text-sr">{t("Age")}: </span>{" "}
              {item.age}
            </p>
            <p
              className=" text-gray-800  text-sm mb-1 "
              title={item?.cityname ? item?.cityname : ""}
            >
              <span className="font-medium  text-gray-800">
                {t("Location")}:{" "}
              </span>
              {item?.cityname && item?.cityname.trim() !== "" ? (
                item?.cityname.length > 15 ? (
                  item?.cityname.substring(0, 15) + " ..."
                ) : (
                  item?.cityname
                )
              ) : (
                <span className="text-pr">{t("No_Data")}</span>
              )}
            </p>

            <div className="border-top border-bottom py-1 my-1">
              <p
                className=" text-gray-800 text-sm"
                title={item?.country ? item?.country : ""}
              >
                <span
                  className="font-medium text-gray-800"
                  title={t("CountryOfResidence")}
                >
                  {t("CountryOfResidenceCard")}:{" "}
                </span>
                {item?.country && item?.country.trim() !== "" ? (
                  item?.country.length > 15 ? (
                    item?.country.substring(0, 15) + " ..."
                  ) : (
                    item?.country
                  )
                ) : (
                  <span className="text-pr">{t("No_Data")}</span>
                )}
              </p>
            </div>

            <p className=" text-gray-800  text-sm mb-1">
              <span className="font-medium text-gray-800">
                {t("Seeking")} :
              </span>{" "}
              {item.seekinggender ? t("Male") : t("Female")}{" "}
              {item.seekingminage}-{item.seekingmaxage}
            </p>
          </div>

          {/* <div className="flex items-center justify-evenly text-xl"> */}
          <div className="flex items-center justify-end gap-3 text-pr text-xl mt-auto">
            <div className="mr-3 md:mr-2 lg:mr-0">
              {/* {like ? (
                  <BsHeartFill
                    className=" text-pink-600"
                    title={t("Already_Expressed_Interest")}
                  />
                ) : (
                  <BsHeart
                    title={t("Express_Interest")}
                    //onClick={likeProfile}
                    onClick={
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion
                        ? confirmShowInterestHandler //() => setLikeOpen(true)
                        : profileNotActiveOrNotApprovedHandler
                    }
                    className="cursor-pointer  transition duration-200 hover:scale-110 transform  text-pink-500 hover:text-sr  "
                  />
                )} */}
              {item.likedmeback && item.isavailable ? (
                <div>
                  <RiHeartsFill
                    className="h-5  w-5  text-pink-600"
                    title={t("Mutual_Likes")}
                  />
                </div>
              ) : (
                <div>
                  {item.isliked || like ? (
                    item.isavailable ? (
                      <BsHeartFill
                        className=" text-pink-600"
                        title={t("Already_Expressed_Interest")}
                      />
                    ) : item.likedmeback ? (
                      <RiHeartsFill
                        className="text-gray-300"
                        title={t("Mutual_Likes")}
                      />
                    ) : (
                      <BsHeartFill className="  text-gray-300" />
                    )
                  ) : item.isavailable ? (
                    <BsHeart
                      title={t("Express_Interest")}
                      onClick={
                        isCurrentProfileApproved &&
                        isCurrentProfileAvailable &&
                        !isMarkedForDeletion
                          ? confirmShowInterestHandler
                          : profileNotActiveOrNotApprovedHandler
                      }
                      className="cursor-pointer  transition duration-200 hover:scale-110 transform  text-pink-500 hover:text-sr  "
                    />
                  ) : (
                    <BsHeart className="transition duration-200 hover:scale-110 transform  text-gray-300 hover:text-gray-300  " />
                  )}
                </div>
              )}
            </div>
            <div className="mr-3 md:mr-2 lg:mr-0">
              {" "}
              {/* <RiMessage2Fill
                  title={t("Write_A_Message_By_Clicking_Here")}
                  onClick={
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                      ? () => setSendmessage(true)
                      : profileNotActiveOrNotApprovedHandler
                  }
                  className="cursor-pointer text-purple-500  hover:text-purple-700  transition duration-200 hover:scale-110 transform  "
                /> */}
              {item.isavailable ? (
                <div>
                  {" "}
                  <RiMessage2Fill
                    onClick={
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion
                        ? () => setSendmessage(true)
                        : profileNotActiveOrNotApprovedHandler
                    }
                    className="cursor-pointer text-purple-500  hover:text-purple-700  transition duration-200 hover:scale-110 transform  "
                    title={t("Write_A_Message_By_Clicking_Here")}
                  />
                </div>
              ) : (
                <div>
                  {" "}
                  <RiMessage2Fill className=" text-gray-300  transition duration-200 hover:scale-110 transform " />
                </div>
              )}
            </div>
            <div className="flex items-center gap-1 text-green-700 text-sm">
              {" "}
              <AiFillCamera
                className=" text-green-700 text-xl"
                title={t("Number_Of_Images_Attached")}
              />
              {item.totalimages}
            </div>

            {props.isBlocked ? (
              <div>
                <AiTwotoneUnlock
                  title={t("Unblock_Profile")}
                  onClick={() =>
                    blockRemove(item.profileid, props.cardProfileId)
                  }
                  className="h-5 cursor-pointer w-5"
                />
              </div>
            ) : (
              ""
            )}
            {props.cardProfileId !== null &&
            props.cardProfileId !== undefined &&
            props.isFavorit ? (
              <AiFillDelete
                title={t("Remove_From_Favorites")}
                onClick={() => faverouteRemove(props.cardProfileId)}
                className="h-5 cursor-pointer w-5"
              />
            ) : (
              ""
            )}
          </div>
        </Card.Body>
      </div>
    </>
  );
}

export default PeopleCard;
